import * as React from "react";

// MUI
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/reducer";
import { CLEAR_SNACK } from "../store/_UI/Snackbar";

export default function SnackbarProvider() {
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state: RootState) => state.ui);

  function handleClose() {
    dispatch(CLEAR_SNACK());
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackbar.open}
      autoHideDuration={3000}
      message="Testee"
      onClose={handleClose}
    >
      <MuiAlert severity={snackbar?.severity} sx={{ width: "100%" }}>
        {snackbar.message}
      </MuiAlert>
    </Snackbar>
  );
}
