import React from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import { EditButton } from '../EditButton';
import { DocumentButton } from '../DocumentButton';
import { fromFloatToCurrency } from '../../utils/format';
import { Typography } from '@mui/material';
import clsx from 'clsx';

const sizeCol = 150;
const columns = [
    {
        field:"description",
        headerName:"Pedido",
        minWidth: 600,
        headerAlign: 'center',
        overflow: '',
        align: 'center'
    },
    {
        field: 'totalAmount',
        headerName: 'Valor',
        renderCell: (params) => fromFloatToCurrency(params.row.totalAmount),
        minWidth: sizeCol,
        headerAlign: 'center',
        align: 'center',
        cellClassName: (params) => {
            if (params.value == null) {
              return '';
            }
      
            return clsx('number-handler', {
              negative: params.value < 0,
              positive: params.value > 0,
            });
          },
    },
    {
        
        field: 'created',
        headerName: 'Data',
        minWidth: sizeCol,
        headerAlign: 'center',
        align: 'center'
    }
];

export default function ExtractGridOrder({ rows }) {
    return (
        <div style={{ height: 440, width: '100%' }}>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                disableSelectionOnClick
                columns={columns}
                pageSize={5}
                getRowId={() => parseInt(Date.now() * Math.random()).toString()}
                rowsPerPageOptions={[5]}
            />
        </div>
    );
}
