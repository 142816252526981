import React from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import { EditButton } from '../EditButton';

const statusFunctions = {
    Completo: [
        {
            label: 'Ver detalhes',
            action: () => {
                console.log('Ver detalhes');
            }
        }
    ],
    Entregue: [
        {
            label: 'Rastrear',
            action: () => {
                console.log('Rastrear');
            }
        }
    ]
};
// To make some equal
statusFunctions.Envio = statusFunctions.Entregue;

const sizeCol = 170;
const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 90,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'requestDate',
        headerName: 'Data de criação',
        minWidth: sizeCol,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'requestedBy',
        headerName: 'Solicitante',
        minWidth: sizeCol,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'cards',
        headerName: 'Cartões',
        minWidth: sizeCol,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'requestType',
        headerName: 'Tipo de solicitação',
        minWidth: sizeCol,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: sizeCol,
        headerAlign: 'center',
        align: 'center'
    }

    //   {
    //     field: "action",
    //     headerName: "Ação",
    //     renderCell: (params) => (
    //       <EditButton
    //       functions={
    //         params.row.status in statusFunctions ? statusFunctions[params.row.status] : [{label:'Sem Ações', action:function(){}}]
    //       }
    //       />
    //     ),
    //     minWidth: sizeCol,
    //     headerAlign: "center",
    //     align: "center",
    //   },
];

const rows = [
    {
        id: 1,
        requestDate: '19/11/2021',
        requestedBy: 'Suporte',
        cards: 5,
        requestType: 'empresa',
        status: 'Completo'
    },
    {
        id: 2,
        requestDate: '19/11/2021',
        requestedBy: 'Suporte',
        cards: 5,
        requestType: 'empresa',
        status: 'Entregue'
    },
    {
        id: 3,
        requestDate: '19/11/2021',
        requestedBy: 'Suporte',
        cards: 5,
        requestType: 'empresa',
        status: 'Envio'
    }
];

export default function CardGrid({ data }) {
    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                disableSelectionOnClick
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
            />
        </div>
    );
}
