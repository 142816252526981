import React, { useEffect } from 'react';

// MUI
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Switch } from '@mui/material';

// Wexp Icons
import WexpIconOrangeCreditCard from '../assets/images/credit-card.png';

// Wexp Components
import ResponsiveAppBar from '../components/NavBar';
import Footer from '../components/Footer';
import Header from '../components/Header';

import { useDispatch, useSelector } from 'react-redux';
import { getBenefits, changeBenefitStatus } from '../store/_Entities/Benefits';
import { benefitsIcons } from '../utils/benefitsIcons';

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '10px'
}));

const FreeBalance = () => {
    const dispatch = useDispatch();
    const { benefits, user } = useSelector((state) => state.entities);

    useEffect(() => {
        dispatch(getBenefits(user.data.companyID));
    }, []);

    const [freeBalanceActive, setFreeBalanceActive] = React.useState(false);

    // Make the HTTP request and update the freeBalanceActive state
    const handleToggleFreeBalance = () => {
        setFreeBalanceActive(!freeBalanceActive);
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Saldo livre" />

                <Grid container rowGap={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">CORP</Typography>
                    </Grid>
                    {benefits?.benefits
                        .filter(
                            (x) => x.product === 'Corp' && x.benefitId === 12
                        )
                        .map((benefit) => (
                            <Grid item xs={12} key={benefit?.benefitId}>
                                <Item>
                                    <Grid
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img
                                            style={{
                                                width: '35px',
                                                height: '35px',
                                                marginRight: '8px'
                                            }}
                                            src={
                                                benefitsIcons[
                                                    benefit?.description
                                                ]
                                            }
                                            alt=""
                                        />
                                        <Grid>
                                            <Typography
                                                variant="subtitle1"
                                                component="div"
                                                sx={{ fontSize: '18px' }}
                                            >
                                                {benefit?.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Switch
                                        color="secondary"
                                        checked={benefit?.active}
                                        onChange={() =>
                                            dispatch(
                                                changeBenefitStatus(benefit)
                                            )
                                        }
                                    />
                                </Item>
                            </Grid>
                        ))}
                    <Grid item xs={12}>
                        <div style={{ marginTop: '2rem' }}>
                            <Typography variant="h6">YOU</Typography>
                        </div>
                    </Grid>
                    {benefits?.benefits
                        .filter((x) => x.product === 'You' && x.benefitId === 9)
                        .map((benefit) => (
                            <Grid item xs={12} key={benefit?.benefitId}>
                                <Item>
                                    <Grid
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img
                                            style={{
                                                width: '35px',
                                                height: '35px',
                                                marginRight: '8px'
                                            }}
                                            src={
                                                benefitsIcons[
                                                    benefit?.description
                                                ]
                                            }
                                            alt=""
                                        />
                                        <Grid>
                                            <Typography
                                                variant="subtitle1"
                                                component="div"
                                                sx={{ fontSize: '18px' }}
                                            >
                                                {benefit?.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Switch
                                        color="secondary"
                                        checked={benefit?.active}
                                        onChange={() =>
                                            dispatch(
                                                changeBenefitStatus(benefit)
                                            )
                                        }
                                    />
                                </Item>
                            </Grid>
                        ))}
                        <div style={{ marginTop: '2rem' }}>
                            <Grid item xs={12} md={4}>
                                <Item sx={{ border: '1px solid #C0C0C0FF' }}>
                                    <Grid
                                        display="flex"
                                        flexDirection="column"
                                        rowGap="15px"
                                    >
                                        <Grid
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <img
                                                style={{
                                                    width: '35px',
                                                    height: '35px',
                                                    marginRight: '8px'
                                                }}
                                                src={WexpIconOrangeCreditCard}
                                                alt=""
                                            />
                                            <Typography
                                                variant="subtitle1"
                                                component="div"
                                                sx={{ fontSize: '18px' }}
                                            >
                                                Saldo Livre
                                            </Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography
                                                variant="subtitle1"
                                                component="div"
                                                sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: '16px'
                                                }}
                                            >
                                                Oque é ?
                                            </Typography>
                                            <Typography
                                                gutterBottom
                                                variant="body2"
                                                component="div"
                                                sx={{
                                                    fontSize: '15px',
                                                    color: '#686868'
                                                }}
                                            >
                                                Saldo Livre é função que permite ao
                                                usuário utilizar seu saldo, ou parte
                                                dele, sem restrições de compra, como um
                                                cartão de crédito comum. É possível
                                                selecionar quanto oferecer de saldo.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Item>
                            </Grid>
                        </div>
                    
                </Grid>

                <Footer />
            </Container>
        </>
    );
};

export default FreeBalance;
