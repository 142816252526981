import React from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Wexp Components
import ResponsiveAppBar from '../components/NavBar';
import BackButton from '../components/BackButton';
import Footer from '../components/Footer';
import CardGrid from '../components/grids/CardGrid';

// Store
import { useSelector, useDispatch } from 'react-redux';
import {
    ChangeCardStatus,
    GetUserCard,
    ListCompanyRequests
} from '../store/_Entities/Card';

const Cards = () => {
    const { card, user } = useSelector((state) => state.entities);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(ListCompanyRequests(user.data.companyID));
    }, []);

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <BackButton />
                <Grid container>
                    <Grid xs={12}>
                        <h2>Cartões</h2>
                    </Grid>
                </Grid>
                <Grid container rowGap={8} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12}>
                        <CardGrid />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Cards;
