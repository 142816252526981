import React, { useEffect, useState } from 'react';

// Mui components
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Button } from '@mui/material';

// Utils
import { fromFloatToCurrency } from '../../utils/format';

import { getDownloadBoleto } from '../../store/_Entities/Transfer';

import { useDispatch, useSelector } from 'react-redux';

export default function TransactionBoletoGrid({ rows }) {

    const [downloadBoleto, setDownloadBoleto] = useState(false)

    const dispatch = useDispatch();
    const { transfer } = useSelector(
        (state) => state.entities
    );

    const downloadBoletoHandler = async (transferID) => {
        dispatch(getDownloadBoleto(transferID));
        setDownloadBoleto(true);
    }

    useEffect(()=>{
        if(downloadBoleto && transfer.boletoDownloadFile && transfer.boletoDownloadFile !== ''){
            const url = `data:application/pdf;base64,${transfer.boletoDownloadFile}`
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'boleto.pdf');
            link.click();
        }

    },[transfer.boletoDownloadFile])

const sizeCol = 130;
const columns = [
    {
        field: 'created',
        headerName: 'Data de criação',
        minWidth: sizeCol,
        flex:1,
        headerAlign: 'center',
        align: 'center',
        type: 'date',
        valueGetter: (params) => {
            const dataHoraString = params.value;
            const dataHoraFormatada = dataHoraString.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})/, "$3-$2-$1T$4:$5:$6");
            return new Date(dataHoraFormatada).toISOString()
    },
        valueFormatter: (params) =>{
            const options = { timeZone: 'America/Sao_Paulo', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            return new Date(params.value).toLocaleDateString('pt-BR', options);
        }
    },
    {
        field: 'description',
        headerName: 'Descrição',
        minWidth: sizeCol,
        flex:1,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'totalAmount',
        headerName: 'Valor',
        renderCell: (params) => fromFloatToCurrency(params.row.totalAmount / 100),
        minWidth: sizeCol,
        flex:1,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: sizeCol,
        flex:1,
        headerAlign: 'center',
        align: 'center'
    },
    {
        field: 'download',
        headerName: 'Download',
        minWidth: sizeCol,
        flex:1,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            if(params.row.transferStatusID === 6){
                return (
                    <Button onClick={() => downloadBoletoHandler(params.row.transferID)}>
                        Baixar
                    </Button>
                );
            }
        }
    },
    
]

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                disableSelectionOnClick
                columns={columns}
                flex={1}
                pageSize={10}
                getRowId={(row) => row.orderID}
                rowsPerPageOptions={[5]}
                initialState={{
                    sorting: {
                      sortModel: [
                        {
                          field: 'created',
                          sort: 'desc',
                        },
                      ],
                    },
                  }}
            />
        </div>
    );
}
