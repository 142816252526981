import React from 'react';

// MUI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

// React-Router
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate()

    const goBack = () => navigate(-1);

    return (
        <Grid container sx={{ marginTop: "10px" }}>
            <Grid xs={12}>
                <Button
                   onClick={goBack} sx={{ my: 2, display: 'block', textTransform: "capitalize", fontSize: "15px" }}
                >
                    {"<"} Voltar
                </Button>
            </Grid>
        </Grid>
    );
};

export default BackButton;