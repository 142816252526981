import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Link from '@mui/material/Link';
import { useForm } from "react-hook-form";

import InputMd from "../components/Inputs";
import ColorButton from "../components/Buttons";
import logo from "../assets/images/logow.png";
import cancel from "../assets/images/cancel.png";
import check from "../assets/images/check.png";
import { TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser, validateCaptcha } from "../store/_Entities/User.ts";
import { SHOW_SNACK } from "../store/_UI/Snackbar";
import { useNavigate } from "react-router-dom";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { set } from "date-fns";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const dispatch = useDispatch();
  const [firstLogin, setFirstLogin] = useState(true)

  const { user } = useSelector((state) => state.entities);
  const [userData, setUserData] = useState({});

  const [recaptchaSubmitted, setRecaptchaSubmitted] = useState(false);

  const onSubmit = async (data) => {
    const token = await executeRecaptcha('login');
    dispatch(validateCaptcha(token));
    setUserData(data);
    setRecaptchaSubmitted(true);
  };
  
  useEffect(()=>{
    if(user.validCaptcha && recaptchaSubmitted){
      dispatch(loginUser(userData));
      setFirstLogin(false);
    }
  },[user.validCaptcha])
  

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openSuccess, setOpenSuccess] = useState(false);
  const handleOpenSuccess = () => setOpenSuccess(true);
  const handleCloseSuccess = () => setOpenSuccess(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/select-company");
    }
  }, [user.isLoggedIn]);

  return (
    <div className="login">
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={12}
          md={6}
          sx={{ minHeight: "50vh" }}
        >
          <Grid
            xm={12}
            maxWidth={600}
            sx={{
              background: "white",
              padding: "25px 100px",
              borderRadius: "10px",
            }}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                maxWidth: "400px",
              }}
              
            >
              
              <img className="img-login" src={logo} alt="" />
              <TextField
                label="Email"
                className="login-input"
                variant="standard"
                fullWidth
                {...register("email")}
              />
              <TextField
                label="Senha"
                className="login-input"
                type="password"
                variant="standard"
                {...register("password")}
                fullWidth
                margin="normal"
              />
            {(user.error && !firstLogin) && <p style={{ color: 'red' }}>Usuário e/ou senha inválidos</p>}
            {(!user.validCaptcha && recaptchaSubmitted && !user.loading && firstLogin) && <p style={{ color: 'red' }}>Não foi possível validar sua solicitação</p>}

              <Link
                className="link-password-reset"
                href="https://auth.wexp.com.br/account/ForgotPassword"
              >
                Esqueceu sua senha?
              </Link>

              <div id="recaptcha"/>

              <ColorButton text={user.loading ? <CircularProgress color={"inherit"} size={25} thickness={5}/> : 'ENTRAR'} type="submit" />
            </form>

            {/*<Button*/}
            {/*  onClick={handleOpen}*/}
            {/*  sx={{ color: "#EE7330", marginTop: "20px" }}*/}
            {/*>*/}
            {/*  REDEFINIR SENHA*/}
            {/*</Button>*/}
            {/*<Modal*/}
            {/*  open={open}*/}
            {/*  onClose={handleClose}*/}
            {/*  aria-labelledby="modal-modal-title"*/}
            {/*  aria-describedby="modal-modal-description"*/}
            {/*>*/}
            {/*  <Box className="modal-login" sx={style}>*/}
            {/*    <img*/}
            {/*      className="cancel"*/}
            {/*      src={cancel}*/}
            {/*      alt=""*/}
            {/*      onClick={handleClose}*/}
            {/*    />*/}
            {/*    <Typography id="modal-modal-title" variant="h6" component="h2">*/}
            {/*      Para redefinir a senha digite seu email*/}
            {/*    </Typography>*/}
            {/*    <InputMd label="Email" className="login-input" />*/}
            {/*    <ColorButton text="ENVIAR" onClick={handleOpenSuccess} />*/}
            {/*  </Box>*/}
            {/*</Modal>*/}

            {/* <ModalAlertSimple show={openSuccess} handleCloseSucces={handleCloseSuccess}/> */}

            {/*<Modal*/}
            {/*  open={openSuccess}*/}
            {/*  onClose={handleCloseSuccess}*/}
            {/*  aria-labelledby="modal-modal-title"*/}
            {/*  aria-describedby="modal-modal-description"*/}
            {/*>*/}
            {/*  <Box className="modal-login" sx={style}>*/}
            {/*    <img*/}
            {/*      className="cancel"*/}
            {/*      src={cancel}*/}
            {/*      alt=""*/}
            {/*      onClick={handleCloseSuccess}*/}
            {/*    />*/}
            {/*    <Typography id="modal-modal-title" variant="h6" component="h2">*/}
            {/*      Email enviado com sucesso!*/}
            {/*    </Typography>*/}
            {/*    <img className="check" src={check} alt="" />*/}
            {/*  </Box>*/}
            {/*</Modal>*/}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
