// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    isLoading: 0
};

export const slice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading += 1;
        },
        stopLoading: (state) => {
            state.isLoading -= 1;
        },
        clearLoading: (state) => {
            state.isLoading = 0;
        }
    }
});

export const { startLoading, stopLoading, clearLoading } = slice.actions;

export default slice.reducer;
