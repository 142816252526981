import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export default function CustomizedButtons({ text, type, color = "primary", }) {
  return (
    <Stack spacing={2} direction="row">
      <Button
        variant="contained"
        type={type}
        sx={{
          width: "140px",
          height: "30px",
          marginTop: "30px",
        }}
        color={color}
      >
        {text}
      </Button>
    </Stack>
  );
}
