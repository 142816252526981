// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { IState } from './interfaces/BalanceReturnConfiguration';
import { Endpoints } from '../../constants/endpoints';
import { IApi } from '../middleware/interfaces/api';
import { IBalanceReturnConfigurationResult, IBalanceReturnConfigurationRequest } from '../../interfaces/BalanceReturnConfiguration';
import { GatewayApiUrl } from '../../constants/endpoints';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    balanceReturnConfiguration: {
        BalanceReturnConfigurationID: '',
        BalanceReturnDate: 0,
        CreatedByUserID: ''
    }
};

export const slice = createSlice({
    name: 'balanceReturn',
    initialState,
    reducers: {
        BALANCE_RETURN_CONFIGURATION_REQUESTED: (
            balanceReturnConfiguration: IState,
            action: PayloadAction<IState>
        ) => {
            balanceReturnConfiguration.loading = true;
            balanceReturnConfiguration.error = false;
            balanceReturnConfiguration.success = false;
        },

        BALANCE_RETURN_CONFIGURATION_FAILED: (balanceReturnConfiguration: IState, action: PayloadAction<IState>) => {
            balanceReturnConfiguration.loading = false;
            balanceReturnConfiguration.error = true;
            balanceReturnConfiguration.success = false;
        },

        BALANCE_RETURN_CONFIGURATION_RETRIEVED: (balanceReturnConfiguration: IState, action: PayloadAction<any>) => {
            balanceReturnConfiguration.loading = false;
            balanceReturnConfiguration.error = false;
            balanceReturnConfiguration.success = true;
            const { result } = action.payload;
            balanceReturnConfiguration.balanceReturnConfiguration = result;
        },
        CREATE_BALANCE_RETURN_CONFIGURATION_SUCCESS: (
            balanceReturnConfiguration: IState,
            action: PayloadAction<any>
        ) => {
            const { result } = action.payload;
        }
    }
});

export const {
    BALANCE_RETURN_CONFIGURATION_REQUESTED,
    BALANCE_RETURN_CONFIGURATION_FAILED,
    BALANCE_RETURN_CONFIGURATION_RETRIEVED,
    CREATE_BALANCE_RETURN_CONFIGURATION_SUCCESS
} = slice.actions;

export default slice.reducer;

const url: string = GatewayApiUrl + '/BalanceReturnConfiguration';

export const GetUserBalanceReturnConfig = (userID: string) => {
    const urlParams = new URLSearchParams();
    urlParams.append('UserID', userID);
    const request: IApi = {
        axiosConfig: {
            url: Endpoints.BalanceReturnConfiguration.GetUserBalanceReturnConfig,
            method: 'get',
            params: urlParams
        },
        onAction: {
            onStart: BALANCE_RETURN_CONFIGURATION_REQUESTED.type,
            onSuccess: BALANCE_RETURN_CONFIGURATION_RETRIEVED.type,
            onError: BALANCE_RETURN_CONFIGURATION_FAILED.type
        }
    };
    return apiCallBegan(request);
};

export const PostBalanceReturnConfiguration = (balanceReturnConfiguration: IBalanceReturnConfigurationRequest) => {
    const request: IApi = {
        axiosConfig: {
            url: url,
            method: 'post',
            data: balanceReturnConfiguration
        },
        onAction: {
            onSuccess: CREATE_BALANCE_RETURN_CONFIGURATION_SUCCESS.type,
            showToast: true
        }
    };
    return apiCallBegan(request);
};