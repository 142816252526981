import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import {FormControl, InputLabel, Select} from '@mui/material';

const CompanyUsersSelector = ({ users, setUserFilter, ...MUIProps}) => {
    const useStyles = makeStyles(theme => ({
        menuPaper: {
          maxHeight: 200
        }
      }));

    const classes = useStyles()

    const handleSelectChange = (event) => {
        setUserFilter(event.target.value)
    }

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="state-select-standard-label">Usuários</InputLabel>
            <Select
                {...MUIProps}
                labelId="state-select-standard-label"
                id="state-select-standard"
                label="Usuários"
                defaultValue="0"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(event) => handleSelectChange(event)}
            >
                <MenuItem value={"0"}>{"Todos"}</MenuItem>
                {users.map((user) => {
                    return (
                        <MenuItem
                            value={user.userID}>{user.firstName + ' ' + user.lastName}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
};

export default CompanyUsersSelector;