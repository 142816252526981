import React from "react";
import Card, { CardProps } from "@mui/material/Card";

export default function RoundedCard(props: CardProps & { children: React.ReactNode }) {
  return (
    <Card
      sx={{
        borderRadius: 2,
        padding: "0.5rem",
        boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
      }}
      {...props}
    >
      {props.children}
    </Card>
  );
}
