import React from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import BackButton from '../../components/BackButton';
import Footer from '../../components/Footer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExtractGridOrder from '../../components/grids/ExtractGridOrder';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCompanyTransferStatements } from '../../store/_Entities/Statement';

const rows = [
    {
        id: 1,
        orderName: 'Suporteee',
        creationDate: '19/11/2021',
        status: 'Pago',
        value: 200
    },
    {
        id: 2,
        orderName: 'Suporte',
        creationDate: '20/11/2021',
        status: 'Pago',
        value: 310
    }
];

const Orders = () => {
    const dispatch = useDispatch();
    const { user, statement } = useSelector((state) => state.entities);

    useEffect(() => {
        dispatch(getCompanyTransferStatements(user.data?.companyID));
    }, []);

    console.log(statement?.statements)

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <BackButton />
                <Grid container>
                    <Grid xs={12}>
                        <h2>Pedidos de Benefício</h2>
                    </Grid>
                </Grid>
                <Grid container rowGap={8}>
                    <Grid
                        container
                        xs={12}
                        columnGap={6}
                        rowGap={6}
                        flexWrap="nowrap"
                    >
                        <Grid item xs={12} md={4}>
                            <Card sx={Styles.cardStyle}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Adicionar Individualmente
                                    </Typography>
                                    <Typography
                                        sx={Styles.greyText}
                                        color="text.secondary"
                                    >
                                        Selecione os colaboradores
                                        individualmente
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        href="/orders/add/individual"
                                    >
                                        Adicionar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={Styles.cardStyle}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Adicionar por planilha
                                    </Typography>
                                    <Typography
                                        sx={Styles.greyText}
                                        color="text.secondary"
                                    >
                                        Para pedidos com grande número de
                                        colaboradores
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        href="/orders/add/batch"
                                    >
                                        Adicionar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        {user?.data?.approver && (
                            <Grid item xs={12} md={4}>
                                <Card sx={Styles.cardStyle}>
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                        >
                                            Aprovar Transferência
                                        </Typography>
                                        <Typography
                                            sx={Styles.greyText}
                                            color="text.secondary"
                                        >
                                            Aprovação de Transferência
                                            solicitada
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            href="/orders/approve-tranfer"
                                        >
                                            Aprovar
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Pedidos Passados</Typography>
                        <ExtractGridOrder rows={statement?.statements} />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Orders;

const Styles = {
    cardStyle: {
        borderRadius: 2,
        padding: '0.5rem',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)'
    },
    greyText: {
        mb: 1.5,
        minHeight: '3rem'
    }
};
