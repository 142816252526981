export const moneyFormat = (value) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

export const fromFloatToCurrency = (value) => {
    return parseFloat(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    });
}

export const formatDateToPtLocale = (date) => {
    const dateObj = new Date(date);

    return dateObj.toLocaleDateString("pt-BR", {timeZone: 'UTC'});
}

/*
* Receives a date on pt-BR format (dd-mm-yyyy) and converts to (yyyy-mm-dd)
*/
export const formatDateStringToSwapFormat = (date) => {
    let separatedDate = date.toString().split('/');

    return separatedDate[2] + "-" + separatedDate[1] + "-" + separatedDate[0];
}
