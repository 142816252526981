import React from "react";
import { Card, CardContent, Typography } from '@mui/material';

// Store
import { fromFloatToCurrency } from '../utils/format';

export default function Totalizer( {totalizerValue, totalizerText, color} ) {
  return (
    <Card
    sx={{
        minWidth: 275,
        boxShadow: '0px 0px 11px 0px rgba(0,0,0,0.25)',
        borderRadius: '10px',
        backgroundColor: color
    }}
>
    <CardContent
        sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        }}
    >
        <Typography
            fontWeight={400}
            variant="h6"
            component="div"
            sx={{ color: '#fff' }}
        >
            {totalizerText}
        </Typography>
        <Typography
            fontWeight={500}
            variant="h5"
            component="div"
            sx={{ color: '#fff' }}
        >
            {totalizerValue
                ? fromFloatToCurrency(
                    totalizerValue
                )
                : ""}
        </Typography>
    </CardContent>
  </Card>
  );
}
