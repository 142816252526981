// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { IState, IApproveTransferRequest } from './interfaces/ApproveTransfer';
import { Endpoints } from '../../constants/endpoints';
import { IApi } from '../middleware/interfaces/api';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    approveTransfers: []
};

export const slice = createSlice({
    name: 'approveTransfer',
    initialState,
    reducers: {
        APPROVE_TRANSFER_REQUESTED: (
            approveTransfer: IState,
            action: PayloadAction<IState>
        ) => {
            approveTransfer.loading = true;
            approveTransfer.error = false;
            approveTransfer.success = false;
            approveTransfer.approveTransfers = [];
        },

        APPROVE_TRANSFER_FAILED: (
            approveTransfer: IState,
            action: PayloadAction<IState>
        ) => {
            approveTransfer.loading = false;
            approveTransfer.error = true;
            approveTransfer.success = false;
            approveTransfer.approveTransfers = [];
        },

        APPROVE_TRANSFER_RETRIEVED: (
            approveTransfer: IState,
            action: PayloadAction<any>
        ) => {
            approveTransfer.loading = false;
            approveTransfer.error = false;
            approveTransfer.success = true;
            const { result } = action.payload;
            approveTransfer.approveTransfers = result;
        },
        APPROVE_TRANSFER_UPDATE_RETRIEVED: (
            approveTransfer: IState,
            action: PayloadAction<any>
        ) => {
            approveTransfer.loading = false;
            approveTransfer.error = false;
            approveTransfer.success = true;
        }
    }
});

export const { APPROVE_TRANSFER_REQUESTED, APPROVE_TRANSFER_FAILED, APPROVE_TRANSFER_RETRIEVED, APPROVE_TRANSFER_UPDATE_RETRIEVED } =
    slice.actions;

export default slice.reducer;

export const getApproveTranfer = (companyID: number) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Transfer.GetApproveTranfer(companyID),
        },
        onAction: {
            onStart: APPROVE_TRANSFER_REQUESTED.type,
            onSuccess: APPROVE_TRANSFER_RETRIEVED.type,
            onError: APPROVE_TRANSFER_FAILED.type,
        }
    });
};

export const UpdateApproveTranfer = (data: IApproveTransferRequest) => {
    return apiCallBegan({
        axiosConfig: {
            url: Endpoints.Transfer.UpdateAprroveTransfer,
            method: 'put',
            data: data
        },
        onAction: {
            onStart: APPROVE_TRANSFER_REQUESTED.type,
            onSuccess: APPROVE_TRANSFER_UPDATE_RETRIEVED.type,
            onError: APPROVE_TRANSFER_FAILED.type,
        }
    })
}
