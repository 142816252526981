import {
    IAccountHolder,
    IAccountHolderState,
    IAllAccountHoldersResult,
    IBankUserCheckResult
} from './interfaces/AccountHolder';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import { GatewayApiUrl } from '../../constants/endpoints';
import { apiCallBegan } from '../api';
import {IAddress} from "../../interfaces/Address";

const initialState: IAccountHolderState = {
    error: false,
    loading: false,
    success: false,
    companyAccountHolders: [],
    bankUserCheck: {
        bankUser: false,
        description: ''
    },
    holderCards: [],
    fetchedSingleAccountHolder: {
        birthDate: "",
        document: "",
        email: "",
        firstName: "",
        accountHolderID: "",
        created: "",
        lastName: "",
        motherName: "",
        phone: "",
        kycStatus: "",
        updated: "",
        address: {
            street: "",
            number: "",
            complement: "",
            neighborhood: "",
            postalCode: "",
            city: "",
            state: "",
            type: ""
        },
        userID: "",
        company: "",
        companyID: 0,
        companies: [
            {
                bankAdministratorID: 0,
                accountHolderID: "",
                treasuryAccountHolderID: "",
                userID: "",
                companyID: 0,
                companyName: "",
                active: false,
                created: "",
                updated: "",
            }
          ]
    }
};

const slice = createSlice({
    name: 'accountHolder',
    initialState,
    reducers: {
        ACCOUNT_HOLDER_REQUESTED: (
            accountHolder: IAccountHolderState,
            action: PayloadAction<IRequestResponse<IAccountHolderState>>
        ) => {
            accountHolder.loading = true;
            accountHolder.error = false;
            accountHolder.success = false;
        },
        ACCOUNT_HOLDER_FAILED: (
            accountHolder: IAccountHolderState,
            action: PayloadAction<IRequestResponse<IAccountHolderState>>
        ) => {
            accountHolder.loading = false;
            accountHolder.error = true;
            accountHolder.success = false;
        },
        ACCOUNT_HOLDER_SUCCESS: (
            accountHolder: IAccountHolderState,
            action: PayloadAction<IRequestResponse<IAccountHolderState>>
        ) => {
            accountHolder.loading = false;
            accountHolder.error = false;
            accountHolder.success = true;
        },
        GET_ALL_ACCOUNT_HOLDER_BY_COMPANY_SUCCESSFULLY: (
            accountHolder: IAccountHolderState,
            action: PayloadAction<IRequestResponse<IAllAccountHoldersResult>>
        ) => {
            accountHolder.loading = false;
            accountHolder.error = false;
            accountHolder.companyAccountHolders =
                action.payload.result.accountHolders;
        },
        CHECK_ACCOUNT_HOLDER_IS_BANK_USER: (
            accountHolder: IAccountHolderState,
            action: PayloadAction<IRequestResponse<IBankUserCheckResult>>
        ) => {
            accountHolder.loading = false;
            accountHolder.error = false;
            accountHolder.bankUserCheck = action.payload.result;
        },
        GET_ACCOUNT_HOLDER_CARDS_SUCCESSFULLY: (
            accountHolder: IAccountHolderState,
            action: PayloadAction<any>
        ) => {
            accountHolder.holderCards = action.payload.result;
        },
        GET_SINGLE_ACCOUNT_HOLDER_SUCCESSFULLY: (
            accountHolder: IAccountHolderState,
            action: PayloadAction<IRequestResponse<IAccountHolder>>
        ) => {
            accountHolder.loading = false;
            accountHolder.error = false;
            accountHolder.fetchedSingleAccountHolder = action.payload.result;
        },
    }
});

export const {
    ACCOUNT_HOLDER_REQUESTED,
    ACCOUNT_HOLDER_FAILED,
    ACCOUNT_HOLDER_SUCCESS,
    GET_ALL_ACCOUNT_HOLDER_BY_COMPANY_SUCCESSFULLY,
    CHECK_ACCOUNT_HOLDER_IS_BANK_USER,
    GET_ACCOUNT_HOLDER_CARDS_SUCCESSFULLY,
    GET_SINGLE_ACCOUNT_HOLDER_SUCCESSFULLY
} = slice.actions;

export default slice.reducer;

const url: string = GatewayApiUrl + '/AccountHolder';

export const GetAllAccountHoldersByCompanyId = (companyID: string) => {
    const request: IApi = {
        axiosConfig: {
            url: url + `/GetAllAccountHolders/${companyID}`,
            method: 'get'
        },
        onAction: {
            onStart: ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: GET_ALL_ACCOUNT_HOLDER_BY_COMPANY_SUCCESSFULLY.type,
            onError: ACCOUNT_HOLDER_FAILED.type
        }
    };

    return apiCallBegan(request);
};

export const GetAccountHoldersByUserId = (userID: string) => {
    const request: IApi = {
        axiosConfig: {
            url: url + `/GetIndividualAccountHolder/${userID}`,
            method: 'get'
        },
        onAction: {
            onStart: ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: GET_SINGLE_ACCOUNT_HOLDER_SUCCESSFULLY.type,
            onError: ACCOUNT_HOLDER_FAILED.type
        }
    };

    return apiCallBegan(request);
};

export const PostAdministrator = (userID: string, admBank: boolean = true) => {
    const request: IApi = {
        axiosConfig: {
            url: url + `/PostAdministrator`,
            method: 'post',
            data: {
                userID,
                admBank
            }
        },
        onAction: {
            onStart: ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: ACCOUNT_HOLDER_SUCCESS.type,
            onError: ACCOUNT_HOLDER_FAILED.type,
            showToast: true
        }
    };

    return apiCallBegan(request);
};

export const CheckIfUserExistsByDocument = (document: string) => {
    const request: IApi = {
        axiosConfig: {
            url: url + `/GetIndividualAccountHolderbyDocument/${document}`,
            method: 'get'
        },
        onAction: {
            onStart: ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: CHECK_ACCOUNT_HOLDER_IS_BANK_USER.type,
            onError: ACCOUNT_HOLDER_FAILED.type
        }
    };

    return apiCallBegan(request);
};

export const CreateNewCollaborator = (accountHolderInfo: IAccountHolder) => {
    const request: IApi = {
        axiosConfig: {
            url: url + `/CreateAccountHolder`,
            method: 'post',
            data: accountHolderInfo
        },
        onAction: {
            onStart: ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: ACCOUNT_HOLDER_SUCCESS.type,
            onError: ACCOUNT_HOLDER_FAILED.type,
            showToast: true
        }
    };

    return apiCallBegan(request);
};

export const GetCollaboratorCards = (userId: string) => {
    return apiCallBegan({
        axiosConfig: {
            url: GatewayApiUrl + `/Card/ListCards/` + userId,
            method: 'get'
        },
        onAction: {
            onStart: ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: GET_ACCOUNT_HOLDER_CARDS_SUCCESSFULLY.type,
            onError: ACCOUNT_HOLDER_FAILED.type
        }
    });
};

export const UpdateCollaborator = (userId: string, collaboratorUpdatedInfo: IAccountHolder) => {
    return apiCallBegan({
        axiosConfig: {
            url: url + `/PutAccountHolder/${userId}`,
            method: 'put',
            data: collaboratorUpdatedInfo
        },
        onAction: {
            onStart: ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: ACCOUNT_HOLDER_SUCCESS.type,
            onError: ACCOUNT_HOLDER_FAILED.type,
            showToast: true
        }
    })
}