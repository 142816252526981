// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { ICompanyTransactionsState } from './interfaces/CompanyTransactions';
import { Endpoints } from '../../constants/endpoints';
import { IApi } from '../middleware/interfaces/api';
import { ICompanyTransactionsResult, ICompanyTransactionsReportResult, IAuthorizationReportRequest } from '../../interfaces/CompanyTransactions';

const initialState: ICompanyTransactionsState = {
    loading: false,
    error: false,
    success: false,
    exportSuccess: false,
    exportFailed: false,
    companyTransactions: {
        total: 0,
        totalPages: 0,
        page: 0,
        transactions: []
    }
};

export const slice = createSlice({
    name: 'companyTransactions',
    initialState,
    reducers: {
        COMPANY_TRANSACTIONS_REQUESTED: (
            companyTransactions: ICompanyTransactionsState,
            action: PayloadAction<ICompanyTransactionsState>
        ) => {
            companyTransactions.loading = true;
            companyTransactions.error = false;
            companyTransactions.success = false;
        },

        COMPANY_TRANSACTIONS_FAILED: (companyTransactions: ICompanyTransactionsState, action: PayloadAction<ICompanyTransactionsState>) => {
            companyTransactions.loading = false;
            companyTransactions.error = true;
            companyTransactions.success = false;
        },

        COMPANY_TRANSACTIONS_RETRIEVED: (companyTransactions: ICompanyTransactionsState, action: PayloadAction<any>) => {
            companyTransactions.loading = false;
            companyTransactions.error = false;
            companyTransactions.success = true;
            const { result } = action.payload;
            companyTransactions.companyTransactions = result;
        },
        EXPORT_TRANSACTIONS_REQUESTED: (
            companyTransactions: ICompanyTransactionsState,
            action: PayloadAction<ICompanyTransactionsState>
        ) => {
            companyTransactions.loading = true;
            companyTransactions.exportSuccess = false;
            companyTransactions.exportFailed = false;

        },

        EXPORT_TRANSACTIONS_FAILED: (companyTransactions: ICompanyTransactionsState, action: PayloadAction<ICompanyTransactionsState>) => {
            companyTransactions.loading = false;
            companyTransactions.exportSuccess = false;
            companyTransactions.exportFailed = true;

        },

        EXPORT_TRANSACTIONS_RETRIEVED: (companyTransactions: ICompanyTransactionsState, action: PayloadAction<any>) => {
            companyTransactions.loading = false;
            companyTransactions.exportSuccess = true;
            companyTransactions.exportFailed = false;
        }
    }
});

export const {
    COMPANY_TRANSACTIONS_REQUESTED,
    COMPANY_TRANSACTIONS_FAILED,
    COMPANY_TRANSACTIONS_RETRIEVED,
    EXPORT_TRANSACTIONS_REQUESTED,
    EXPORT_TRANSACTIONS_FAILED,
    EXPORT_TRANSACTIONS_RETRIEVED
} = slice.actions;

export default slice.reducer;

export const GetCompanyTransactions = (productID: string, startDate: string, endDate: string, companyID: string, skip: number, userID?: string, mcc?: string, benefitID?: string) => {
    const urlParams = new URLSearchParams();
    if (userID && userID !== "0")
        urlParams.append('UserID', userID);
    if(mcc)
        urlParams.append('MCC', mcc);
    if(benefitID)
        urlParams.append('BenefitID', benefitID);
    urlParams.append('ProductID', productID);
    urlParams.append('StartDate', startDate);
    urlParams.append('EndDate', endDate);
    urlParams.append('CompanyID', companyID);
    urlParams.append('Skip', skip.toString());
    urlParams.append('Take', '20');

    const request: IApi = {
        axiosConfig: {
            url: Endpoints.CompanyTransactions.GetCompanyTransactions,
            method: 'get',
            params: urlParams
        },
        onAction: {
            onStart: COMPANY_TRANSACTIONS_REQUESTED.type,
            onSuccess: COMPANY_TRANSACTIONS_RETRIEVED.type,
            onError: COMPANY_TRANSACTIONS_FAILED.type
        }
    };
    return apiCallBegan(request);
};

export const ExportReport = (authorizationReportRequest: IAuthorizationReportRequest) => {

    const request: IApi = {
        axiosConfig: {
            url: Endpoints.CompanyTransactions.ExportReport,
            method: 'post',
            data: authorizationReportRequest
        },
        onAction: {
            onStart: EXPORT_TRANSACTIONS_REQUESTED.type,
            onSuccess: EXPORT_TRANSACTIONS_RETRIEVED.type,
            onError: EXPORT_TRANSACTIONS_FAILED.type
        }
    };
    return apiCallBegan(request);
};