import {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Box, Button, Grid, Modal, TextField, Typography} from "@mui/material";
import WexpIconOrangeCancel from '../../assets/images/cancel.png'
import WexpIconOrangeSuccess from "../../assets/images/corrigir.png";
import LoadingAction from "../LoadingAction";
import StateAbbreviationSelector from "../StateAbbreviationSelector";
import InputWithMask from "../InputWithMask";
import { cepMask } from "../../utils/InputMasks";
import axios from "axios";

const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function AddressForm({ onSubmit, loading, data, showModal, setShowModal,deleteHandler}) {

    const {
        setValue,
        control,
        handleSubmit,
        watch,
        formState: {errors},
        reset
    } = useForm({
        defaultValues: {
            street: data?.street || '',
            neighborhood: data?.neighborhood || '',
            city: data?.city || '',
            state: data?.state || '',
            complement: data?.complement || '',
            number: data?.number || '',
            postalCode: data?.postalCode || '',
            description: data?.description || ''
        },
    });

    const watchCep = watch('postalCode')

    useEffect(() => {        
        if(watchCep && watchCep.replace(/_/g, '').replace(/-/g, '').length === 8){
            loadAddress(watchCep.replace(/-/g, ''))
        }
    }, [watchCep])

    const modalSuccessText = 'Cadastro atualizado com sucesso!' 
    const hasAddress = data && data.street && data.neighborhood && data.city && data.state && data.postalCode;
    const onClickSubmit = handleSubmit(onSubmit);

    const loadAddress = async (cep) => {
        const { status, data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

        if(status === 200 && 'cep' in data){
            setValue('street', data.logradouro)
            setValue('neighborhood', data.bairro)
            setValue('city', data.localidade)
            setValue('state', data.uf)
        }
    }

    const deleteHandlerHandler = () => {
        deleteHandler();
        setValue('street', '');
        setValue('neighborhood', '');
        setValue('city', '');
        setValue('state', '');
        setValue('complement', '');
        setValue('number', '');
        setValue('postalCode', '');
        setValue('description', '');
    }

    return (
        <form
            onSubmit={onClickSubmit}
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "1.5rem",
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>                    
                    <Controller
                        name="postalCode"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="postalCode"
                                label="CEP"
                                variant="standard"
                                fullWidth
                                placeholder="17059157"
                                mask={cepMask}
                                maskChar="_"
                                {...field}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="number"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="number"
                                label="Número"
                                variant="standard"
                                fullWidth
                                placeholder="100"
                                {...field}
                            />}
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="complement"
                        control={control}
                        render={({field}) =>
                            <TextField
                                // required={!userRegisterPage}
                                id="complement"
                                label="Complemento"
                                variant="standard"
                                fullWidth
                                placeholder="Apto 101"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="neighborhood"
                        control={control}
                        render={({field}) =>
                            <TextField
                                //disabled
                                required
                                id="neighborhood"
                                label="Bairro"
                                variant="standard"
                                fullWidth
                                placeholder="Centro"
                                {...field}
                            />}
                    />

                </Grid>

                <Grid item xs={12} md={6}>
                    <Controller
                        name="street"
                        control={control}
                        render={({field}) =>
                            <TextField
                                disabled
                                required
                                id="street"
                                label="Endereço"
                                variant="standard"
                                fullWidth
                                placeholder="Rua, Avenida, Travessa, etc."
                                {...field}
                            />}
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="city"
                        control={control}
                        render={({field}) =>
                            <TextField
                                disabled
                                required
                                id="city"
                                label="Cidade"
                                variant="standard"
                                fullWidth
                                placeholder="São Paulo"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="state"
                        control={control}
                        render={({field}) =>
                            <StateAbbreviationSelector
                                {...field}                                
                                disabled
                                required
                                id="state"
                            />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="description"
                        control={control}
                        render={({field}) =>
                            <TextField
                                id="description"
                                label="Descrição"
                                variant="standard"
                                fullWidth
                                placeholder="Recebimento de correspondência"
                                {...field}
                            />}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        marginTop: "2rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "2rem",
                    }}
                    sx={{
                        "@media (max-width: 600px)": {
                            justifyContent: "space-between", // Altera para espaço entre os itens em telas pequenas
                            flexDirection: "column", // Altera para layout de coluna em telas pequenas
                            alignItems: "flex-start", // Alinha itens à esquerda em telas pequenas
                        },
                    }}
                >
                    {hasAddress && (
                        <div style={{display: "flex", gap: "2rem"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={deleteHandlerHandler}
                        >
                               Remover
                        </Button>
                    </div>
                    )}
                    
                    <div style={{display: "flex", gap: "2rem"}}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                        >
                               {hasAddress ? 'Atualizar' : 'Cadastrar' }             
                        </Button>
                    </div>
                </Grid>

                <Grid>
                        <LoadingAction loading={loading}/>
                </Grid>
            </Grid>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-login" sx={modalContentStyle}>
                    <img className="cancel" src={WexpIconOrangeCancel} alt="Wexp Icon orange cancel"
                         onClick={() => setShowModal(false)}/>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "35px",
                        padding: "20px"
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {
                                modalSuccessText
                            }                            
                        </Typography>
                        <img style={{width: "50px", height: "50px", marginRight: "8px", marginBottom: "25px"}} src={WexpIconOrangeSuccess}
                             alt="Wexp Icon orange success"/>
                    </Grid>
                </Box>
            </Modal>
        </form>
    );
}
