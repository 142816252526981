import React, { useEffect, useState } from 'react';

// Wexp Components
import ResponsiveAppBar from '../../../components/NavBar';
import BackButton from '../../../components/BackButton';

import LoadingAction from "../../../components/LoadingAction";

// MUI
import {
    Typography,
    Container,
    Grid,
    Card,
    Checkbox,
    TextField,
    InputAdornment,
    Button,
    Modal,
    Box
} from '@mui/material';

// Assets
import WexpIconOrangeWallet from '../../../assets/images/wallet.png';
import WexpIconOrangeBarCode from '../../../assets/images/barcode.png';
import WexpIconOrangeOrangeMoneyExchange from '../../../assets/images/wmoney-exchange.png';
import WexpIconOrangeCancel from '../../../assets/images/cancel.png';
import WexpIconOrangeSuccess from '../../../assets/images/corrigir.png';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fromFloatToCurrency } from '../../../utils/format';
import { GetTreasuryAccountHolder } from '../../../store/_Entities/TreasuryAccountHolder';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'; 
import { postTransfer, TRANSFER_RESET } from '../../../store/_Entities/Transfer';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const ChoosePaymentOption = () => {
    const [isSaldoChecked, setIsSaldoChecked] = React.useState(false);
    const [isBoletoChecked, setIsBoletoChecked] = React.useState(false);
    const [isTEDChecked, setIsTEDChecked] = React.useState(false);

    const { newOrder } = useSelector((state) => state.forms);

    const [saldoValue, setSaldoValue] = React.useState(newOrder.total);
    const [boletoValue, setBoletoValue] = React.useState('');
    const [TEDValue, setTEDValue] = React.useState('');

    const [loading, setLoading] = useState(false);

    const { treasuryAccountHolder, user, transfer } = useSelector(
        (state) => state.entities
    );

    const [showModal, setShowModal] = React.useState(false);
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const [currentTreasuryAccountBalance, setCurrentTreasuryAccountBalance] =
        React.useState(
            treasuryAccountHolder?.treasuryAccountHolder?.treasuryAccounts[0]
                ?.balanceAccounts[0]?.balance
        );

    React.useEffect(() => {
        dispatch(GetTreasuryAccountHolder(user.data?.companyID));
        setCurrentTreasuryAccountBalance(
            treasuryAccountHolder.treasuryAccountHolder?.treasuryAccounts[0]
                ?.balanceAccounts[0]?.balance
        );
    }, []);

    const finishOrderRequest = async () => {
        setLoading(true);
        const paymentSetup = {
            saldo: { isChecked: isSaldoChecked, value: saldoValue },
            boleto: { isChecked: isBoletoChecked, value: boletoValue },
            ted: { isChecked: isTEDChecked, value: TEDValue }
        };

        const formattedBenefits = Object.entries(newOrder.benefits).map(
            (benefit) => {
                return {
                    userID: params.userID,
                    balanceAccountID: benefit[0],
                    amount: benefit[1] !== '' ? benefit[1].value : 0
                };
            }
        );

        await dispatch(postTransfer(user.data.companyID, formattedBenefits)); 
    };
    
    useEffect(() => {
        if(transfer.success){
            setShowModal(true);
        }
    }, [transfer.success])

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <BackButton />
                <Grid container display="flex" justifyContent="center">
                    <Grid
                        xs={12}
                        md={7}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                        gap={8}
                    >
                        <Typography
                            alignSelf="flex-start"
                            fontWeight={400}
                            variant="h4"
                        >
                            Escolher forma de pagamento
                        </Typography>

                        <Grid sx={{ width: '100%' }}>
                            <Typography
                                fontWeight={400}
                                variant="h6"
                                component="div"
                            >
                                Valor do pedido
                            </Typography>
                            <Typography
                                fontWeight={400}
                                variant="body1"
                                component="div"
                                sx={{ color: '#737373' }}
                            >
                                {fromFloatToCurrency(newOrder.total)}
                            </Typography>
                        </Grid>

                        <Grid sx={{ width: '100%' }}>
                            <PaymentOptionWrapperCard
                                inputValue={saldoValue}
                                onInputChange={(e) =>
                                    setSaldoValue(e.target.value)
                                }
                            >
                                <Checkbox
                                    checked={isSaldoChecked}
                                    onChange={() =>
                                        setIsSaldoChecked(!isSaldoChecked)
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <img
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        marginRight: '8px'
                                    }}
                                    src={WexpIconOrangeWallet}
                                    alt=""
                                />
                                <Grid>
                                    <Typography
                                        fontWeight={400}
                                        variant="h6"
                                        component="div"
                                    >
                                        Saldo
                                    </Typography>
                                    <Typography
                                        fontWeight={400}
                                        variant="body1"
                                        component="div"
                                        sx={{ color: '#737373' }}
                                    >
                                        {currentTreasuryAccountBalance
                                            ? fromFloatToCurrency(
                                                  currentTreasuryAccountBalance
                                              )
                                            : 0}
                                    </Typography>
                                </Grid>
                            </PaymentOptionWrapperCard>

                            <PaymentOptionWrapperCard
                                inputValue={boletoValue}
                                onInputChange={(e) =>
                                    setBoletoValue(e.target.value)
                                }
                                disableInput
                            >
                                <Checkbox
                                    checked={isBoletoChecked}
                                    disabled
                                    onChange={() =>
                                        setIsBoletoChecked(!isBoletoChecked)
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <img
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        marginRight: '8px'
                                    }}
                                    src={WexpIconOrangeBarCode}
                                    alt=""
                                />
                                <Typography
                                    fontWeight={400}
                                    variant="h6"
                                    component="div"
                                    alignSelf="center"
                                    color="#9d9d9d"
                                >
                                    Boleto (Em Breve)
                                </Typography>
                            </PaymentOptionWrapperCard>

                            <PaymentOptionWrapperCard
                                inputValue={TEDValue}
                                onInputChange={(e) =>
                                    setTEDValue(e.target.value)
                                }
                                disableInput
                            >
                                <Checkbox
                                    checked={isTEDChecked}
                                    disabled
                                    onChange={() =>
                                        setIsTEDChecked(!isTEDChecked)
                                    }
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <img
                                    style={{
                                        width: '45px',
                                        height: '45px',
                                        marginRight: '8px'
                                    }}
                                    src={WexpIconOrangeOrangeMoneyExchange}
                                    alt=""
                                />
                                <Typography
                                    fontWeight={400}
                                    variant="h6"
                                    component="div"
                                    alignSelf="center"
                                    color="#9d9d9d"
                                >
                                    TED (Em Breve)
                                </Typography>
                            </PaymentOptionWrapperCard>
                        </Grid>
                        <Button
                            variant="contained"
                            size="large"
                            disabled={
                                !isSaldoChecked &&
                                !isBoletoChecked &&
                                !isTEDChecked
                            }
                            sx={{ marginBottom: '20px' }}
                            onClick={finishOrderRequest}
                        >
                            FINALIZAR PEDIDO
                        </Button>
                    </Grid>
                </Grid>

                <Modal
                    open={showModal}
                    onClose={() => setShowModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modal-login" sx={modalContentStyle}>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '35px',
                                padding: '20px'
                            }}
                        >
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                Pedido criado com sucesso!
                            </Typography>
                            <img
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    marginRight: '8px'
                                }}
                                src={WexpIconOrangeSuccess}
                                alt="Wexp Icon orange success"
                            />
                            <Button href="/orders" variant="contained">Fechar</Button>
                        </Grid>
                    </Box>
                </Modal>
            </Container>
            <LoadingAction loading={loading} />
        </>
    );
};

const PaymentOptionWrapperCard = ({
    onInputChange,
    inputValue,
    disableInput,
    children
}) => {
    return (
        <Card
            sx={{
                minWidth: 275,
                boxShadow: '0px 0px 11px 0px rgba(0,0,0,0.25)',
                borderRadius: '10px',
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px'
            }}
        >
            <Grid display="flex" justifyContent="space-around" gap={3}>
                {children}
            </Grid>

            <Grid
                display="flex"
                alignItems="center"
                sx={{ width: { xs: '26%', md: '20%' } }}
            >
                {/* TODO: Add input on backend */}
                {/* {!disableInput && (
                    <CurrencyTextField
                        variant="standard"
                        value={inputValue}
                        placeholder="0,00"
                        currencySymbol="R$"
                        //minimumValue="0"
                        decimalCharacter=","
                        digitGroupSeparator="."
                        // onChange={onChange}
                        onChange={(e, value) => onChangeInput(value)}
                    />
                )} */}
            </Grid>
        </Card>
    );
};

export default ChoosePaymentOption;
