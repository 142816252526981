import React, {useEffect, useState} from "react";
import InputWithMask from "../InputWithMask";

// MUI
import { DataGrid, ptBR } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';

// Wexp Components
import { EditButton } from '../EditButton';

// React-router
import { useNavigate } from 'react-router-dom';

// Utils
import { mapStatusToPortuguese } from '../../utils/statusMapper';
import { formatDateToPtLocale } from '../../utils/format';
import { MaskCpf } from '../../utils/mask';
import { filterByValue } from '../../utils/filter';
import { ModalWrapper } from "../Modal";

import { BalanceReturnDate } from '../BalanceReturnDate';

// Store
import { useDispatch, useSelector } from "react-redux";

//Form
import { Controller, useForm } from "react-hook-form";
import {
    GetUserBalanceReturnConfig
} from "../../store/_Entities/BalanceReturnConfiguration";

const sizeCol = 160;

export default function CollaboratorGrid({ data }) {
    const [search, setSearch] = useState('');
    const [editModal, setEditModal] = useState({ show: false, AddressOrInfo: "info" });

    const [selectedUser, setSelectedUser] = useState("");
    const [balanceReturnDate, setBalanceReturnDate] = useState("");

    const { balanceReturnConfiguration } = useSelector((state) => state.entities);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(() => {
        if(selectedUser)
            dispatch(GetUserBalanceReturnConfig(selectedUser));
    }, [selectedUser])

    useEffect(() => {
        if(balanceReturnConfiguration.success){
            setBalanceReturnDate(balanceReturnConfiguration?.balanceReturnConfiguration.balanceReturnDate);
        }
    }, [balanceReturnConfiguration])

    const statusFunctions = {
        Ativo: [
            {
                label: 'Ver detalhes',
                action: (params) => {
                    navigate(`/collaborators/detail/${params.row.userID}`);
                }
            },
            {
                label: 'Retorno de Saldo',
                action: (params) => {
                    setSelectedUser(params.row.userID);
                    setEditModal({ ...editModal, show: true });
                    setBalanceReturnDate('');
                }
            }
            // ,
            // {
            //     label: 'Inativar',
            //     action: () => {
            //         console.log('Inativar');
            //     }
            // }
        ],
        Inativo: [
            {
                label: 'Ativar',
                action: () => {
                    console.log('Ativar');
                }
            }
        ],
        Aguardando: [
            {
                label: 'Editar Contato',
                action: () => {
                    console.log('Editar Contato');
                }
            },
            {
                label: 'Reenviar Código (Email)',
                action: () => {
                    console.log('Reenviar Código Email');
                }
            },
            {
                label: 'Reenviar Código (SMS)',
                action: () => {
                    console.log('Reenviar Código SMS');
                }
            },
            {
                label: 'Inativar',
                action: () => {
                    console.log('Inativar');
                }
            }
        ]
    };
    statusFunctions['Aprovado Automático'] = statusFunctions.Ativo;

    const columns = [
        {
            field: 'accountHolderID',
            headerName: 'ID',
            width: 90,
            hide: true,
            minWidth: sizeCol
        },
        {
            field: 'created',
            headerName: 'Data de Criação',
            valueFormatter: ({ value }) => {
                return formatDateToPtLocale(value);
            },
            minWidth: sizeCol,
            type: 'date',
            headerAlign: 'start',
            align: 'start'
        },
        {
            field: 'firstName',
            headerName: 'Nome',
            renderCell: (params) => (
                <p>{`${params.row.firstName} ${params.row.lastName}`}</p>
            ),
            minWidth: sizeCol + 150,
            headerAlign: 'start',
            align: 'start'
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 250,
            headerAlign: 'start',
            align: 'start'
        },
        {
            field: 'document',
            headerName: 'CPF',
            valueFormatter: ({ value }) => {
                return MaskCpf(value);
            },
            minWidth: sizeCol,
            headerAlign: 'start',
            align: 'start'
        },
        {
            field: 'kycStatus',
            headerName: 'Status',
            renderCell: (params) => (
                <p>{mapStatusToPortuguese(params.row.kycStatus)}</p>
            ),
            minWidth: sizeCol,
            headerAlign: 'start',
            align: 'start'
        },
        {
            field: 'action',
            headerName: 'Ação',
            renderCell: (params) => (
                <EditButton
                    params={params}
                    functions={
                        mapStatusToPortuguese(params.row.kycStatus) in
                            statusFunctions
                            ? statusFunctions[
                            mapStatusToPortuguese(params.row.kycStatus)
                            ]
                            : [
                                {
                                    label: 'Sem Ações',
                                    action: function () { }
                                }
                            ]
                    }
                />
            ),
            minWidth: 50,
            headerAlign: 'start',
            align: 'start'
        }
    ];
    return (
        <div style={{ height: 400, width: '100%' }}>
            <div style={{ width: '25%', margin: '1rem' }}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                    value={search}
                    onChange={(s) => setSearch(s.target.value)}
                    label="Pesquisar"
                    placeholder='ABC'
                    variant="standard"
                    fullWidth
                />
            </div>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={filterByValue(data, search)}
                getRowId={(row) => row.accountHolderID}
                disableSelectionOnClick
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
            />
            <ModalWrapper showModal={editModal.show} closeModalAction={() => setEditModal({ ...editModal, show: false })}
                title={'RETORNO DE SALDO CORPORATIVO'}>
                <BalanceReturnDate userID={selectedUser} balanceReturnDate={balanceReturnDate} onChangeBalanceReturnDate={setBalanceReturnDate} closeModalAction={() => setEditModal({ ...editModal, show: false }, setSelectedUser(""))}/>
            </ModalWrapper>
        </div>
    );
}