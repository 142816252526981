import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import { Endpoints, GatewayApiUrl } from '../../constants/endpoints';
import { apiCallBegan } from '../api';
import {IAddress} from "../../interfaces/Address";
import { Address } from './interfaces/Address';
import { add } from 'date-fns';

interface IExtendedAddress extends IAddress {
    description: string;
}
const initialState: Address = {
    error: false,
    loading: false,
    getSuccess: false,
    postSuccess: false,
    deleteSuccess: false,
    address: {} as IAddress,
};

const slice = createSlice({
    name: 'Address',
    initialState,
    reducers: {
        COMPANY_HOLDER_REQUESTED: (
            state,action
        ) => {
            state.loading = true;
            state.error = false;
            state.getSuccess = false;
            state.postSuccess = false;
            state.deleteSuccess = false;
        },
        COMPANY_HOLDER_FAILED: (
            state,action
        ) => {
            state.loading = false;
            state.error = true;
            state.getSuccess = false;
            state.postSuccess = false;
            state.deleteSuccess = false;
        },
        COMPANY_HOLDER_GET_SUCCESS: (
            state,action
        ) => {
            state.loading = false;
            state.error = false;
            state.getSuccess = true;
            state.postSuccess = false;
            state.deleteSuccess = false;
            state.address = action.payload.result;
        },
        COMPANY_HOLDER_POST_SUCCESS: (
            state,action
        ) => {
            state.loading = false;
            state.error = false;
            state.getSuccess = false;
            state.postSuccess = true;
            state.deleteSuccess = false;
            state.address = action.payload.result;
        },
        COMPANY_HOLDER_DELETE_SUCCESS: (
            state,action
        ) => {
            state.loading = false;
            state.error = false;
            state.getSuccess = false;
            state.postSuccess = false;
            state.deleteSuccess = true;
            state.address = {} as IAddress;
        },
    }    
});

export const {
    COMPANY_HOLDER_FAILED,
    COMPANY_HOLDER_REQUESTED,
    COMPANY_HOLDER_GET_SUCCESS,
    COMPANY_HOLDER_POST_SUCCESS,
    COMPANY_HOLDER_DELETE_SUCCESS
} = slice.actions;

export default slice.reducer;

export const GetCompanyAddress = (companyID: string) => {    
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url : `${Endpoints.Address.CompanyAddress}?companyID=${companyID}`
        },
        onAction: {
            onStart: COMPANY_HOLDER_REQUESTED.type,
            onSuccess: COMPANY_HOLDER_GET_SUCCESS.type,
            onError: COMPANY_HOLDER_FAILED.type,
        }
    });
};

export const PostCompanyAddress = (companyID:number, userID: number, address: IExtendedAddress) => {
    const data = {
        street: address.street,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        postalCode: address.postalCode,
        city: address.city,
        state: address.state,
        type: address.type,
        companyID,
        userID,
        description: address.description
    }
    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url : `${Endpoints.Address.CompanyAddress}`,
            data
        },
        onAction: {
            onStart: COMPANY_HOLDER_REQUESTED.type,
            onSuccess: COMPANY_HOLDER_POST_SUCCESS.type,
            onError: COMPANY_HOLDER_FAILED.type,
        }
    });

}

export const PutCompanyAddress = (companyID:number, userID: number,addressID: string,  address: IExtendedAddress) => {
    const data = {
        street: address.street,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        postalCode: address.postalCode,
        city: address.city,
        state: address.state,
        type: address.type,
        companyID,
        userID,
        description: address.description
    }
    return apiCallBegan({
        axiosConfig: {
            method: 'PUT',
            url : `${Endpoints.Address.CompanyAddress}?addressID=${addressID}`,
            data
        },
        onAction: {
            onStart: COMPANY_HOLDER_REQUESTED.type,
            onSuccess: COMPANY_HOLDER_POST_SUCCESS.type,
            onError: COMPANY_HOLDER_FAILED.type,
        }
    });
}

export const DeleteCompanyAddress = (addressID:string, userID: string) => {
    const data ={
        addressID,
        userID
    }
    return apiCallBegan({
        axiosConfig: {
            method: 'PUT',
            url : `${Endpoints.Address.CompanyAddress}/Delete?addressID=${addressID}&userID=${userID}`,
            data
        },
        onAction: {
            onStart: COMPANY_HOLDER_REQUESTED.type,
            onSuccess: COMPANY_HOLDER_DELETE_SUCCESS.type,
            onError: COMPANY_HOLDER_FAILED.type,
        }
    });
}