import React, {useEffect, useState} from "react";

// MUI
import Container from "@mui/material/Container";
import {Grid} from "@mui/material";

// Wexp Components
import ResponsiveAppBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Step1 from "../../components/forms/AddCollaborator/AddCollaboratorStep1";
import Step2 from "../../components/forms/AddCollaborator/AddCollaboratorStep2";
import Step3 from "../../components/forms/AddCollaborator/AddCollaboratorStep3";

// Store
import {useDispatch, useSelector} from "react-redux";
import {CreateNewCollaborator} from "../../store/_Entities/AccountHolder";

// Utils
import {formatDateStringToSwapFormat} from "../../utils/format";

const AddCollaborator = () => {
    const {user} = useSelector(state => state.entities);

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch();

    const formFlowHandler = (data, nextStep) => {

        if (step === 1 || step === 2)
            setFormData({...formData, data});

        if (step === 3)
            setFormData({...formData, address: data});

        setStep(nextStep)
    }

    useEffect(() => {
        if(formData?.address)
            submitForm();
    }, [formData])

    const prepareDataForSubmit = () => {
        let newFormData = {...formData};

        let concatenateCountryCodeAndPhone = newFormData.data.countryCode + newFormData.data.phone;
        let splitFullName = newFormData.data.name.toString().split(' ');
        let removeFullPhoneSpecialChars = concatenateCountryCodeAndPhone.toString().replace(/\D/g,'');
        let removeDocumentSpecialChars = newFormData.data.cpf.toString().replace(/\D/g,'');
        let firstName = splitFullName[0];
        splitFullName.shift();
        let lastName = splitFullName.join(" ");

        return {
            firstName: firstName,
            lastName: lastName,
            document: removeDocumentSpecialChars,
            email: newFormData.data.email,
            phoneNumber: removeFullPhoneSpecialChars,
            motherName: newFormData.data.motherName,
            birthDate: formatDateStringToSwapFormat(newFormData.data.birthDate),
            companyID: parseInt(user.data?.companyID),
            addresses: [newFormData.address]
        };

    }

    const submitForm = () => {
        const preparedData = prepareDataForSubmit();
        dispatch(CreateNewCollaborator(preparedData));
        setLoading(true);
    }

    return (
        <>
            <ResponsiveAppBar/>
            <Container spacing={11}>
                <Header backButton title="Cadastrar colaborador"/>
                <Grid container rowGap={8}>
                    <Grid item xs={12}>
                        {step === 1 && <Step1 onSubmit={(data) => formFlowHandler(data, 2)}/>}
                        {step === 2 && <Step2 prevData={formData} onSubmit={(data) => formFlowHandler(data, 3)}
                                              onBack={() => setStep(1)}/>}
                        {step === 3 && <Step3 prevData={formData} onSubmit={(data) => formFlowHandler(data, 3)} onBack={() => setStep(2)} loading={loading} setLoading={setLoading}/>}
                    </Grid>
                </Grid>
                <Footer/>
            </Container>
        </>
    );
};

export default AddCollaborator;
