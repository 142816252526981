import React, { useState } from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { filterByValue } from '../../utils/filter';
import { EditButton } from '../EditButton';
import { useNavigate } from 'react-router-dom';

const sizeCol = 230;

export default function AdminGrid({ rows }) {
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const columns = [
        {
            field: 'userId',
            headerName: 'ID',
            width: 90,
            hide: true,
            minWidth: sizeCol
        },
        {
            field: 'name',
            headerName: 'Nome',
            minWidth: sizeCol,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'permission',
            headerName: 'Nível de Permissão',
            minWidth: sizeCol,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                params.row?.permission
                    ? params.row?.permission
                    : 'Administrador'
        },
        {
            field: 'collaborator',
            headerName: 'É Colaborador?',
            renderCell: (params) =>
                params.row.collaborator === true ? 'Sim' : 'Não',
            minWidth: sizeCol,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'active',
            headerName: 'Está Ativo?',
            renderCell: (params) =>
                params.row.active === true ? 'Sim' : 'Não',
            minWidth: sizeCol,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'action',
            headerName: 'Ação',
            renderCell: (params) => (
                <EditButton
                    functions={[
                        {
                            label: 'Ver Detalhes',
                            action: function () {
                                navigate(
                                    `/collaborators/detail/${params.row.userID}`
                                );
                            }
                        }
                    ]}
                />
            ),
            minWidth: sizeCol,
            headerAlign: 'center',
            align: 'center'
        }
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <div style={{ width: '25%', margin: '1rem' }}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                    value={search}
                    onChange={(s) => setSearch(s.target.value)}
                    label="Pesquisar"
                    variant="standard"
                    placeholder='ABC'
                    fullWidth
                />
            </div>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={filterByValue(rows, search)}
                disableSelectionOnClick
                columns={columns}
                pageSize={5}
                getRowId={(row) => row.userID}
                rowsPerPageOptions={[5]}
            />
        </div>
    );
}
