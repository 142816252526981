import React from "react";

// MUI
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Wexp Components
import ResponsiveAppBar from "../components/NavBar";
import Footer from "../components/Footer";
import Header from "../components/Header";
import InputMd from "../components/Inputs"
import CustomizedButtons from "../components/Buttons";
import InputWithMask from "../components/InputWithMask";
import { cnpjMask, phoneMask } from "../utils/InputMasks";

// Store
import { useDispatch, useSelector } from 'react-redux';
import { GetTreasuryAccountHolder } from '../store/_Entities/TreasuryAccountHolder';

const Account = () => {

  const { treasuryAccountHolder, user, statement } = useSelector(
    (state) => state.entities
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(GetTreasuryAccountHolder(user.data?.companyID));
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container spacing={11}>
        <Header backButton title="Dados Gerais" />
        <Card sx={{ minWidth: 275, marginBottom: 15 }}>
          <CardContent sx={{ padding: 5 }}>
            <Grid container rowGap={8} >
              <Grid container spacing={2} rowSpacing={4}>
                <Grid item xs={6} sx={{ margimBottom: 25 }}>
                  <InputMd
                    label="Razão Social"
                    className="login-input"
                    defaultValue={treasuryAccountHolder.treasuryAccountHolder.treasuryAccountHolder.legalName}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputWithMask
                    className="login-input"
                    label="Telefone Contato"
                    variant="standard"
                    fullWidth
                    mask={phoneMask}
                    disabled={true}
                    value={treasuryAccountHolder.treasuryAccountHolder.treasuryAccountHolder.phoneNumber}
                    maskChar="_"
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputWithMask
                    className="login-input"
                    label="CNPJ"
                    variant="standard"
                    fullWidth
                    mask={cnpjMask}
                    disabled={true}
                    value={treasuryAccountHolder.treasuryAccountHolder.treasuryAccountHolder.document}
                    maskChar="_"
                  />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                {/*                 
                <Grid item xs={6}>
                  <InputMd label="Nome Fantasia" className="login-input" />
                </Grid> */}
                <Grid item xs={6}></Grid>
                <Grid item xs={6}></Grid>
                {/* <Grid item xs={6} display="flex" justifyContent="center">
                  <CustomizedButtons text="SALVAR" />
                </Grid> */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Footer />
      </Container>
    </>
  )
}

export default Account;