import React, { useEffect, useState } from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// Wexp Components
import ResponsiveAppBar from '../components/NavBar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import InputMd from '../components/Inputs';
import StateAbbreviationSelector from '../components/StateAbbreviationSelector';
import InputWithMask from '../components/InputWithMask';
import { cepMask } from '../utils/InputMasks';
import AddressForm from '../components/forms/AddressForm';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { GetTreasuryAccountHolder } from '../store/_Entities/TreasuryAccountHolder';
import { GetCompanyAddress, PostCompanyAddress, PutCompanyAddress, DeleteCompanyAddress } from '../store/_Entities/Address';
import {
    Typography
} from '@mui/material';



const Account = () => {

    const { treasuryAccountHolder, user, Address } = useSelector(
        (state) => state.entities
    );

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(GetTreasuryAccountHolder(user.data?.companyID));
        dispatch(GetCompanyAddress(user.data?.companyID));
    }, []);

    
    useEffect(() => {
        if(Address.postSuccess && showModalFlag){
            setLoading(false);
            setShowModal(true);
        }

        if(Address.deleteSuccess && showModalFlag){
            setLoading(false);
            setShowModal(true);
        }

        if(Address.loading){
            setLoading(true);
        }else{
            setLoading(false);
        }

        if(Address.error){
            setLoading(false);
            setShowModalFlag(false);
        }

    }, [Address])

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalFlag, setShowModalFlag] = useState(false);



    const submitForm = async (formData) => {

        setLoading(true);
        setShowModalFlag(true);
        formData.complement = formData.complement || 'S/C'
        formData.neighborhood = formData.neighborhood.replace(/[^a-zA-Z0-9\s]/g, "");
        formData.street = formData.street.replace(/[^a-zA-Z0-9\s]/g, "");
        formData.postalCode = formData.postalCode.replace(/[^a-zA-Z0-9\s]/g, "");
        formData.description = formData.description.replace(/[^a-zA-Z0-9\s]/g, "");
        const resultData = {
            ...formData,
            type: 'shippingAddress',
        }
        if(Address.address.addressID == null){
            dispatch(PostCompanyAddress(+user.data.companyID, user.data.userID, resultData));
        }
        else{
            dispatch(PutCompanyAddress(+user.data.companyID, user.data.userID, Address.address.addressID,resultData));
    }
    }

    const deleteHandler = async () => {
        setLoading(true);
        setShowModalFlag(true);
        dispatch(DeleteCompanyAddress(Address.address.addressID, user.data.userID, ));
    }

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Endereços" />
                <Card sx={{ minWidth: 275, marginBottom: 15 }}>
                    <CardContent sx={{ padding: 5 }}>
                        <Grid container rowGap={8}>
                            <Grid container spacing={2} rowSpacing={4}>
                                <Typography variant="h6">Endereço fiscal</Typography>
                                <Grid item
                                    xs={12}
                                    md={12}
                                    sx={{ margimBottom: 25 }}>
                                    <InputWithMask
                                        label="CEP"
                                        variant="standard"
                                        fullWidth
                                        mask={cepMask}
                                        disabled={true}
                                        value={
                                            treasuryAccountHolder
                                                .treasuryAccountHolder
                                                .treasuryAccountHolder.address
                                                .postalCode
                                        }
                                        maskChar="_"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <InputMd
                                        label="Endereço"
                                        defaultValue={
                                            treasuryAccountHolder
                                                .treasuryAccountHolder
                                                .treasuryAccountHolder.address
                                                .street
                                        }
                                        readOnly={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputMd
                                        label="Número"
                                        defaultValue={
                                            treasuryAccountHolder
                                                .treasuryAccountHolder
                                                .treasuryAccountHolder.address
                                                .number
                                        }
                                        readOnly={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputMd
                                        label="Complemento"
                                        defaultValue={
                                            treasuryAccountHolder
                                                .treasuryAccountHolder
                                                .treasuryAccountHolder.address
                                                .complement
                                        }
                                        readOnly={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputMd
                                        label="Bairro"
                                        defaultValue={
                                            treasuryAccountHolder
                                                .treasuryAccountHolder
                                                .treasuryAccountHolder.address
                                                .neighborhood
                                        }
                                        readOnly={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputMd
                                        label="Cidade"
                                        defaultValue={
                                            treasuryAccountHolder
                                                .treasuryAccountHolder
                                                .treasuryAccountHolder.address
                                                .city
                                        }
                                        readOnly={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <StateAbbreviationSelector
                                        id="state"
                                        defaultValue={
                                            treasuryAccountHolder
                                                .treasuryAccountHolder
                                                .treasuryAccountHolder.address
                                                .state
                                        }
                                        readOnly={true}
                                    />
                                    {/* <InputMd
                    label="Estado"
                    className="login-input"
                    defaultValue={treasuryAccountHolder.treasuryAccountHolder.treasuryAccountHolder.address.state}
                    readOnly={true}
                  /> */}
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}></Grid>

                                {/* <Grid item xs={6} display="flex" justifyContent="center">
                  <CustomizedButtons text="SALVAR"  />
                </Grid> */}
                            </Grid>
                        </Grid>

                        <Grid display="flex" flexDirection="column" gap={5}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}
                            >
                        <Typography variant="h6">Endereço de entrega</Typography>
                        <Typography variant="h8" style={{color:'rgba(0,0,0,0.6)'}}>(Adicione um endereço para habilitar a entrega em lote dos cartões nominais.)</Typography>
                            </div>
                            <AddressForm loading={loading} onSubmit={submitForm} data={Address.address} showModal={showModal} setShowModal={setShowModal} deleteHandler={deleteHandler}/>

                                {/* <Accordion defaultExpanded>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        Endereço de entrega
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container rowGap={8}>
                                            <Grid
                                                container
                                                spacing={2}
                                                rowSpacing={4}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    sx={{ margimBottom: 25 }}
                                                >
                                                    <InputMd label="Endereço" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <InputMd label="Número" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <InputWithMask
                                                        label="CEP"
                                                        variant="standard"
                                                        fullWidth
                                                        mask={cepMask}
                                                        maskChar="_"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <InputMd label="Complemento" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <InputMd label="Bairro" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <InputMd label="Cidade" />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <StateAbbreviationSelector id="state" />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    <AccordionActions>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                        >
                                            Salvar
                                        </Button>
                                    </AccordionActions>
                                </Accordion> */}
                        </Grid>
                    </CardContent>
                </Card>

                <Footer />
            </Container>
        </>
    );
};

export default Account;
