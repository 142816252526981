import React, { useEffect, useState } from "react";
import InputWithMask from "./InputWithMask";

// MUI
import { DataGrid, ptBR } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography';;

// Wexp Components
import { EditButton } from './EditButton';

// React-router
import { useNavigate } from 'react-router-dom';

// Utils
import { mapStatusToPortuguese } from '../utils/statusMapper';
import { formatDateToPtLocale } from '../utils/format';
import { MaskCpf } from '../utils/mask';
import { filterByValue } from '../utils/filter';
import { ModalWrapper } from "./Modal";

//Form
import { Controller, useForm } from "react-hook-form";
import {
    PostBalanceReturnConfiguration
} from "../store/_Entities/BalanceReturnConfiguration";

// Store
import { useDispatch, useSelector } from "react-redux";

// MUI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";


export const BalanceReturnDate = ({ userID, balanceReturnDate, onChangeBalanceReturnDate, closeModalAction }) => {

    const { user } = useSelector((state) => state.entities);


    const {
        control,
        handleSubmit,
        getValues,
    } = useForm({});


    const prepareDataForSubmit = () => {

        return {
            balanceReturnDate: parseInt(balanceReturnDate.replace(/^0+/, '').trim()),
            userID: userID,
            createdByUserID: user.data.userID
        };
    }

    const dispatch = useDispatch();

    const submitAction = () => {
        let preparedData = prepareDataForSubmit();
        dispatch(PostBalanceReturnConfiguration(preparedData));
        closeModalAction();

    }

    return (
        <form onSubmit={handleSubmit(submitAction)}>
            <Grid container rowGap={2}>
                <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    sx={{
                        fontSize: '15px',
                        color: '#686868'
                    }}
                >
                Defina uma data para retornar o saldo Corp do usuário para a conta da empresa:
                </Typography>
                <Grid item xs={12}>
                    <Controller
                        name="balanceReturnDate"
                        control={control}
                        render={({ field }) =>
                            <InputWithMask
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            Todo dia:
                                        </InputAdornment>
                                    )
                                }}
                                required
                                id="balanceReturnDate"
                                label="Data de retorno do saldo"
                                {...field}
                                variant="standard"
                                fullWidth
                                value={balanceReturnDate == 0 ? '' : balanceReturnDate}
                                onChange={(s) => onChangeBalanceReturnDate(s.target.value)}
                                mask={"99"}
                                helperText="O retorno do saldo ocorrerá no último dia corrente caso o mês não tenha a data especificada (ex: dia 31)."
                            />}
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="space-between" sx={{ marginTop: "40px" }}>
                <Button type="submit" variant="contained">Salvar</Button>
            </Grid>
        </form>
    )
}
