import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"
];

const useLogout = (startTime) => {
    const { user } = useSelector((state) => state.entities);
    const [timer, setTimer] = useState(startTime);

    useEffect(() => {
        let myInterval;
        if(user.isLoggedIn){
            myInterval = setInterval(() => {
                if (timer > 0) setTimer(timer - 1);
            }, 1000);

            const resetTimeout = () => setTimer(startTime);

            for (let i in events)
                window.addEventListener(events[i], resetTimeout);

            return () => {
                clearInterval(myInterval);
                for (let i in events)
                    window.removeEventListener(events[i], resetTimeout);
            };
        }
    });

    return timer;
};

export default useLogout;