import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ITreasuryAccountHolderResult,
    ITreasuryAccountHolderState
} from './interfaces/TreasuryAccountHolder';
import { GatewayApiUrl } from '../../constants/endpoints';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import { apiCallBegan } from '../api';

const initialState: ITreasuryAccountHolderState = {
    error: false,
    loading: false,
    success: false,
    treasuryAccountHolder: {
        treasuryAccountHolder: {
            treasuryAccountHolderID: '',
            addedTime: '',
            legalName: '',
            email: '',
            document: '',
            address: {
                street: '',
                number: '',
                complement: '',
                neighborhood: '',
                postalCode: '',
                city: '',
                state: '',
                type: ''
            },
            phoneNumber: '',
            status: ''
        },
        treasuryAccounts: []
    }
};

const slice = createSlice({
    name: 'treasuryAccountHolder',
    initialState,
    reducers: {
        TREASURY_ACCOUNT_HOLDER_REQUESTED: (
            treasuryAccountHolder: ITreasuryAccountHolderState,
            action: PayloadAction<IRequestResponse<ITreasuryAccountHolderState>>
        ) => {
            treasuryAccountHolder.loading = true;
            treasuryAccountHolder.error = false;
            treasuryAccountHolder.success = false;
        },
        TREASURY_ACCOUNT_HOLDER_FAILED: (
            treasuryAccountHolder: ITreasuryAccountHolderState,
            action: PayloadAction<IRequestResponse<ITreasuryAccountHolderState>>
        ) => {
            treasuryAccountHolder.loading = false;
            treasuryAccountHolder.error = true;
            treasuryAccountHolder.success = false;
        },
        TREASURY_ACCOUNT_HOLDER_SUCCESS: (
            treasuryAccountHolder: ITreasuryAccountHolderState,
            action: PayloadAction<IRequestResponse<ITreasuryAccountHolderState>>
        ) => {
            treasuryAccountHolder.loading = false;
            treasuryAccountHolder.error = false;
            treasuryAccountHolder.success = true;
        },
        GET_TREASURY_ACCOUNT_HOLDER_SUCCESSFULLY: (
            treasuryAccountHolder: ITreasuryAccountHolderState,
            action: PayloadAction<
                IRequestResponse<ITreasuryAccountHolderResult>
            >
        ) => {
            treasuryAccountHolder.loading = false;
            treasuryAccountHolder.error = false;
            treasuryAccountHolder.success = true;

            const { result } = action.payload;
            const treasuryAccounts = result.treasuryAccounts.filter(
                (x) => x.status !== 'blocked'
            );
            treasuryAccountHolder.treasuryAccountHolder.treasuryAccounts =
                treasuryAccounts;
            treasuryAccountHolder.treasuryAccountHolder.treasuryAccountHolder =
                result.treasuryAccountHolder;
        }
    }
});

export const {
    TREASURY_ACCOUNT_HOLDER_REQUESTED,
    TREASURY_ACCOUNT_HOLDER_FAILED,
    TREASURY_ACCOUNT_HOLDER_SUCCESS,
    GET_TREASURY_ACCOUNT_HOLDER_SUCCESSFULLY
} = slice.actions;

export default slice.reducer;

const url: string = GatewayApiUrl + '/TreasuryAccountHolder/';

export const GetTreasuryAccountHolder = (companyID: string) => {
    const request: IApi = {
        axiosConfig: {
            url: url + `GetTreasuryAccountHolder/${companyID}`,
            method: 'get'
        },
        onAction: {
            onStart: TREASURY_ACCOUNT_HOLDER_REQUESTED.type,
            onSuccess: GET_TREASURY_ACCOUNT_HOLDER_SUCCESSFULLY.type,
            onError: TREASURY_ACCOUNT_HOLDER_FAILED.type
        }
    };

    return apiCallBegan(request);
};
