import React from 'react';
import {Grid} from "@mui/material";
import WexpIconEmptyOrange from "../assets/images/Empty-amico.png";

const EmptyState = ({message}) => {
    return (
        <Grid container justifyContent="center" >
            <Grid item xs={6} md={8} sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: {xs: "column", md: "row"}, width: "100%"}}>
                <img  src={WexpIconEmptyOrange} style={{width: 300, height: 300}} alt="empty state image"/>
                <p>{message}</p>
            </Grid>
        </Grid>
    );
};

export default EmptyState;