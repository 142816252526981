import React, { useState } from 'react';

// Wexp Components
import Header from '../../components/Header';
import ResponsiveAppBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import ExtractGrid from '../../components/grids/ExtractGrid';
import { filterByDate } from '../../utils/filterByDate';

// MUI
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    Divider
} from '@mui/material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ptLocale from "date-fns/locale/pt-BR";
import addDays from 'date-fns/addDays';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { GetTreasuryAccountHolder } from '../../store/_Entities/TreasuryAccountHolder';
import { fromFloatToCurrency } from '../../utils/format';
import { getCompanyPMATransferStatements, getTreasuryAccountStatementV2 } from '../../store/_Entities/Statement';
import ExportToExcelButton from '../../components/xlsx';
import { max, subMonths } from 'date-fns';

const Balance = () => {
    const { treasuryAccountHolder, user, statement } = useSelector(
        (state) => state.entities
    );

    // filtro default de 1 ano
    var defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
    defaultStartDate.setHours(0, 0, 0, 0);

    var defaultEndDate = new Date();
    defaultEndDate.setHours(0, 0, 0, 0);

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    React.useEffect(() => {
        dispatch(getTreasuryAccountStatementV2(user.data?.companyID,startDate.toLocaleDateString("en-CA"),endDate.toLocaleDateString("en-CA")))
    }, [startDate,endDate]);

    const [currentTreasuryAccountBalance, setCurrentTreasuryAccountBalance] =
        React.useState(
            treasuryAccountHolder.treasuryAccountHolder?.treasuryAccounts[0]
                ?.balanceAccounts[0]?.balance
        );

    const dispatch = useDispatch();

    const handleStartDateChange= (newValue) =>{
        setStartDate(newValue)
    }

    const handleEndDateChange= (newValue)=>{
        setEndDate(newValue)
    }

    React.useEffect(() => {
        setCurrentTreasuryAccountBalance(
            treasuryAccountHolder.treasuryAccountHolder?.treasuryAccounts[0]
                ?.balanceAccounts[0]?.balance
        );
    }, [treasuryAccountHolder]);

    React.useMemo(() => {
        dispatch(GetTreasuryAccountHolder(user.data?.companyID));
    }, []);

    return (
        <>
            <ResponsiveAppBar />

            <Container spacing={11}>
                <Header backButton title="Saldo" />
                <Grid
                    container
                    sx={{
                        justifyContent: { xs: 'center', md: 'space-between' }
                    }}
                    rowGap={5}
                    columnGap={8}
                >
                    <Card
                        sx={{
                            minWidth: 275,
                            boxShadow: '0px 0px 11px 0px rgba(0,0,0,0.25)',
                            borderRadius: '10px',
                            backgroundColor: '#9e9e9e'
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column'
                            }}
                        >
                            <Typography
                                fontWeight={400}
                                variant="h6"
                                component="div"
                                sx={{ color: '#fff' }}
                            >
                                Saldo disponível
                            </Typography>
                            <Typography
                                fontWeight={500}
                                variant="h5"
                                component="div"
                                sx={{ color: '#fff' }}
                            >
                                {currentTreasuryAccountBalance
                                    ? fromFloatToCurrency(
                                          currentTreasuryAccountBalance
                                      )
                                    : 0}
                            </Typography>
                        </CardContent>
                    </Card>
                    <Button
                        variant="outlined"
                        size="large"
                        href="/balance/add"
                        sx={{
                            borderWidth: '2px',
                            maxHeight: '60px',
                            alignSelf: 'center'
                        }}
                    >
                        Adicionar saldo +
                    </Button>

                    {/* <ExportToExcelButton data={filterByDate(statement?.statements, startDate, endDate)}>Exportar para Excel</ExportToExcelButton> */}
                </Grid>

                <Divider sx={{ margin: '60px 0' }} />

                <Grid item xs={12}>
                    <Grid
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            marginBottom: '20px',
                            flexDirection: { xs: 'column', md: 'row' }
                        }}
                    >
                        <Grid>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="De"
                                    views={["year", "month", "day"]}
                                    inputFormat="dd/MM/yyyy"
                                    mask='__/__/____'
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    disableFuture
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptLocale}>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="Até"
                                    views={["year", "month", "day"]}
                                    inputFormat="dd/MM/yyyy"
                                    mask='__/__/____'
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    maxDate={startDate ? addDays(startDate, 30) : null}
                                    
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    <ExtractGrid rows={statement?.statements} />
                </Grid>
            </Container>
            <Footer />
        </>
    );
};

export default Balance;
