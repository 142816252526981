import React from "react";

// Wexp Components
import ResponsiveAppBar from "../../components/NavBar";
import BackButton from "../../components/BackButton";
import Footer from "../../components/Footer";
import cancel from "../../assets/images/cancel.png";

// Utils
import { MaskCpf } from "../../utils/mask";
import { filterByValue } from "../../utils/filter";

// MUI
import Container from "@mui/material/Container";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/system/Box";

// React-Router
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllAccountHoldersByCompanyId,
  PostAdministrator,
} from "../../store/_Entities/AccountHolder";

const mockValues = [
  {
    name: "Andre Insardi",
    email: "andre.insardi@khipo.com.br",
    document: "23444333344",
  },
  {
    name: "Pedro Miotti",
    email: "pedro.miotti@wexp.com.br",
    document: "12345677954",
  },
  {
    name: "Rafael Torres",
    email: "rafael.torres@khipo.com.br",
    document: "23444300074",
  },
  {
    name: "Arthur Olga",
    email: "arthur.olga@khipo.com.br",
    document: "23444355594",
  },
];

const modalStyle = {
  //   position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SelectNewAdmin = () => {
  const [selectedUser, setSelectedUser] = React.useState({});
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const [filteredValues, setFilteredValues] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");

  const dispatch = useDispatch();
  const { companyAccountHolders } = useSelector(
    (state) => state.entities.accountHolder
  );
  const { user } = useSelector((state) => state.entities);

  const handleClose = () => {
    setOpenConfirmationModal(false);
    setSelectedUser({});
  };

  const onClickConfirm = () => {
    dispatch(PostAdministrator(selectedUser.userID));
    setTimeout(() => {
      navigate("/admin/");
    }, 1000);
  };

  const navigate = useNavigate();

  const selectUser = (user) => {
    setSelectedUser(user);
    // navigate(`/admin/add/${user.document}`);
    setOpenConfirmationModal(true);
  };

  React.useEffect(() => {
    setFilteredValues(filterByValue(companyAccountHolders, searchQuery));
  }, [companyAccountHolders, searchQuery]);

  React.useEffect(() => {
    dispatch(GetAllAccountHoldersByCompanyId(user.data.companyID));
  }, []);

  return (
    <>
      <ResponsiveAppBar />
      <Container spacing={11}>
        <BackButton />
        <Grid container display="flex" justifyContent="center">
          <Grid
            xs={12}
            md={7}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Typography alignSelf="flex-start" fontWeight={400} variant="h4">
              Busque pelo colaborador que se tornará administrador
            </Typography>
            {/* <p>{JSON.stringify(companyAccountHolders)}</p> */}

            <Grid sx={{ width: "100%", margin: "3rem" }}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                value={searchQuery}
                onChange={(s) => setSearchQuery(s.target.value)}
                label="Pesquisar"
                placeholder='ABC'
                variant="standard"
                fullWidth
              />
            </Grid>

            <Grid sx={{ width: "100%" }}>
              {filteredValues.map((user) => (
                <Card
                  sx={{
                    minWidth: 275,
                    boxShadow: "0px 0px 11px 0px rgba(0,0,0,0.25)",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", md: "row" },
                    marginBottom: "20px",
                  }}
                  key={user.document}
                >
                  <CardContent>
                    <Typography gutterBottom variant="body1" component="div">
                      <span style={{ fontWeight: "bold" }}>Nome:</span>{" "}
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Typography gutterBottom variant="body1" component="div">
                      <span style={{ fontWeight: "bold" }}>E-mail:</span>{" "}
                      {user.email}
                    </Typography>
                    <Typography variant="body1" component="div">
                      <span style={{ fontWeight: "bold" }}>CPF:</span>{" "}
                      {MaskCpf(user.document)}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={() => selectUser(user)}
                    >
                      Adicionar
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Footer />
      </Container>
      <Modal
        open={openConfirmationModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-login" sx={modalStyle}>
          <img className="cancel" src={cancel} alt="" onClick={handleClose} />
          <Typography id="modal-modal-title" variant="h6">
            Tornar{" "}
            <b>
              {selectedUser.firstName} {selectedUser.lastName}
            </b>{" "}
            administrador?
          </Typography>
          <div style={{ display: "flex", gap: "1.5rem", marginTop: "2rem" }}>
            <Button size="medium" variant="contained" onClick={onClickConfirm}>
              Sim
            </Button>
            <Button
              size="medium"
              variant="outlined"
              style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
              onClick={handleClose}
            >
              Não
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SelectNewAdmin;
