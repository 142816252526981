import React, { useState, useEffect } from "react";
import {useForm, Controller} from "react-hook-form";
import {Button, Grid, TextField, FormControl, InputLabel, Select} from "@mui/material";
import InputWithMask from "../../InputWithMask.tsx";
import {brPhoneMask, usPhoneMask, cpfMask, dateMask} from "../../../utils/InputMasks";
import InputMask from "react-input-mask";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";



export default function AddCollaboratorStep2({onBack, onSubmit, prevData, userRegisterPage}) {
    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: prevData?.data?.name || '',
            email: prevData?.data?.email || '',
            countryCode: prevData?.data?.countryCode || '',
            phone: prevData?.data?.phone || '',
            motherName: prevData?.data?.motherName || '',
            birthDate: prevData?.data?.birthDate || '',
        }
    });


    const [mask, setMask] = useState(brPhoneMask);
    const [countriesList, setCountriesList] = React.useState([]);
    const [selectedValue, setSelectedValue] = useState();

    const COUNTRY_API_URL = "https://restcountries.com/v3.1/alpha";

    const fetchCountries = async () => {
        const countriesToGetInformation = ['br', 'usa'];
        try {
            const response = await axios.get(COUNTRY_API_URL, {
                params: {
                    codes: countriesToGetInformation.join(',')
                }
            });

            setCountriesList(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
        fetchCountries();
    }, []);

    const handleClick = (e) => {
        setSelectedValue(e.currentTarget.dataset.value)
    }

    useEffect(() => {
        if(selectedValue == '+1'){
            setMask(usPhoneMask)
        }
        else {
            setMask(brPhoneMask)
        }
    }, [selectedValue])

    const onClickSubmit = handleSubmit(onSubmit);

    return (
        <form
            onSubmit={onClickSubmit}
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "1.5rem",
                maxWidth: "700px",
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="name"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="name"
                                label="Nome Completo"
                                variant="standard"
                                fullWidth
                                helperText="Nome Completo deve ser igual aos documentos oficiais do colaborador."
                                {...field}
                            />}
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        as={InputMask}
                        name="cpf"
                        mask="999.999.999-99"
                        defaultValue={prevData.data.cpf}
                        control={control}
                        render={({field}) =>
                            <TextField
                                disabled
                                id="CPF"
                                label="CPF"
                                {...field}
                                variant="standard"
                                fullWidth
                            />}
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="email"
                        control={control}
                        render={({field}) =>
                            <TextField
                                disabled={userRegisterPage}
                                required
                                id="email"
                                label="Email"
                                {...field}
                                variant="standard"
                                fullWidth
                                placeholder="email@example.com"
                            />}
                    />

                </Grid>
                <Grid item xs={12} md={6} display="flex">
                    <Grid width="80px">
                        <Controller
                            name="countryCode"
                            control={control}
                            render={({field}) =>
                                <FormControl 
                                variant="standard" 
                                fullWidth >
                                    <InputLabel id="simple-select-standard-label">País</InputLabel>
                                    <Select
                                        {...field}
                                        required
                                        id="countryCode"
                                        label="País"
                                    >
                                        {countriesList.map((country) => {
                                            const addSuffixToCountryCodeIfNecessary = country.idd.suffixes.length === 1 ? country.idd.root + country.idd.suffixes[0] : country.idd.root;
                                            return (
                                                <MenuItem
                                                    value={addSuffixToCountryCodeIfNecessary} onClick={handleClick}>{country.flag} {addSuffixToCountryCodeIfNecessary}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>}
                        />

                    </Grid>

                    <Controller
                        name="phone"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="phone"
                                label="Telefone"
                                {...field}
                                variant="standard"
                                fullWidth
                                mask={mask}
                                maskChar="_"
                            />}
                    />

                </Grid>

                <Grid item xs={12} md={6}>
                    <Controller
                        name="motherName"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="motherName"
                                label="Nome da Mãe"
                                {...field}
                                variant="standard"
                                fullWidth
                            />}
                    />

                </Grid>

                <Grid item xs={12} md={6}>
                    <Controller
                        name="birthDate"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="birthDate"
                                label="Data de Nascimento"
                                {...field}
                                variant="standard"
                                fullWidth
                                mask={dateMask}
                                maskChar="_"
                            />}
                    />

                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        marginTop: "2rem",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <div style={{display: "flex", gap: "2rem"}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={onBack}
                            sx={{paddingRight: "4rem", paddingLeft: "4rem"}}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                        >
                            Próximo
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
}
