import React, {useState, useMemo, useEffect} from 'react';

// MUI
import Grid from '@mui/material/Grid';
import {Box, Button, CardContent, CardHeader, Modal, Typography} from '@mui/material';

// Wexp Components
import TabPanel from '../../TabPanel';
import RoundedCard from '../../RoundedCard.tsx';
import cancel from "../../../assets/images/cancel.png";

// Store
import {useDispatch, useSelector} from 'react-redux';
import {GetUserBalanceAccountGroup} from '../../../store/_Entities/BalanceAccountGroup';

// react-chart
import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';

// Utils
import {moneyFormat} from '../../../utils/format';
import {useNavigate, useParams} from 'react-router-dom';
import EmptyState from "../../EmptyState";

// Assets
import { postReturnBalanceTransfer } from '../../../store/_Entities/Transfer';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function BalanceTab({value, index}) {
    const {user, balanceAccountGroup} = useSelector(
        (state) => state.entities
    );

    const [youBalanceAccounts, setYouBalanceAccounts] = useState([]);
    const [corpBalanceAccounts, setCorpBalanceAccounts] = useState([]);
    const [youChartData, setYouChartData] = useState({
        labels: [],
        datasets: []
    });
    const [corpChartData, setCorpChartData] = useState({
        labels: [],
        datasets: []
    });

    const dispatch = useDispatch();

    const assembleChartData = (balanceAccounts) => {
        let labels = balanceAccounts.map(({description}) => description);
        let datasets = [{data: [], backgroundColor: []}];

        datasets[0].data = balanceAccounts.map(({balance}) => balance);
        datasets[0].backgroundColor = balanceAccounts.map(
            ({colorHex}) => colorHex
        );

        return {labels, datasets};
    };

    const routeParams = useParams();

    useMemo(() => {
        let userID = routeParams.userID;
        dispatch(GetUserBalanceAccountGroup(userID));
    }, [user?.data?.userID]);

    useEffect(() => {
        let balanceAccounts = balanceAccountGroup?.userBalanceAccounts;

        if (balanceAccounts) {
            let filteredYouBalanceAccounts = balanceAccounts.filter(
                (ba) => ba.balanceAccountTypeID === 1
            );
            let filteredCorpBalanceAccounts = balanceAccounts.filter(
                (ba) => ba.balanceAccountTypeID === 2
            );

            setYouBalanceAccounts(filteredYouBalanceAccounts);
            setCorpBalanceAccounts(filteredCorpBalanceAccounts);

            let assembledYouChartData = assembleChartData(
                filteredYouBalanceAccounts
            );
            let assembledCorpChartData = assembleChartData(
                filteredCorpBalanceAccounts
            );

            setYouChartData({
                ...youChartData,
                labels: assembledYouChartData.labels,
                datasets: assembledYouChartData.datasets
            });
            setCorpChartData({
                ...corpChartData,
                labels: assembledCorpChartData.labels,
                datasets: assembledCorpChartData.datasets
            });
        }
    }, [balanceAccountGroup?.userBalanceAccounts]);

    if (youBalanceAccounts.length === 0 && corpBalanceAccounts.length === 0){
        return (
            <TabPanel value={value} index={index}>
                <EmptyState message="Nenhum saldo disponível para esse colaborador."/>
            </TabPanel>
        );
    }

    return (
        <TabPanel value={value} index={index}>
            <Grid>
                {youBalanceAccounts.length > 0 && (
                    <BalanceTabCardContainer
                        title={'Pessoal'}
                        balanceAccounts={youBalanceAccounts}
                        chartData={youChartData}
                    />
                )}
                {corpBalanceAccounts.length > 0 && (
                    <BalanceTabCardContainer
                        title={'Corporativo'}
                        balanceAccounts={corpBalanceAccounts}
                        chartData={corpChartData}
                        balanceAccountGroup={balanceAccountGroup}
                        user={user}
                        userID={routeParams.userID}
                    />
                )}
            </Grid>
        </TabPanel>
    );
}

const BalanceTabCardContainer = ({title, chartData, balanceAccounts, balanceAccountGroup, user, userID}) => {
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [openSentModal, setOpenSentModal] = React.useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const modalStyle = {
        //   position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        //   width: 400,
        maxWidth: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const submitRequest = () => setOpenConfirmationModal(true);

    const handleClose = () => {
        setOpenConfirmationModal(false);
        setOpenSentModal(false);
      };

    const onClickConfirm = async () => {
        const companyID = user.data.companyID;
        const balanceAccountGroupID = balanceAccountGroup.userBalanceAccounts.find((ba) => ba.balanceAccountTypeID === 2).balanceAccountGroupID;
        const accountHolderID = balanceAccountGroup.userBalanceAccounts[0].accountHolderID;
    
        await dispatch(postReturnBalanceTransfer(companyID, userID, balanceAccountGroupID, accountHolderID)); 
        
        setOpenSentModal(true);
    }

    return (
        <Grid
            container
            spacing={10}
            sx={{
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                marginBottom: '40px'
            }}
        >
            <Grid item xs={12} md={6}>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <h2>{title}</h2>
                    {title === 'Corporativo' ? 
                        <Button
                            variant="outlined"
                            size="large"
                            type='button' 
                            onClick={submitRequest}
                            sx={{
                                borderWidth: '2px',
                                maxHeight: '60px',
                                alignSelf: 'center'
                            }}
                        >
                            Retornar Saldo
                        </Button> : ''}
                </Box>
                <RoundedCard>
                    <CardContent>
                        <Grid container>
                            <Grid item xs={6} style={{marginBottom: '1rem'}}>
                                <b>Benefício</b>
                            </Grid>
                            <Grid item xs={6}>
                                <b>Saldo</b>
                            </Grid>
                            {balanceAccounts.map((ba) => (
                                <>
                                    <Grid item xs={6}>
                                        <p style={{lineHeight: '0.25rem'}}>
                                            {ba.description}
                                        </p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p style={{lineHeight: '0.25rem'}}>
                                            {moneyFormat(ba.balance)}
                                        </p>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </CardContent>
                </RoundedCard>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px'
                }}
            >
                <div style={{width: '20rem'}}>
                    <Pie data={chartData}/>
                </div>
            </Grid>
            <Modal
                open={openConfirmationModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-login" sx={modalStyle}>
                <img className="cancel" src={cancel} alt="" onClick={handleClose} />
                <Typography id="modal-modal-title" variant="h6">
                    Deseja retornar o saldo?
                </Typography>
                <div style={{ display: "flex", gap: "1.5rem", marginTop: "2rem" }}>
                    <Button size="medium" variant="contained" onClick={onClickConfirm}>
                    Sim
                    </Button>
                    <Button
                    size="medium"
                    variant="outlined"
                    style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                    onClick={handleClose}
                    >
                    Não
                    </Button>
                </div>
                </Box>
            </Modal>
            <Modal
                open={openSentModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-login" sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6">
                    Requisição enviada!
                </Typography>
                <div style={{ display: "flex", gap: "1.5rem", marginTop: "2rem" }}>
                    <Button size="medium" variant="contained" onClick={handleClose}>
                    Fechar
                    </Button>
                </div>
                </Box>
            </Modal>
        </Grid>
    );
};
