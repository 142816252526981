import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import {FormControl, InputLabel, Select} from '@mui/material';

const BenefitsSelector = ({ setBenefitID, ...MUIProps}) => {
    const useStyles = makeStyles(theme => ({
        menuPaper: {
          maxHeight: 200
        }
      }));

    const classes = useStyles()

    const handleSelectChange = (event) => {
        setBenefitID(event.target.value)
    }

    const benefits = [
        { value: '0', label: 'Todos - Corp' },
        { value: '10', label: 'Mobilidade - Corp' },
        { value: '11', label: 'Combustível - Corp' },
        { value: '12', label: 'Livre - Corp' }
    ];

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="state-select-standard-label">Benefício</InputLabel>
            <Select
                {...MUIProps}
                labelId="state-select-standard-label"
                id="state-select-standard"
                label="Benefício"
                defaultValue="0"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
                onChange={(event) => handleSelectChange(event)}
            >
                {benefits.map((benefit) => {
                    return (
                        <MenuItem
                            value={benefit.value}>{benefit.label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
};

export default BenefitsSelector;