/*
 * Takes the incoming status from the api which is in english and converts to portuguese
 */
export const mapStatusToPortuguese = (status) => {
    const selectedStatus = statusStates[status];
    if (!selectedStatus) return status;

    return selectedStatus;
};

const statusStates = {
    pending: 'Aguardando ativação',
    active: 'Ativo',
    approved: 'Ativo',
    inactive: 'Inativo',
    automatically_approved: 'Ativo',
    blocked: 'Bloqueado'
};
