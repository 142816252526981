import React from "react";
import TextField from '@mui/material/TextField';

const InputMd = ({ id, label, className, color = 'primary', defaultValue, readOnly }) => {
  return (
    <TextField
      className={`${className} input-md`}
      id={id}
      label={label}
      color={color}
      variant="standard"
      defaultValue={defaultValue} 
      InputProps={{
        readOnly: readOnly,
      }}
    />
  )
}

export default InputMd;