import React, { useState } from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import AccountTab from '../../components/tabs/DetailCollaborator/AccountTab';
import CardsTab from '../../components/tabs/DetailCollaborator/CardsTab';
import BalanceTab from '../../components/tabs/DetailCollaborator/BalanceTab';
import SecurityTab from '../../components/tabs/DetailCollaborator/SecurityTab';

const DetailCollaborator = () => {
    const [tab, setTab] = useState(0);
    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title={'Detalhes Colaborador'} />
                <Grid container>
                    <Grid xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tab}
                                onChange={(_, v) => {
                                    setTab(v);
                                }}
                                aria-label="basic tabs example"
                            >
                                <Tab label="Conta" id={0} />
                                <Tab label="Saldo" id={1} />
                                <Tab label="Cartões" id={2} />
                                {/* <Tab label="Segurança" id={3} /> */}
                            </Tabs>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <AccountTab value={tab} index={0} />
                        <BalanceTab value={tab} index={1} />
                        <CardsTab value={tab} index={2} />
                        <SecurityTab value={tab} index={3} />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default DetailCollaborator;
