import React from 'react';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import Footer from '../../components/Footer';

// Wexp Icons
import WexpIconOrangeMoneyExchange from '../../assets/images/wmoney-exchange.png';
import WexpIconOrangeBarCode from '../../assets/images/barcode.png';
import WexpIconOrangeQrCode from '../../assets/images/qr-code.png';

// MUI
import { Button, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Header from '../../components/Header';

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '10px'
}));

const AddBalance = () => {
    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Adicionar Saldo" />

                <Grid
                    container
                    columnGap={5}
                    rowGap={6}
                    justifyContent="center"
                >
                    {/* <Grid item xs={12} md={5}>
                        <Item sx={{ border: '1px solid #C0C0C0FF' }}>
                            <Grid
                                display="flex"
                                flexDirection="column"
                                rowGap="15px"
                            >
                                <Grid
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <img
                                        style={{
                                            width: '35px',
                                            height: '35px',
                                            marginRight: '8px'
                                        }}
                                        src={WexpIconOrangeMoneyExchange}
                                        alt=""
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        component="div"
                                        sx={{ fontSize: '18px' }}
                                    >
                                        Transferência Bancária - TED
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontSize: '15px',
                                            color: '#686868'
                                        }}
                                    >
                                        Os créditos por TED podem ser
                                        compensados no mesmo dia caso sejam
                                        efetuados dentro da janela bancária.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ marginTop: '43px' }}
                                        href="/balance/add/ted"
                                    >
                                        ADICIONAR POR TRANSFÊRENCIA
                                    </Button>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid> */}

                    <Grid item xs={12} md={5}>
                        <Item sx={{ border: '1px solid #C0C0C0FF' }}>
                            <Grid
                                display="flex"
                                flexDirection="column"
                                rowGap="15px"
                            >
                                <Grid
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <img
                                        style={{
                                            width: '35px',
                                            height: '35px',
                                            marginRight: '8px'
                                        }}
                                        src={WexpIconOrangeQrCode}
                                        alt=""
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        component="div"
                                        sx={{ fontSize: '18px' }}
                                    >
                                        PIX
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontSize: '15px',
                                            color: '#686868'
                                        }}
                                    >
                                        Os créditos por PIX serão compensados
                                        após indentificação do pagamento
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ marginTop: '43px' }}
                                        href="/balance/add/pix"
                                    >
                                        ADICIONAR POR PIX
                                    </Button>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Item sx={{ border: '1px solid #C0C0C0FF' }}>
                            <Grid
                                display="flex"
                                flexDirection="column"
                                rowGap="15px"
                            >
                                <Grid
                                    display="flex"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                >
                                    <img
                                        style={{
                                            width: '35px',
                                            height: '35px',
                                            marginRight: '8px'
                                        }}
                                        src={WexpIconOrangeBarCode}
                                        alt=""
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        component="div"
                                        sx={{ fontSize: '18px' }}
                                    >
                                        Boleto
                                    </Typography>
                                </Grid>
                                <Grid>
                                    <Typography
                                        gutterBottom
                                        variant="body2"
                                        component="div"
                                        sx={{
                                            fontSize: '15px',
                                            color: '#686868'
                                        }}
                                    >
                                        Os créditos por boleto dependem da
                                        compensação do mesmo e pode acontecer em
                                        até 3 dias úteis.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        sx={{ marginTop: '43px' }}
                                        href="/balance/add/boleto"
                                    >
                                        ADICIONAR POR BOLETO
                                    </Button>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>

                <Footer />
            </Container>
        </>
    );
};

export default AddBalance;
