// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { IState } from './interfaces/Statement';
import { Endpoints } from '../../constants/endpoints';
import { IApi } from '../middleware/interfaces/api';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    statements: []
};

export const slice = createSlice({
    name: 'statement',
    initialState,
    reducers: {
        STATEMENTS_REQUESTED: (
            statement: IState,
            action: PayloadAction<IState>
        ) => {
            statement.loading = true;
            statement.error = false;
            statement.success = false;
            statement.statements = [];
        },

        STATEMENTS_FAILED: (
            statement: IState,
            action: PayloadAction<IState>
        ) => {
            statement.loading = false;
            statement.error = true;
            statement.success = false;
            statement.statements = [];
        },

        STATEMENTS_RETRIEVED: (
            statement: IState,
            action: PayloadAction<any>
        ) => {
            statement.loading = false;
            statement.error = false;
            statement.success = true;
            const { result } = action.payload;
            statement.statements = result;
        }
    }
});

export const { STATEMENTS_REQUESTED, STATEMENTS_FAILED, STATEMENTS_RETRIEVED } =
    slice.actions;

export default slice.reducer;

export const getCompanyTransferStatements = (companyID: string) => {
    const urlParams = new URLSearchParams();
    const request: IApi = {
        axiosConfig: {
            url: Endpoints.Statement.GetCompanyTransfer(companyID),
            method: 'get',
            params: urlParams
        },
        onAction: {
            onStart: STATEMENTS_REQUESTED.type,
            onSuccess: STATEMENTS_RETRIEVED.type,
            onError: STATEMENTS_FAILED.type
        }
    };
    return apiCallBegan(request);
};

export const getCompanyPMATransferStatements = (companyID: string) => {
    // const urlParams = new URLSearchParams();
    const request: IApi = {
        axiosConfig: {
            url: Endpoints.Statement.GetCompanyPMATransfer(companyID),
            method: 'get'
            // params: urlParams
        },
        onAction: {
            onStart: STATEMENTS_REQUESTED.type,
            onSuccess: STATEMENTS_RETRIEVED.type,
            onError: STATEMENTS_FAILED.type
        }
    };
    return apiCallBegan(request);
};

export const getTreasuryAccountStatementV2 = (companyID:string, startDate:string, endDate:string)=>{
    const request: IApi = {
        axiosConfig:{
            url:Endpoints.Statement.GetTreasuryAccountStatementV2(companyID,startDate,endDate),
            method:'get'
        },
        onAction:{
            onStart: STATEMENTS_REQUESTED.type,
            onSuccess: STATEMENTS_RETRIEVED.type,
            onError: STATEMENTS_FAILED.type
        }
    };
    return apiCallBegan(request)

}
