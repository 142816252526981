// Redux
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarProps } from "@mui/material/Snackbar";


interface extraTypes  {
    severity: "success" | "info" | "warning" | "error";
}

export type IState = SnackbarProps &  extraTypes;

const initialState: IState   = {
  open: false,
  message: "",
  severity: "info",
};

export const slice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    SHOW_SNACK: (state, action: PayloadAction<IState>) => {
        state.open = true;
        state.message = action.payload.message;
        state.severity = action.payload.severity;
    },
    CLEAR_SNACK: (state) => {
        state.open = false;
    },
  },
});

export const { SHOW_SNACK, CLEAR_SNACK } = slice.actions;

export default slice.reducer;
