import React from 'react';

// Wexp Components
import ResponsiveAppBar from '../../../components/NavBar';
import BackButton from '../../../components/BackButton';
import Footer from '../../../components/Footer';

// Utils
import { MaskCpf } from '../../../utils/mask';
import { filterByValue } from '../../../utils/filter';

// MUI
import Container from '@mui/material/Container';
import { Card, CardActions, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
import Button from '@mui/material/Button';

// React-Router
import { useNavigate } from 'react-router-dom';
import { setBenefits, setTotal } from '../../../store/_Forms/NewOrder';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { GetAllAccountHoldersByCompanyId } from '../../../store/_Entities/AccountHolder';
import { GetTreasuryAccountHolder } from '../../../store/_Entities/TreasuryAccountHolder';

const SelectUser = () => {
    const [selectedUser, setSelectedUser] = React.useState({});
    const [filteredValues, setFilteredValues] = React.useState([]);
    const [searchQuery, setSearchQuery] = React.useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { companyAccountHolders } = useSelector(
        (state) => state.entities.accountHolder
    );
    const { user } = useSelector((state) => state.entities);

    const selectUser = (user) => {
        setSelectedUser(user);
        navigate(`/orders/add/individual/${user.userID}`);
    };

    React.useEffect(() => {
        setFilteredValues(filterByValue(companyAccountHolders, searchQuery));
    }, [companyAccountHolders, searchQuery]);

    React.useEffect(() => {
        dispatch(GetAllAccountHoldersByCompanyId(user.data.companyID));
        dispatch(GetTreasuryAccountHolder(user.data?.companyID));
        dispatch(setBenefits({}));
        dispatch(setTotal(0));
    }, []);

    return (
        <>
            <ResponsiveAppBar />

            <Container spacing={11}>
                <BackButton />
                <Grid container display="flex" justifyContent="center">
                    <Grid
                        xs={12}
                        md={7}
                        display="flex"
                        justifyContent="center"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Typography
                            alignSelf="flex-start"
                            fontWeight={400}
                            variant="h4"
                        >
                            Busque pelo colaborador para adicionar
                        </Typography>

                        <Grid sx={{ width: '100%', margin: '3rem' }}>
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                                value={searchQuery}
                                onChange={(s) => setSearchQuery(s.target.value)}
                                label="Pesquisar"
                                placeholder='ABC'
                                variant="standard"
                                fullWidth
                            />
                        </Grid>

                        <Grid sx={{ width: '100%' }}>
                            {filteredValues.map((user) => (
                                <Card
                                    sx={{
                                        minWidth: 275,
                                        boxShadow:
                                            '0px 0px 11px 0px rgba(0,0,0,0.25)',
                                        borderRadius: '10px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexDirection: {
                                            xs: 'column',
                                            md: 'row'
                                        },
                                        marginBottom: '20px'
                                    }}
                                    key={user.userID}
                                >
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="body1"
                                            component="div"
                                        >
                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                Nome:
                                            </span>{' '}
                                            {user.firstName} {user.lastName}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="body1"
                                            component="div"
                                        >
                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                E-mail:
                                            </span>{' '}
                                            {user.email}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="div"
                                        >
                                            <span
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                CPF:
                                            </span>{' '}
                                            {MaskCpf(user.document)}
                                        </Typography>
                                    </CardContent>

                                    <CardActions>
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            onClick={() => selectUser(user)}
                                        >
                                            Adicionar
                                        </Button>
                                    </CardActions>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Footer />
            </Container>
        </>
    );
};

export default SelectUser;
