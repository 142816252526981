import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Unauthorized from '../../containers/Unauthorized';

// const unauthorizedPage = () => (

//     <div>You must be logged in to view this page</div>
// );

export const defaultRole = 'bankadministrator';

export const PageContainer = (props) => {
    const { user } = useSelector((state) => state.entities);
    const allowedRole = props?.role ? props.role : defaultRole;
    if (props?.protected) {
        if (!user || !user.isLoggedIn) {
            return Unauthorized();
        }

        if(!props.allRoles && !user.roles.includes(allowedRole)){ 
            return <Navigate to="/user/register" />
        }
    }
    return props.children;
};
