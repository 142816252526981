import React, { useState } from 'react';

// MUI
import { DataGrid, ptBR } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';

// Utils
import { formatDateToPtLocale } from '../../utils/format';
import { filterByValue } from '../../utils/filter';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export default function CollaboratorGrid({ data }) {
    const navigate = useNavigate();
    const [ search, setSearch ] = useState('');
    const sortModel = [{ field: "created", sort: "desc" }];

    const batchLogs = (params) => {
        console.log(params.row.batchingID)
        navigate(`/orders/batching-log/${params.row.batchingID}`)
    }
    
    const columns = [
        {
            field: 'created',
            headerName: 'Data de Criação',
            valueFormatter: ({ value }) => {
                return formatDateToPtLocale(value);
            },
            type: 'date',
            headerAlign: 'center',
            align: 'center',
            flex: 1
        },
        {
            field: 'user',
            headerName: 'Usuário responsável',
            headerAlign: 'center',
            align: 'center',
            flex: 1
        },
        {
            field: 'log',
            flex: 1,
            headerName: 'Log',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Button onClick={() => {batchLogs(params)}}  >Ver Log</Button>
            )
        }
    ];
    return (
        <div style={{ height: 400, width: '100%' }}>
            <div style={{ width: '25%', margin: '1rem' }}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        )
                    }}
                    value={search}
                    onChange={(s) => setSearch(s.target.value)}
                    label="Pesquisar"
                    placeholder='ABC'
                    variant="standard"
                    fullWidth
                />
            </div>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={filterByValue(data, search)}
                getRowId={(row) => row.batchingID}
                disableSelectionOnClick
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                sortModel={sortModel}
            />
        </div>
    );
}
