// Wexp Icons
import WexpIconRedFork from "../assets/images/rfork.png";
import WexpIconPinkShoppingCart from "../assets/images/Rshopping-cart.png";
import WexpIconGreenHeart from "../assets/images/Vheartbeat.png";
import WexpIconBlueGasStationPump from "../assets/images/Vgas-station.png";
import WexpIconOrangeMoneyHand from "../assets/images/ohand.png";
import WexpIconPinkCar from "../assets/images/Rcar.png";
import WexpIconCulture from "../assets/images/culture-icon.png";
import WexpIconHomeOffice from "../assets/images/home-office-icon.png";
import WexpEducation from "../assets/images/education-icon.png";
import WexpTransporte from "../assets/images/Bus.png";


export const benefitsIcons = {
    Refeição: WexpIconRedFork,
    Alimentação: WexpIconPinkShoppingCart,
    Saúde: WexpIconGreenHeart,
    Combustível: WexpIconBlueGasStationPump,
    Salário: WexpIconOrangeMoneyHand,
    Mobilidade: WexpIconPinkCar,
    Livre: WexpIconOrangeMoneyHand,
    Cultura: WexpIconCulture,
    "Home Office": WexpIconHomeOffice,
    Educação: WexpEducation,
    Transporte: WexpTransporte,
};