import React from 'react';

// Wexp Components
import ResponsiveAppBar from "../../components/NavBar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// MUI
import Grid from "@mui/material/Grid";
import {Container} from "@material-ui/core";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const Item = styled(Paper)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "flex-start",
    alignItems: "center",
    padding: theme.spacing(2),
    borderRadius: "10px",
}));

const AddBalanceByTed = () => {
    return (
        <>
            <ResponsiveAppBar/>
            <Container spacing={11}>
                <Header backButton title="Adicionar Crédito por Ted"
                        subtitle="Os créditos por TED podem ser compensados no mesmo dia caso sejam efetuados dentro da janela bancária."/>

                <Grid item xs={12}>
                    <Item sx={{border: "1px solid #C0C0C0FF"}}>
                        <Grid xs={12} sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: "space-evenly",
                            flexDirection: {xs: 'column', md: 'row'}
                        }}>
                            <Grid minWidth={250}>
                                <div style={{marginBottom: "40px"}}>
                                    <h3>Banco</h3>
                                    <p>341- Banco Itaú</p>
                                </div>
                                <Grid>
                                    <h3>Razão Social</h3>
                                    <p>WEXP Soluções Digitais Ltda</p>
                                </Grid>
                            </Grid>
                            <Grid minWidth={250}>
                                <div style={{marginBottom: "40px"}}>
                                    <h3>Agência</h3>
                                    <p>4005</p>
                                </div>
                                <div>
                                    <h3>CNPJ</h3>
                                    <p>13.113.314/0001-42</p>
                                </div>

                            </Grid>
                            <Grid minWidth={250}>
                                <div>
                                    <h3>Conta</h3>
                                    <p>01284-6</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Footer/>
            </Container>

        </>
    );
};

export default AddBalanceByTed;