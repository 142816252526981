import React from 'react';
import ResponsiveAppBar from '../components/NavBar';

import Footer from '../components/Footer';
import {useSelector} from "react-redux";
import {Grid} from "@mui/material";
import WexpIconUnauthorizedOrange from "../assets/images/unathorized.png";
import Button from "@mui/material/Button";

const Unauthorized = () => {
    const { user } = useSelector((state) => state.entities);

    return (
        <>
            {user.isLoggedIn && <ResponsiveAppBar />}

            <Grid container alignItems="center" justifyContent="center" flexDirection="column">
                <Grid item xs={6} md={8} sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: {xs: "column", md: "row"}, width: "100%"}}>
                    <img  src={WexpIconUnauthorizedOrange} style={{width: 500, height: 500}} alt="empty state image"/>
                    {user.isLoggedIn ? <p>Você não tem permissão para visualizar essa página. Caso
                        isso seja um erro, contate o suporte.</p> : <p>Você precisa estar autenticado para acessar essa página.</p>}

                </Grid>
                <Button href={user.isLoggedIn ? "/home" : "/login"} variant="contained" sx={{width:"200px", marginBottom: "40px"}}>{user.isLoggedIn ? "Voltar" : "Fazer Login"}</Button>
            </Grid>

            <Footer />
        </>
    );
};

export default Unauthorized;
