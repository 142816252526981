import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import { Endpoints, GatewayApiUrl } from '../../constants/endpoints';
import { apiCallBegan } from '../api';
import { IAdminState } from './interfaces/Admin';

const initialState = {
    error: false,
    loading: false,
    success: false,
    admins: []
};

const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        ADMIN_REQUESTED: (
            admin: IAdminState,
            action: PayloadAction<IRequestResponse<IAdminState>>
        ) => {
            admin.loading = true;
            admin.error = false;
            admin.success = false;
        },
        ADMIN_FAILED: (
            admin: IAdminState,
            action: PayloadAction<IRequestResponse<IAdminState>>
        ) => {
            admin.loading = false;
            admin.error = true;
            admin.success = false;
        },
        ADMIN_SUCCESS: (admin: IAdminState, action: PayloadAction<any>) => {
            admin.loading = false;
            admin.error = false;
            admin.success = true;
            admin.admins = action.payload.result;
        }
    }
});

export const { ADMIN_REQUESTED, ADMIN_FAILED, ADMIN_SUCCESS } = slice.actions;

export default slice.reducer;

export const fetchAdmins = (compannyId: string): any =>
    apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.AccountHolder.GetAdministrators(compannyId)
        },
        onAction: {
            onSuccess: ADMIN_SUCCESS.type,
            onError: ADMIN_FAILED.type
        }
    });
