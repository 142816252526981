// Redux
import { combineReducers } from 'redux';

// Slices
import snackbarReducer from './Snackbar';
import loadingReducer from './Loading';

export default combineReducers({
    snackbar: snackbarReducer,
    loading: loadingReducer
});
