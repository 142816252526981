import React, {useEffect, useState} from "react";
import axios from "axios";

// MUI
import Container from "@mui/material/Container";
import {Grid, Modal, Box, Typography, Button} from "@mui/material";

// Wexp Components
import ResponsiveAppBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Step1 from "../../components/forms/AddCollaborator/AddCollaboratorStep1";
import Step2 from "../../components/forms/AddCollaborator/AddCollaboratorStep2";
import Step3 from "../../components/forms/AddCollaborator/AddCollaboratorStep3";

// Store
import {useDispatch, useSelector} from "react-redux";
import {CreateNewCollaborator, GetAccountHoldersByUserId, UpdateCollaborator} from "../../store/_Entities/AccountHolder";
import { getUserData } from '../../store/_Entities/User';

// Utils
import {formatDateStringToSwapFormat, formatDateToPtLocale} from "../../utils/format";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingAction from "../../components/LoadingAction";

const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    outline: 0,
    p: 4,
};

const UserAutoRegistration = () => {    
    const location = useLocation();
    const navigate = useNavigate()

    const editPage = location.pathname === '/user/edit' 

    const {user, accountHolder} = useSelector(state => state.entities);
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingUserData, setLoadingUserData] = useState(editPage)
    
    const [errorModalShow, setErrorModalShow] = useState(false)

    const dispatch = useDispatch();

    const formFlowHandler = (data, nextStep, isSubmit) => {
        let userData = {...formData}

        if (step === 1 || step === 2){
            userData = {
                ...userData,
                data: {
                    ...userData.data,
                    ...data
                }
            }
        }
            
        if (step === 3){
            data.complement = data.complement || 'S/C'
            data.neighborhood = data.neighborhood.replace(/[^a-zA-Z0-9\s]/g, "");
            data.street = data.street.replace(/[^a-zA-Z0-9\s]/g, "");

            userData = {
                ...userData,
                address: {
                    ...userData.address,
                    ...data
                }
            }
        }

        if(isSubmit){
            submitForm(userData)
        }

        setFormData(userData)
        setStep(nextStep)
    }

    const makeSingleName = (firstName, lastName) => {
        firstName = firstName.toLowerCase()
        firstName = firstName[0].toUpperCase() + firstName.substring(1)

        lastName = lastName.toLowerCase()
        lastName = lastName[0].toUpperCase() + lastName.substring(1)

        return firstName + ' ' + lastName
    }

    const getInfoPhone = (number) => {
        const code = number.substr(0, 2)
        
        if(code === '55'){
            return {
                countryCode: `+${code}`,
                phone: number.substring(2)
            }
        }

        return {
            countryCode: `+1`,
            phone: number.substring(1)
        }
    }

    const loadDataUser = async () => {        
        setLoadingUserData(true)
        let userInformation = accountHolder?.fetchedSingleAccountHolder;

        setFormData({
            data: {
                cpf: userInformation.document,
                name: makeSingleName(userInformation.firstName, userInformation.lastName),
                email: userInformation.email,
                motherName: userInformation.motherName,
                birthDate: formatDateToPtLocale(userInformation.birthDate),
                countryCode: getInfoPhone(userInformation.phone).countryCode,
                phone: getInfoPhone(userInformation.phone).phone,      
            },
            address: {
                street: userInformation.address[0].street,
                neighborhood: userInformation.address[0].neighborhood,
                city: userInformation.address[0].city,
                state: userInformation.address[0].state,
                complement: userInformation.address[0].complement === 'S/C' ? '' : userInformation.address[0].complement,
                number: userInformation.address[0].number,
                postalCode: userInformation.address[0].postalCode 
            }
        })

        setLoadingUserData(false)
    }

    const handleCloseModal = async () => {
        setErrorModalShow(false)
        navigate('/user/register')
    }

    useEffect(() => {
        if(!user.data){
            dispatch(getUserData(user.authData.sub));
        }
        setFormData({
            data: {
                email: user.authData.given_name
            }
        })
    }, [])

    useEffect(() => {
        if(user.data && !editPage){
            navigate('/user/edit')
        }
        if(user.error && editPage){
            setErrorModalShow(true)
            setLoadingUserData(false)
        }
        if(user.data && editPage){
            dispatch(GetAccountHoldersByUserId(user.data?.userID)); 
        }
    }, [user])

    useEffect(() => {
        
        if(editPage && accountHolder.fetchedSingleAccountHolder.userID === user.data?.userID && !accountHolder.loading){
            loadDataUser()
        }
    }, [accountHolder])

    const prepareDataForSubmit = (data) => {
        let newFormData = {...data};
        
        let concatenateCountryCodeAndPhone = newFormData.data.countryCode + newFormData.data.phone;
        let splitFullName = newFormData.data.name.toString().split(' ');
        let removeFullPhoneSpecialChars = concatenateCountryCodeAndPhone.toString().replace(/\D/g,'');
        let removeDocumentSpecialChars = newFormData.data.cpf.toString().replace(/\D/g,'');
        let firstName = splitFullName[0];
        splitFullName.shift();
        let lastName = splitFullName.join(" ");
        
        return {
            firstName: firstName,
            lastName: lastName,
            document: removeDocumentSpecialChars,
            email: newFormData.data.email,
            phoneNumber: removeFullPhoneSpecialChars,
            motherName: newFormData.data.motherName,
            birthDate: formatDateStringToSwapFormat(newFormData.data.birthDate),
            companyID: parseInt(user.data?.companyID) || '0',
            addresses: [newFormData.address]
        };
    }

    const submitForm = async (userData) => {
        const preparedData = prepareDataForSubmit(userData);

        if(editPage){
            dispatch(UpdateCollaborator(user.data.userID, preparedData));
            dispatch(GetAccountHoldersByUserId(user.data.userID));
        }else{
            dispatch(CreateNewCollaborator(preparedData));
        }
        
        setLoading(true);
    }

    return (
        <>
            <ResponsiveAppBar onlyExit />
            <Container spacing={11}>
                <Header title="Cadastrar"/>
                <Grid container rowGap={8}>
                    <Grid item xs={12}>
                        {
                            editPage &&
                            <Grid>
                                <LoadingAction loading={loadingUserData} />
                            </Grid>
                        }
                        {step === 1 && <Step1 prevData={formData} onSubmit={(data) => formFlowHandler(data, 2)} edit={editPage} />}
                        {step === 2 && <Step2 prevData={formData} onSubmit={(data) => formFlowHandler(data, 3)}
                                                onBack={() => setStep(1)} userRegisterPage />}
                        {
                            step === 3 && 
                            <Step3
                                prevData={formData}
                                onSubmit={(data) => formFlowHandler(data, 3, true)}
                                onBack={() => setStep(2)}
                                loading={loading}
                                setLoading={setLoading}
                                edit={editPage}
                                userRegisterPage
                            />
                        }
                    </Grid>
                </Grid>
                <Footer/>
            </Container>
            <Modal
                open={errorModalShow}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-login" sx={modalContentStyle}>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "35px",
                        padding: "20px"
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Você ainda não possui um cadastro
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={handleCloseModal}
                        >
                            Fechar
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};

export default UserAutoRegistration;
