import TabPanel from "../../TabPanel";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import RoundedCard from "../../RoundedCard.tsx";
import { Button, CardContent, CardHeader } from "@mui/material";



export default function SecurityTab({ value, index }) {
  return (
    <TabPanel value={value} index={index}>
      <Grid container spacing={2}>

        <Grid item xs={6}>
          <RoundedCard>
            <CardHeader title="Ações de Segurança" />
            <CardContent>
              <Button
              variant="contained"
              color="primary"
              size="large"
              >
                Deslogar de todos os dispositivos
              </Button>
            </CardContent>
          </RoundedCard>
        </Grid>
      </Grid>
    </TabPanel>
  );
}
