import React, {useEffect, useState} from "react";

// MUI
import Grid from "@mui/material/Grid";
import {CardContent, CardHeader, TextField} from "@mui/material";
import Button from "@mui/material/Button";

// Wexp components
import RoundedCard from "../../RoundedCard.tsx";
import TabPanel from "../../TabPanel";
import {ModalWrapper} from "../../Modal";
import CountryCodeSelector from "../../CountryCodeSelector";
import InputWithMask from "../../InputWithMask";

// Store
import {useDispatch, useSelector} from "react-redux";

// React-router
import {useParams} from "react-router-dom";

// Utils
import {MaskCpf, MaskPhone} from "../../../utils/mask";
import {formatDateStringToSwapFormat, formatDateToPtLocale} from "../../../utils/format";
import {brPhoneMask, cpfMask, dateMask} from "../../../utils/InputMasks";

// Assets
import WexpIconEditOrange from "../../../assets/images/edit.png";

// Form
import {Controller, useForm} from "react-hook-form";
import {
    GetAccountHoldersByUserId,
    GetAllAccountHoldersByCompanyId,
    UpdateCollaborator
} from "../../../store/_Entities/AccountHolder";

const mockData = {
    name: "João da Silva",
    CPF: "123.456.789-10",
    email: "andre.insardi@khipo.com.br",
    phone: "(11) 99999-9999",
    birthDate: "05/11/2021",
};

const mockDataAddress = {
    street: "Rua da Paz",
    number: "123",
    complement: "Apto. 123",
    postalCode: "12345-678",
    city: "São Paulo",
    state: "SP",
    neighborhood: "Sto Antonio",
};

const labels = {
    name: "Nome",
    CPF: "CPF",
    email: "Email",
    phone: "Telefone",
    birthDate: "Data de Nascimento",
    street: "Endereço",
    number: "Número",
    complement: "Complemento",
    postalCode: "CEP",
    city: "Cidade",
    state: "Estado",
    neighborhood: "Bairro"
};

export default function AccountTab({value, index}) {
    const {accountHolder} = useSelector((state) => state.entities);

    const [selectedUserInfo, setSelectedUserInfo] = useState({});
    const [selectedUserAddress, setSelectedUserAddress] = useState({});
    const [editModal, setEditModal] = useState({show: false, AddressOrInfo: "info"});
    const [didUserUpdated, setDidUserUpdated ] = useState(false);

    const routeParams = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        let userID = routeParams.userID;

        dispatch(GetAccountHoldersByUserId(userID));
    }, [])

    useEffect(() => {
        let userInformation = accountHolder?.fetchedSingleAccountHolder;

        let userAddress = userInformation?.address[0];
        const {addressType, created, updated, type, ...filteredUserAddress} = userAddress;

        setSelectedUserAddress(filteredUserAddress)
        setSelectedUserInfo(userInformation);

    }, [accountHolder?.fetchedSingleAccountHolder])

    return (
        <>
            <TabPanel value={value} index={index}>
                <Grid container spacing={5} sx={{
                    display: "flex",
                    flexDirection: {xs: "column", md: "row"}
                }}>
                    <Grid item xs={12} md={6}>
                        <RoundedCard>
                            <Grid display="flex" justifyContent="space-between" alignItems="baseline">
                                <CardHeader title="Dados Pessoais"/>
                                <img src={WexpIconEditOrange} alt="Wexp Icon orange edit"
                                     style={{width: "26px", height: "26px", cursor: "pointer"}}
                                     onClick={() => setEditModal({show: true, AddressOrInfo: "info"})}/>
                            </Grid>
                            <CardContent>
                                <div style={{minHeight: "245px"}}>
                                    <p>
                                        <b>Nome: </b>
                                        {selectedUserInfo ? `${selectedUserInfo?.firstName} ${selectedUserInfo?.lastName}` : ""}
                                    </p>
                                    <p>
                                        <b>CPF: </b>
                                        {selectedUserInfo ? MaskCpf(selectedUserInfo?.document) : ""}
                                    </p>
                                    <p>
                                        <b>Email: </b>
                                        {selectedUserInfo ? selectedUserInfo?.email : ""}
                                    </p>
                                    <p>
                                        <b>Telefone: </b>
                                        {selectedUserInfo ? MaskPhone(selectedUserInfo?.phone) : ""}
                                    </p>
                                    <p>
                                        <b>Data de nascimento: </b>
                                        {selectedUserInfo ? formatDateToPtLocale(selectedUserInfo?.birthDate) : ""}
                                    </p>
                                </div>
                            </CardContent>
                        </RoundedCard>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <RoundedCard>
                            <Grid display="flex" justifyContent="space-between" alignItems="baseline">
                                <CardHeader title="Endereço"/>
                                <img src={WexpIconEditOrange} alt="Wexp Icon orange edit"
                                     style={{width: "26px", height: "26px", cursor: "pointer"}}
                                     onClick={() => setEditModal({show: true, AddressOrInfo: "address"})}/>
                            </Grid>
                            <CardContent>
                                <div style={{minHeight: "13rem"}}>
                                    {Object.keys(selectedUserAddress).map((item, value) => (
                                        <p>
                                            <b>{labels[item]}: </b>
                                            {selectedUserAddress[item]}
                                        </p>
                                    ))}
                                </div>
                            </CardContent>
                        </RoundedCard>
                    </Grid>
                </Grid>
            </TabPanel>
            <ModalWrapper showModal={editModal.show} closeModalAction={() => setEditModal({...editModal, show: false})}
                          title={editModal.AddressOrInfo === "info" ? "EDITANDO INFORMAÇÕES PESSOAIS" : "EDITANDO ENDEREÇO"}>
                {editModal.AddressOrInfo === "info" ?
                    <EditPersonalInformationForm userInformation={selectedUserInfo} currentAddress={selectedUserAddress}/>
                    :
                    <EditAddressForm userInformation={selectedUserInfo} currentAddress={selectedUserAddress} />
                }
            </ModalWrapper>
        </>

    );
}

const EditAddressForm = ({userInformation, currentAddress}) => {
    const {
        control,
        handleSubmit,
        getValues,
    } = useForm({
        defaultValues: {
            street: currentAddress.street ||  "",
            number: currentAddress.number ||  "",
            complement: currentAddress.complement ||  "",
            neighborhood: currentAddress.neighborhood ||  "",
            postalCode: currentAddress.postalCode ||  "",
            city: currentAddress.city ||  "",
            state: currentAddress.state ||  "",
        }
    });

    const dispatch = useDispatch();

    const prepareDataForSubmit = () => {
        let currentFormData = getValues();

        const { firstName, lastName, document, email, phone, motherName, birthDate, companyID} = userInformation;
        return {
            firstName,
            lastName,
            document,
            email,
            phoneNumber: phone,
            motherName,
            birthDate,
            companyID: parseInt(companyID),
            addresses: [currentFormData]
        }
    }

    const submitAction = () => {
        let preparedData = prepareDataForSubmit();
        let {userID} = userInformation;

        dispatch(UpdateCollaborator(userID, preparedData));
        dispatch(GetAccountHoldersByUserId(userID));
    }

    return (
        <form onSubmit={handleSubmit(submitAction)}>
            <Grid container rowGap={2}>
                <Grid item xs={12}>
                    <Controller
                        name="street"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="street"
                                label="Endereço"
                                variant="standard"
                                fullWidth
                                placeholder="Rua, Avenida, Travessa, etc."
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controller
                        name="number"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="number"
                                label="Número"
                                variant="standard"
                                fullWidth
                                placeholder="100"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controller
                        name="complement"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="complement"
                                label="Complemento"
                                variant="standard"
                                fullWidth
                                placeholder="Apto 101"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controller
                        name="neighborhood"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="neighborhood"
                                label="Bairro"
                                variant="standard"
                                fullWidth
                                placeholder="Centro"
                                {...field}
                            />}
                    />
                </Grid>

                <Grid item xs={12} >
                    <Controller
                        name="postalCode"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="postalCode"
                                label="CEP"
                                variant="standard"
                                fullWidth
                                placeholder="17059157"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controller
                        name="city"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="city"
                                label="Cidade"
                                variant="standard"
                                fullWidth
                                placeholder="São Paulo"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controller
                        name="state"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="state"
                                label="Estado"
                                variant="standard"
                                fullWidth
                                placeholder="São Paulo"
                                {...field}
                            />}
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="space-between" sx={{marginTop: "40px"}}>
                <Button type="submit" variant="contained">Salvar</Button>
            </Grid>
        </form>
    )
}

const EditPersonalInformationForm = ({userInformation, currentAddress}) => {
    const separatedCountryCodeFromPhone = userInformation?.phone.toString().slice(0, 2);
    const phoneWithoutCountryCode = userInformation?.phone.toString().slice(2);

    const {
        control,
        handleSubmit,
        getValues,
    } = useForm({
        defaultValues: {
            name: `${userInformation?.firstName} ${userInformation?.lastName}` || '',
            cpf: userInformation?.document,
            email: userInformation?.email || '',
            countryCode: `+${separatedCountryCodeFromPhone}` || '+55',
            phone: phoneWithoutCountryCode || '',
            motherName: userInformation?.motherName || '',
            birthDate: formatDateToPtLocale(userInformation?.birthDate) || '',
        }
    });

    const dispatch = useDispatch();

    const prepareDataForSubmit = () => {
        let currentFormData = getValues();

        let concatenateCountryCodeAndPhone = currentFormData?.countryCode + currentFormData?.phone;
        let removeFullPhoneSpecialChars = concatenateCountryCodeAndPhone.toString().replace(/\D/g, '');

        let removeDocumentSpecialChars = currentFormData?.cpf.toString().replace(/\D/g, '');
        let splitFullName = currentFormData?.name.toString().split(' ');
        let firstName = splitFullName[0];
        splitFullName.shift();
        let lastName = splitFullName.join(" ");

        return {
            firstName: firstName,
            lastName: lastName,
            document: removeDocumentSpecialChars,
            email: currentFormData?.email,
            phoneNumber: removeFullPhoneSpecialChars,
            motherName: currentFormData?.motherName,
            birthDate: formatDateStringToSwapFormat(currentFormData?.birthDate),
            companyID: parseInt(userInformation?.companyID),
            addresses: [currentAddress]
        };
    }

    const submitAction = () => {
        let preparedData = prepareDataForSubmit();
        let {userID} = userInformation;

        dispatch(UpdateCollaborator(userID, preparedData));
        dispatch(GetAccountHoldersByUserId(userID));
    }

    return (
        <form onSubmit={handleSubmit(submitAction)}>
            <Grid container rowGap={2}>
                <Grid item xs={12}>
                    <Controller
                        name="name"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="name"
                                label="Nome Completo"
                                variant="standard"
                                fullWidth
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="cpf"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="CPF"
                                label="CPF"
                                {...field}
                                variant="standard"
                                fullWidth
                                mask={cpfMask}
                                maskChar="_"
                            />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="email"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="email"
                                label="Email"
                                {...field}
                                variant="standard"
                                fullWidth
                                placeholder="email@example.com"
                            />}
                    />

                </Grid>
                <Grid item xs={12} display="flex">
                    <Grid width="80px">
                        <Controller
                            name="countryCode"
                            control={control}
                            render={({field}) =>
                                <CountryCodeSelector
                                    {...field}
                                    required
                                    id="countryCode"
                                />}
                        />

                    </Grid>

                    <Controller
                        name="phone"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="phone"
                                label="Telefone"
                                {...field}
                                variant="standard"
                                fullWidth
                                mask={brPhoneMask}
                                maskChar="_"
                            />}
                    />

                </Grid>

                <Grid item xs={12}>
                    <Controller
                        name="motherName"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="motherName"
                                label="Nome da Mãe"
                                {...field}
                                variant="standard"
                                fullWidth
                            />}
                    />

                </Grid>

                <Grid item xs={12}>
                    <Controller
                        name="birthDate"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="birthDate"
                                label="Data de Nascimento"
                                {...field}
                                variant="standard"
                                fullWidth
                                mask={dateMask}
                                maskChar="_"
                            />}
                    />
                </Grid>
            </Grid>

            <Grid container justifyContent="space-between" sx={{marginTop: "40px"}}>
                <Button type="submit" variant="contained">Salvar</Button>
            </Grid>
        </form>
    )
}