import { IconButton } from "@mui/material";
import ThreeDotsIcon from "../assets/images/threeDotsIcon.png";
import { useState } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as React from "react";

const CleanTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    maxWidth: 220,
    color: "black",
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    padding: "1rem",
    marginTop: "-5px !important",
    cursor: "pointer"
  },
}));

export const EditButton = ({ functions, params }) => {
  const [showModal, setShowModal] = useState(false);
  const onClose = () => setShowModal(false);
  return (
    <ClickAwayListener onClickAway={() => setShowModal(false)}>
      <CleanTooltip
        onClose={onClose}
        open={showModal}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement="bottom-start"
        title={
          <React.Fragment>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
              }}
            >
              {functions
                .filter((x) => x?.label)
                .map((item) => (
                  <span
                    key={item.label}
                    onClick={() => {
                      onClose();
                      item.action(params);
                    }}
                  >
                    {item.label}
                  </span>
                ))}
            </div>
          </React.Fragment>
        }
      >
        <IconButton
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          <img alt="threeDots" style={{ height: "2rem" }} src={ThreeDotsIcon} />
        </IconButton>
      </CleanTooltip>
    </ClickAwayListener>
  );
};
