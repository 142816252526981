import React, {useEffect} from "react";
import {useForm, Controller} from "react-hook-form";
import {Box, Button, Grid, Modal, TextField, Typography} from "@mui/material";
import WexpIconOrangeCancel from "../../../assets/images/cancel.png";
import WexpIconOrangeSuccess from "../../../assets/images/corrigir.png";
import LoadingAction from "../../LoadingAction";
import {useSelector} from "react-redux";
import StateAbbreviationSelector from "../../StateAbbreviationSelector";
import InputWithMask from "../../InputWithMask.tsx";
import {cepMask} from "../../../utils/InputMasks";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function AddCollaboratorStep3({onBack, onSubmit, loading, setLoading, prevData, edit, userRegisterPage}) {
    const modalSuccessText = userRegisterPage ?
        edit ? 'Cadastro atualizado com sucesso!' : 'Cadastro foi enviado para análise!'
        :
        'Colaborador criado com sucesso!'

    const navigate = useNavigate()

    const {
        register,
        setValue,
        control,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm({
        defaultValues: {
            street: prevData?.address?.street || '',
            neighborhood: prevData?.address?.neighborhood || '',
            city: prevData?.address?.city || '',
            state: prevData?.address?.state || '',
            complement: prevData?.address?.complement || '',
            number: prevData?.address?.number || '',
            postalCode: prevData?.address?.postalCode || '',
        }
    });

    const watchCep = watch('postalCode')

    const onClickSubmit = handleSubmit(onSubmit);

    const {accountHolder} = useSelector(state => state.entities);

    const [showModal, setShowModal] = React.useState(false);

    const loadAddress = async (cep) => {
        const { status, data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

        if(status === 200 && 'cep' in data){
            setValue('street', data.logradouro)
            setValue('neighborhood', data.bairro)
            setValue('city', data.localidade)
            setValue('state', data.uf)
        }
    }

    useEffect(() => {        
        if(watchCep && watchCep.replace(/_/g, '').replace(/-/g, '').length === 8){
            loadAddress(watchCep.replace(/-/g, ''))
        }
    }, [watchCep])

    useEffect(() => {
        if(accountHolder.success){
            setLoading(false);
            setShowModal(true);
        }else if(accountHolder.error){
            setLoading(false);
        }

    }, [accountHolder.success, accountHolder.error])

    return (
        <form
            onSubmit={onClickSubmit}
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "1.5rem",
                maxWidth: "700px",
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>                    
                    <Controller
                        name="postalCode"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="postalCode"
                                label="CEP"
                                variant="standard"
                                fullWidth
                                placeholder="17059157"
                                mask={cepMask}
                                maskChar="_"
                                {...field}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="number"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required
                                id="number"
                                label="Número"
                                variant="standard"
                                fullWidth
                                placeholder="100"
                                {...field}
                            />}
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="complement"
                        control={control}
                        render={({field}) =>
                            <TextField
                                required={!userRegisterPage}
                                id="complement"
                                label="Complemento"
                                variant="standard"
                                fullWidth
                                placeholder="Apto 101"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="neighborhood"
                        control={control}
                        render={({field}) =>
                            <TextField
                                //disabled
                                required
                                id="neighborhood"
                                label="Bairro"
                                variant="standard"
                                fullWidth
                                placeholder="Centro"
                                {...field}
                            />}
                    />

                </Grid>

                <Grid item xs={12} md={6}>
                    <Controller
                        name="street"
                        control={control}
                        render={({field}) =>
                            <TextField
                                disabled
                                required
                                id="street"
                                label="Endereço"
                                variant="standard"
                                fullWidth
                                placeholder="Rua, Avenida, Travessa, etc."
                                {...field}
                            />}
                    />

                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="city"
                        control={control}
                        render={({field}) =>
                            <TextField
                                disabled
                                required
                                id="city"
                                label="Cidade"
                                variant="standard"
                                fullWidth
                                placeholder="São Paulo"
                                {...field}
                            />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        name="state"
                        control={control}
                        render={({field}) =>
                            <StateAbbreviationSelector
                                {...field}                                
                                disabled
                                required
                                id="state"
                            />}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        marginTop: "2rem",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <div style={{display: "flex", gap: "2rem"}}>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            onClick={onBack}
                            sx={{paddingRight: "4rem", paddingLeft: "4rem"}}
                        >
                            Voltar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                        >
                            {
                                edit ? 'Atualizar' : "Cadastrar"
                            }                            
                        </Button>
                    </div>
                </Grid>

                <Grid>
                        <LoadingAction loading={loading}/>
                </Grid>
            </Grid>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-login" sx={modalContentStyle}>
                    <img className="cancel" src={WexpIconOrangeCancel} alt="Wexp Icon orange cancel"
                         onClick={() => setShowModal(false)}/>
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "35px",
                        padding: "20px"
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {
                                modalSuccessText
                            }                            
                        </Typography>
                        <img style={{width: "50px", height: "50px", marginRight: "8px", marginBottom: "25px"}} src={WexpIconOrangeSuccess}
                             alt="Wexp Icon orange success"/>

                        <Button
                            variant="contained"
                            onClick={() => {
                                if(userRegisterPage){
                                    window.close();
                                }

                                navigate('/collaborators')
                            }}
                        >
                            Fechar
                        </Button>
                    </Grid>
                </Box>
            </Modal>
        </form>
    );
}
