import React from "react";
import {useForm, Controller} from "react-hook-form";
import {Button, CircularProgress, Grid} from "@mui/material";
import InputWithMask from "../../InputWithMask.tsx";
import {cpfMask} from '../../../utils/InputMasks';
import {useDispatch, useSelector} from "react-redux";
import {CheckIfUserExistsByDocument} from "../../../store/_Entities/AccountHolder";
import { validateCpf } from "../../../utils/validateCpf";


export default function AddCollaboratorStep1({onSubmit, prevData, edit}) {
    const {
        getValues,
        control,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        formState: {errors},
    } = useForm({
        defaultValues: {
            cpf: ''
        }
    });

    React.useEffect(() => {
        if(prevData?.data?.cpf){
            setValue('cpf', prevData?.data?.cpf)
        }
    }, [prevData])
    
    const { accountHolder } = useSelector(state => state.entities);

    const dispatch = useDispatch();

    const onClickSubmit = async (data) => {
        const document = getValues("cpf").toString().replace(/\D/g,'');
        const doesUserExists = await checkIfUserAlreadyExists(document);

        if (!edit && doesUserExists){
            setError("userExists", {type: "manual", message: "Este usuário já existe"});
            return
        }else if(edit && !doesUserExists){
            setError("userNotFound", {type: "manual", message: "Usuário não encontrado"});
        }
        
        if(!validateCpf(document)){
            setError("invalidCPF", {type: "manual", message: "CPF invalido"});            
            return
        }
        
        onSubmit(data);
    };

    const checkIfUserAlreadyExists = async (document) => {
        await dispatch(CheckIfUserExistsByDocument(document));

        if(accountHolder.bankUserCheck?.bankUser)
            return true;

        return false;
    }

    return (
        <form
            onSubmit={(e) => {clearErrors(); handleSubmit(onClickSubmit)(e)} }
            style={{
                display: "flex",
                flexDirection: "column",
                margin: "1.5rem",
                maxWidth: "350px",
            }}
        >
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Controller
                        name="cpf"
                        control={control}
                        render={({field}) =>
                            <InputWithMask
                                required
                                id="CPF"
                                label="CPF"
                                {...field}
                                variant="standard"
                                fullWidth
                                mask={cpfMask}
                                maskChar="_"
                            />}
                    />
                    {/*TODO -> Change this to a snackbar*/}
                    {errors.userNotFound && <p style={{color: "red"}}>{errors.userNotFound.message}</p>}
                    {errors.userExists && <p style={{color: "red"}}>{errors.userExists.message}</p>}
                    {errors.invalidCPF && <p style={{color: "red"}}>{errors.invalidCPF.message}</p>}
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{
                        marginTop: "2rem",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                    >
                       Próximo
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}
