import React from 'react';
import BackButton from "./BackButton";
import Grid from "@mui/material/Grid";

const Header = ({title, subtitle, backButton}) => {
    return (
        <>
            {backButton ? <BackButton /> : null}
            <Grid container>
                <Grid xs={12} sx={{marginBottom: "30px"}}>
                    <h2>{title}</h2>
                    <p style={{color: "#686868"}}>{subtitle}</p>
                </Grid>
            </Grid>
        </>
    );
};

export default Header;