import React, { useEffect } from 'react';

// MUI
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import AdminGrid from '../../components/grids/AdminGrid';
import Header from '../../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdmins } from '../../store/_Entities/Admin';

const rows = [
    {
        id: 1,
        name: 'André Insardi',
        permission: 'Administrador',
        collaborator: true,
        active: true
    },
    {
        id: 2,
        name: 'Rafael Torres',
        permission: 'Administrador',
        collaborator: true,
        active: true
    }
];

const Admin = () => {
    const dispatch = useDispatch();
    const { admins } = useSelector((state) => state.entities.admin);
    const { user } = useSelector((state) => state.entities);
    useEffect(() => {
        dispatch(fetchAdmins(user.data.companyID));
    }, []);
    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Administradores" />
                <Grid container rowGap={2}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse'
                        }}
                    >
                        <Button href="/admin/add" variant="contained">
                            Adicionar
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <AdminGrid rows={admins} />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Admin;
