// Redux
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// @ts-ignore
import { apiCallBegan } from '../api';

import { IState } from './interfaces/Benefit';
import { Endpoints } from '../../constants/endpoints';
import { IApi } from '../middleware/interfaces/api';
import { IGetBenefitsDto } from '../../interfaces/Benefits';

const initialState: IState = {
    loading: false,
    error: false,
    success: false,
    benefits: []
};

export const slice = createSlice({
    name: 'benefits',
    initialState,
    reducers: {
        BENEFITS_REQUESTED: (
            benefits: IState,
            action: PayloadAction<IState>
        ) => {
            benefits.loading = true;
            benefits.error = false;
            benefits.success = false;
        },

        BENEFITS_FAILED: (benefits: IState, action: PayloadAction<IState>) => {
            benefits.loading = false;
            benefits.error = true;
            benefits.success = false;
        },

        BENEFITS_RETRIEVED: (benefits: IState, action: PayloadAction<any>) => {
            benefits.loading = false;
            benefits.error = false;
            benefits.success = true;
            const { result } = action.payload;
            benefits.benefits = result;
        },
        CHANGE_BENEFIT_STATUS_SUCCESS: (
            benefits: IState,
            action: PayloadAction<any>
        ) => {
            const { result } = action.payload;
            benefits.benefits.filter(
                (benefit) => benefit.benefitId === result.benefitId
            )[0].active = result.active;
        },
        CHANGE_BENEFIT_ALLOWED_TRANSFER_SUCCESS: (
            benefits: IState,
            action: PayloadAction<any>
        ) => {
            const { result } = action.payload;
            console.log(result);
            benefits.benefits.forEach((benefit) => {
                benefit.benefitAllowedTransfer.forEach((benefitAllowedTransfer) => {
                    if(benefitAllowedTransfer.benefitAllowedTransferID == result.benefitAllowedTransferID){
                        benefitAllowedTransfer.allowTransfer = result.allowTransfer;
                    }
                });
            });
        }
    }
});

export const {
    BENEFITS_REQUESTED,
    BENEFITS_FAILED,
    BENEFITS_RETRIEVED,
    CHANGE_BENEFIT_STATUS_SUCCESS,
    CHANGE_BENEFIT_ALLOWED_TRANSFER_SUCCESS
} = slice.actions;

export default slice.reducer;

export const getBenefits = (companyID: string) => {
    const urlParams = new URLSearchParams();
    urlParams.append('CompanyID', companyID);
    const request: IApi = {
        axiosConfig: {
            url: Endpoints.Benefits.GetCompanyBenefits,
            method: 'get',
            params: urlParams
        },
        onAction: {
            onStart: BENEFITS_REQUESTED.type,
            onSuccess: BENEFITS_RETRIEVED.type,
            onError: BENEFITS_FAILED.type
        }
    };
    return apiCallBegan(request);
};

export const changeBenefitStatus = (benefit: IGetBenefitsDto) => {
    const request: IApi = {
        axiosConfig: {
            url: Endpoints.Benefits.ChangeBenefitStatus,
            method: 'put',
            data: {
                benefitId: benefit.benefitId,
                companyId: benefit.companyID,
                active: !benefit.active
            }
        },
        onAction: {
            onSuccess: CHANGE_BENEFIT_STATUS_SUCCESS.type,
            showToast: true
        }
    };
    return apiCallBegan(request);
};

export const changeBenefitAllowedTransferStatus = (benefitAllowedTransferID: number, allowTransfer: boolean) => {
    const request: IApi = {
        axiosConfig: {
            url: Endpoints.Benefits.ChangeBenefitAllowedTransferStatus + "?BenefitAllowedTransferID=" + benefitAllowedTransferID + "&AllowTransfer=" + allowTransfer,
            method: 'put',
            data: {}
        },
        onAction: {
            onSuccess: CHANGE_BENEFIT_ALLOWED_TRANSFER_SUCCESS.type,
            showToast: true
        }
    };
    return apiCallBegan(request);
};
