import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';


import { getBatchingLog } from '../../../store/_Entities/Batching';

import { exportCSVFile } from '../../../utils/download';

import ResponsiveAppBar from '../../../components/NavBar';
import Container from '@mui/material/Container';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import LoadingAction from "../../../components/LoadingAction";


import Grid from '@mui/material/Grid';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';


const sizeCol = 130;
const columns = [
    {
        field: 'email',
        headerName: 'E-mail',
        minWidth: sizeCol,
        flex:1,
        headerAlign: 'center',
        overflow: '',
        align: 'center'
    },
    {
        field: 'status',
        headerName: 'Status',
        minWidth: sizeCol,
        flex:1,
        headerAlign: 'center',
        align: 'center',
    },
];



const BatchingLog = () => {
    
    const routeParams = useParams();
    const { batching } = useSelector((state) => state.entities);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [batchingLog, setBatchingLog] = useState([]);
    const logSuccessCount = batchingLog.reduce((acc, log) => log.successRecords ? acc + 1 : acc, 0);
    const logErrorCount = batchingLog.reduce((acc, log) => log.errorRecords ? acc + 1 : acc, 0);
    const logWarningCount = batchingLog.reduce((acc, log) => log.warningRecords ? acc + 1 : acc, 0);

    useEffect(()=>{
        dispatch(getBatchingLog(routeParams.batchingID));
    },[])

    useEffect(() => {
        if (batching.success && batching.selectedBatchingLog !== null) {
            setLoading(false);
            const transformLog = batching.selectedBatchingLog.map((log) => {
                if (log.successRecords) {
                    return { ...log, status: 'Sucesso' };
                } else if (log.errorRecords) {
                    return { ...log, status: 'Erro' };
                } else if (log.warningRecords) {
                    return { ...log, status: 'Aviso' };
                } else {
                    return { ...log, status: 'Sem status' };
                }
            });
            setBatchingLog(transformLog);
        }
        console.log(batchingLog);
        if(batching.loading) {
            setLoading(true);
        }
    }, [batching]);

    const downloadSheet = () => {
        const headers = {
            email: 'E-mail',
            status: 'Status'
        };
        exportCSVFile(headers, batchingLog, 'log_do_pedido');
    };


    return(
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Log do pedido"/>
                <Grid container rowGap={4}>
                    <Grid
                        item
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >   
                    <Grid item xs={8}>
                        <Typography variant="h6">Sucesso: {logSuccessCount}</Typography>
                        <Typography variant="h6">Aviso: {logWarningCount}</Typography>
                        <Typography variant="h6">Erro: {logErrorCount}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        {batchingLog.length > 0 && (
                             <Button variant="contained" onClick={downloadSheet}>
                             Exportar planilha
                         </Button>
                        )}
                    </Grid>
                   

                    </Grid>
                    <Grid item xs={12}>
                    <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={batchingLog}
                        disableSelectionOnClick
                        columns={columns}
                        pageSize={15}
                        getRowId={(row) => row.batchingLogID}
                        rowsPerPageOptions={[5]}
                    />
                    </div>
                    </Grid>
                </Grid>
                <Footer />
            </Container>
            <LoadingAction loading={loading} />
        </>
    )


}

export default BatchingLog;