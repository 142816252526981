import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Endpoints } from '../../constants/endpoints';
import { apiCallBegan } from '../api';
import {
    REFRESH_REQUESTED,
    REFRESH_TOKEN,
    USER_FAILED
} from '../_Entities/User';
import { IApi } from './interfaces/api';

export const refreshToken = (api: AxiosInstance) => {
    const formData = new FormData();

    const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = localStorage.getItem('access_token');
    if (!refreshToken) return;

    formData.append('grant_type', 'refresh_token');
    formData.append('client_id', 'bank');
    formData.append('client_secret', 'secret');
    formData.append('refresh_token', refreshToken);
    if (accessToken) formData.append('access_token', accessToken);
    // formData.append('scope', 'wBankGateway offline_access');

    const request: AxiosRequestConfig = {
        url: Endpoints.Auth.ConnectToken,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-encoded'
        },
        data: formData
    };

    api.request(request)
        .then((res) => {
            const data = res.data;
            localStorage.setItem('access_token', data.access_token);
            localStorage.setItem('refresh_token', data.refresh_token);
        })
        .catch((err) => {
            console.log(err);
        });
};
