import React from 'react';

// MUI
import Grid from "@mui/material/Grid";

// Wexp Icons
import WexpLogo from '../assets/images/logow.png';

const Footer = () => {
    return (
        <Grid container xs={12} justifyContent="center" sx={{ marginTop: "calc(2% + 10px)", marginBottom: "20px", bottom: "100 !important"}}>
            <img src={WexpLogo} alt="Wexp logo" style={{ width: "120px" }} />
        </Grid>
    );
};

export default Footer;