import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

// Wexp Components
import ResponsiveAppBar from "../../components/NavBar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import LoadingAction from "../../components/LoadingAction";
import TransactionBoletoGrid from '../../components/grids/TransactionBoletoGrid';

import { getCompanyOrders, TRANSFER_RESET, postGenerateBoleto } from '../../store/_Entities/Transfer';

// MUI
import Grid from "@mui/material/Grid";
import { Container } from "@material-ui/core";
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import Typography from '@mui/material/Typography';


const Item = styled(Paper)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "flex-start",
    alignItems: "center",
    padding: theme.spacing(2),
    borderRadius: "10px",
}));

const AddBalanceByBoleto = () => {
    const dispatch = useDispatch();
    const {user, transfer} = useSelector(
        (state) => state.entities
    );

    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [orders, setOrders] = useState([]);
    const [fetchOrders, setFetchOrders] = useState(false);
    const boletoOrderType = 4;


    useEffect(() => {
        dispatch(TRANSFER_RESET());
            dispatch(getCompanyOrders(+user.data.companyID, boletoOrderType))
    }, [])
    

    useEffect(() => {

        if(transfer.loading){
            setLoading(true);
        }else{
            setLoading(false);
        }

        if(transfer.companyOrders){
            setOrders(transfer.companyOrders)
        }

        if(fetchOrders && !transfer.loading){
            dispatch(getCompanyOrders(+user.data.companyID, boletoOrderType))
            setFetchOrders(false);
        }

    }, [transfer, fetchOrders]);

    const generateBoleto = async () => {
        if(value != 0){
            setError(false);
            dispatch(postGenerateBoleto(+user.data.companyID, value, user.data.userID));
            setFetchOrders(true);
        }else{
            setError(true);
        }

    }

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Adicionar Crédito por Boleto"
                    subtitle="Os créditos por Boleto serão compensados após indentificação do pagamento em até 3 dias úteis."/>

                <Grid item xs={12}>
                    <Item sx={{ border: "1px solid #C0C0C0FF" }}>
                        <Grid item xs={12} sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: "space-evenly",
                            flexDirection: { xs: 'column', md: 'row' }
                        }}>
                            <Grid item xs={12} minWidth={500}>
                                <div style={{ marginBottom: "40px" }}>
                                    <h3>Valor</h3>
                                    <CurrencyTextField
                                        variant="standard"
                                        value={value}
                                        placeholder="0,00"
                                        currencySymbol="R$"
                                        //minimumValue="0"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        // onChange={onChange}
                                        onChange={(e, value) => setValue(value)}
                                        error={error}
                                    />
                                </div>

                                <div>
                                <Button onClick={generateBoleto} variant="contained">
                                    Gerar Boleto
                                </Button>
                                </div>
                            </Grid>
                        </Grid>  
                    </Item>
                    <Grid mt item xs={12}>
                        <Typography variant="h6">Boletos Passados</Typography>
                        <TransactionBoletoGrid rows={orders}/>
                        
                    </Grid>
                </Grid>
                <Footer />
            </Container>
            <LoadingAction loading={loading} />
        </>
    );
};

export default AddBalanceByBoleto;