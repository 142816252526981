import React, { useState, useEffect } from 'react';
import TabPanel from '../../TabPanel';
import { Grid, Modal, Box } from '@mui/material';
import RoundedCard from '../../RoundedCard.tsx';
import { CardContent, CardHeader } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GetCollaboratorCards } from '../../../store/_Entities/AccountHolder';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { mapStatusToPortuguese } from '../../../utils/statusMapper';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import WexpIconOrangeSuccess from '../../../assets/images/corrigir.png';

import WexpIconOrangeCancel from "../../../assets/images/cancel.png";
import { textAlign } from '@mui/system';
import { IssueCardDuplicate } from '../../../store/_Entities/Card';

const mockCardData = {
    name: 'João da Silva',
    cardNumber: '1234 **** **** 3456',
    status: 'Ativo',
    creationDate: '05/11/2021'
};
const mockCardData2 = {
    name: 'João da Silva',
    cardNumber: '1234 **** **** 3456',
    status: 'Inativo',
    creationDate: '05/11/2021'
};

const labels = {
    name: 'Nome',
    panMasked: 'Número do Cartão',
    status: 'Status',
    expiryDate: 'Data de Expiração'
};

const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const mockCards = [mockCardData, mockCardData2];

export default function CardsTab({ value, index }) {
    const dispatch = useDispatch();
    const { accountHolder, user } = useSelector((state) => state.entities);
    const [showModal, setShowModal] = React.useState(false);
    const [showModalConfirmation, setShowModalConfirmation] = React.useState(false);
    const [cardID, setCardID] = React.useState('');
    const navigate = useNavigate();
    const routeParams = useParams();

    useEffect(() => {
        let userID = routeParams.userID;
        dispatch(GetCollaboratorCards(userID));
    }, []);

    const DisableCard = async () => {
        setShowModal(false);
        let userID = routeParams.userID;
        await dispatch(IssueCardDuplicate(cardID, userID));
        setShowModalConfirmation(true)
    }

    return (
        <TabPanel value={value} index={index}>
            <Grid container spacing={10}>
                {accountHolder?.holderCards?.map((card) => (
                    <Grid item xs={6}>
                        <RoundedCard>
                            <div
                                style={{
                                    marginLeft: '1rem'
                                }}
                            >

                                    <div className='card-info'>
                                        <h3>Cartão {card.cardType}</h3>
                                        {card.cardTypeID == 2 && card.cardID != "" &&
                                            <Button variant="contained" onClick={(e) => { setCardID(card.cardID); setShowModal(true);}}>
                                                Inativar Cartão
                                            </Button>
                                        }

                                    </div>




                                <div>
                                    {Object.keys(card).map(
                                        (item, value) =>
                                            item in labels && (
                                                <p>
                                                    <b>{labels[item]}: </b>
                                                    {item === 'status'
                                                        ? mapStatusToPortuguese(
                                                            card[item]
                                                        )
                                                        : card[item]}
                                                </p>
                                            )
                                    )}
                                </div>
                            </div>
                        </RoundedCard>
                    </Grid>
                ))}
            </Grid>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-login" sx={modalContentStyle}>
                    <img className="cancel" src={WexpIconOrangeCancel} alt="Wexp Icon orange cancel"
                        onClick={() => setShowModal(false)} />
                    <Grid sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "35px",
                        padding: "20px"
                    }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: 'center' }}>
                            Uma vez desativado, o cartão não poderá mais ser utilizado.
                        </Typography>
                        <Grid >
                            <Button onClick={() => DisableCard()} sx={{ marginRight: "40px" }} variant="contained">Confirmar</Button>
                            <Button onClick={() => setShowModal(false)}>Cancelar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal
                    open={showModalConfirmation}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modal-login" sx={modalContentStyle}>
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '35px',
                                padding: '20px'
                            }}
                        >
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                            >
                                Cartão inativado com sucesso!
                            </Typography>
                            <img
                                style={{
                                    width: '50px',
                                    height: '50px',
                                    marginRight: '8px'
                                }}
                                src={WexpIconOrangeSuccess}
                                alt="Wexp Icon orange success"
                            />
                            <Button href="/collaborators" variant="contained">Fechar</Button>
                        </Grid>
                    </Box>
                </Modal>
        </TabPanel>


    );
}
