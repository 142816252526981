import React, { useEffect, useState } from 'react';

// MUI
import Grid from '@mui/material/Grid';
import { alpha, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Switch } from '@mui/material';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

// Wexp Components
import ResponsiveAppBar from '../components/NavBar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getBenefits, changeBenefitStatus, changeBenefitAllowedTransferStatus } from '../store/_Entities/Benefits';
import { benefitsIcons } from '../utils/benefitsIcons';

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '10px'
}));

export const WexpBlack = {
    700: "#616161",
    800: "#424242",
    900: "#212121",
};

const BlackSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: WexpBlack[900],
        '&:hover': {
            backgroundColor: alpha(WexpBlack[900], theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: WexpBlack[900],
    },
}));

const TransferConfiguration = () => {
    const dispatch = useDispatch();
    const { benefits, user } = useSelector((state) => state.entities);

    useEffect(() => {
        dispatch(getBenefits(user.data.companyID));
    }, []);

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Parametrizar transferência" />

                <Grid container rowGap={2}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h5">You</Typography>
                        </Grid>
                    </Grid>
                    {benefits?.benefits
                        .filter((x) => x.product === 'You' && x.benefitId !== 9 && x.benefitId !== 6)
                        .map((benefit) => (
                            <Grid item xs={12} key={benefit?.benefitId}>
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {benefit?.description}
                                    </Typography>
                                </Grid>

                                {benefit?.benefitAllowedTransfer.map((benefitAllowedTransfer) => (
                                    <Grid 
                                        item xs={12} 
                                            key={benefitAllowedTransfer?.benefitAllowedTransferID} 
                                                style={{ marginTop: "15px", marginBottom: "15px" }}
                                                    hidden = {benefitAllowedTransfer.benefitID === 6}>
                                        <Item>
                                            <Grid
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <img
                                                    style={{
                                                        width: '35px',
                                                        height: '35px',
                                                        marginRight: '8px'
                                                    }}
                                                    src={
                                                        benefitsIcons[
                                                                benefitAllowedTransfer
                                                                    ?.benefit
                                                                    .description
                                                            ]
                                                        }
                                                        alt=""
                                                    />
                                                    <Grid>
                                                        <Typography
                                                            variant="subtitle1"
                                                            component="div"
                                                            sx={{
                                                                fontSize: '18px'
                                                            }}
                                                        >
                                                            {
                                                                benefitAllowedTransfer
                                                                    ?.benefit
                                                                    .description
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Switch
                                                    color="primary"
                                                checked={benefitAllowedTransfer?.allowTransfer}
                                                onChange={() =>
                                                    dispatch(
                                                            changeBenefitAllowedTransferStatus(
                                                                benefitAllowedTransfer?.benefitAllowedTransferID,
                                                                !benefitAllowedTransfer?.allowTransfer
                                                            )
                                                        )
                                                    }
                                                />
                                            </Item>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        ))}
                    <Grid item xs={12}>
                        <Typography variant="h5">CORP</Typography>
                    </Grid>
                    {benefits?.benefits
                        .filter((x) => x.product === 'Corp')
                        .map((benefit) => (
                            <Grid
                                item
                                xs={12}
                                key={benefit?.benefitId}
                                hidden={benefit?.description === 'Cultura'}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h6">
                                        {benefit?.description}
                                    </Typography>
                                </Grid>

                                {benefit?.benefitAllowedTransfer.map(
                                    (benefitAllowedTransfer) => (
                                        <Grid
                                            item
                                            xs={12}
                                            key={
                                                benefitAllowedTransfer?.benefitAllowedTransferID
                                            }
                                            style={{
                                                marginTop: '15px',
                                                marginBottom: '15px'
                                            }}
                                        >
                                            <Item>
                                                <Grid
                                                    display="flex"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <img
                                                        style={{
                                                            width: '35px',
                                                            height: '35px',
                                                            marginRight: '8px'
                                                        }}
                                                        src={
                                                            benefitsIcons[
                                                                benefitAllowedTransfer
                                                                    ?.benefit
                                                                    .description
                                                            ]
                                                        }
                                                        alt=""
                                                    />
                                                    <Grid>
                                                        <Typography
                                                            variant="subtitle1"
                                                            component="div"
                                                            sx={{
                                                                fontSize: '18px'
                                                            }}
                                                        >
                                                            {
                                                                benefitAllowedTransfer
                                                                    ?.benefit
                                                                    .description
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <BlackSwitch
                                                    checked={
                                                        benefitAllowedTransfer?.allowTransfer
                                                    }
                                                    onChange={() =>
                                                        dispatch(
                                                            changeBenefitAllowedTransferStatus(
                                                                benefitAllowedTransfer?.benefitAllowedTransferID,
                                                                !benefitAllowedTransfer?.allowTransfer
                                                            )
                                                        )
                                                    }
                                                />
                                            </Item>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        ))}
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default TransferConfiguration;
