export function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
  
    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','
  
            line += array[i][index];
        }
  
        str += line + '\r\n';
    }
  
    return str;
  }
  
export function selectProps(props){
    return function(obj){
      const newObj = {};
      props.forEach(name =>{
        newObj[name] = obj[name];
      });
      
      return newObj;
    }
  }
  
export const exportCSVFile = (headers, originalItems, fileTitle) => {
    let items = originalItems.map(selectProps(Object.keys(headers)))
  
    if (headers) {
        items.unshift(headers);
    }
  
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
  
    var csv = convertToCSV(jsonObject);
  
    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
  
    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  }