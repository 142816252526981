import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'newOrderForm',
    initialState: {
        benefits: {},
        total: 0
    },
    reducers: {
        setBenefits: (state, action) => {
            state.benefits = action.payload;
        },
        setTotal: (state, action) => {
            state.total = action.payload;
        }
    }
});

export const { setBenefits, setTotal } = slice.actions;

export default slice.reducer;
