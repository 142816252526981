import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputMask from 'react-input-mask';

interface MaskProps {
    mask?: string;
    maskChar?: string;
    inputMaskProps?: any;
}

type InputWithMaskProps = TextFieldProps & MaskProps;

export default function InputWithMask(props: InputWithMaskProps) {
    const {
        mask,
        onBlur,
        onChange,
        value,
        maskChar,
        disabled,
        inputMaskProps,
        ...textFieldProps
    } = props;

    return (
        //@ts-ignore
        <InputMask
            mask={mask ? mask : ''}
            value={value}
            disabled={disabled}
            maskChar={maskChar ? maskChar : ' '}
            onChange={props.onChange}
            onBlur={onBlur}
            {...inputMaskProps}
        >
            {() => <TextField {...textFieldProps} />}
        </InputMask>
    );
}
