// Redux
import { combineReducers } from 'redux';

// Slices
import UserReducer from './User';
import CardReducer from './Card';
import BenefitsReducer from './Benefits';
import BalanceAccountGroupReducer from './BalanceAccountGroup';
import TreasuryAccountHolderReducer from './TreasuryAccountHolder';
import AccountHolderReducer from './AccountHolder';
import AdminReducer from './Admin';
import TransferReducer from './Transfer';
import StatementReducer from './Statement';
import BalanceReturnConfiguration from './BalanceReturnConfiguration';
import Batching from './Batching';
import CompanyTransactions from './CompanyTransactions';
import ApproveTransfer from './ApproveTransfer';
import Address from './Address';

export default combineReducers({
    user: UserReducer,
    card: CardReducer,
    benefits: BenefitsReducer,
    balanceAccountGroup: BalanceAccountGroupReducer,
    treasuryAccountHolder: TreasuryAccountHolderReducer,
    Address: Address,
    accountHolder: AccountHolderReducer,
    admin: AdminReducer,
    transfer: TransferReducer,
    statement: StatementReducer,
    balanceReturnConfiguration: BalanceReturnConfiguration,
    batching: Batching,
    companyTransactions: CompanyTransactions,
    approveTransfer: ApproveTransfer

});
