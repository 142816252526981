import axios from "axios";

// Actions
// @ts-ignore
import * as actions from "../api.ts";
import {SHOW_SNACK} from "../_UI/Snackbar";
import {IAction} from "./interfaces/api";
import {refreshToken} from "./refreshToken";

const api = axios.create();

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const access_token = localStorage.getItem("access_token");
        if (error.response.status === 401 && access_token) {
            refreshToken(api);
        }
        return Promise.reject(error);
    }
);

api.interceptors.request.use((config: any) => {
    const access_token = localStorage.getItem("access_token");
    if (access_token && typeof access_token === "string") {
        config.headers.Authorization = `Bearer ${access_token}`;
    }
    return config;
});

const callApi =
    (apiCall: any) =>
        (next: (action: IAction) => void) =>
            async (action: IAction) => {
                const {dispatch} = apiCall;

                if (action.type !== actions.apiCallBegan.type) return next(action);

                const {onAction} = action.payload;

                const {axiosConfig} = action.payload;

                console.info("callApi", axiosConfig);

                if (onAction?.onStart) dispatch({type: onAction.onStart});

                next(action);

                api
                    .request(axiosConfig)
                    .then(({data}) => {
                        if (onAction?.onSuccess) {
                            dispatch(actions.apiCallSuccess(data));
                            dispatch({type: onAction?.onSuccess, payload: data});
                        }

                        if (onAction?.showToast) dispatch(SHOW_SNACK({message: "Sucesso", severity: "success"}));
                    })
                    .catch((error) => {

                        if (onAction?.onError) dispatch({type: onAction.onError, payload: error.response});

                        if (onAction?.showToast){
                            let errorMessage = "";
                            if(error.response?.data?.errors && error.response?.data?.errors.length > 0) {
                                errorMessage = error.response.data.errors.join();
                            }

                            dispatch(SHOW_SNACK({message: errorMessage || "Ops ! Algo deu errado.", severity: "error"}));
                        }

                    });
            };

export default callApi;
