import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ICard,
    ICardPin,
    ICardState,
    ICompanyRequestedCardStatus,
    ICompanyRequests
} from './interfaces/Card';
import { apiCallBegan } from '../api';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import { GatewayApiUrl } from '../../constants/endpoints';
import { IAddress } from '../../interfaces/Address';

const initialState: ICardState = {
    loading: false,
    error: false,
    success: false,
    userCard: {
        cardID: '',
        panMasked: '',
        expirationDate: '',
        status: '',
        addedTime: 0,
        sid: '',
        issuanceReason: '',
        issuanceType: '',
        replacementId: '',
        contactlessPermitted: false,
        issueNumber: 0,
        tokenisationPermitted: false,
        hasPin: false
    },
    companyRequestCards: [],
    companyRequestCardStatus: []
};

const slice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        CARD_REQUESTED: (
            card: ICardState,
            action: PayloadAction<IRequestResponse<ICardState>>
        ) => {
            card.loading = true;
            card.error = false;
            card.success = false;
        },
        CARD_FAILED: (
            card: ICardState,
            action: PayloadAction<IRequestResponse<ICardState>>
        ) => {
            card.loading = false;
            card.error = true;
            card.success = false;
        },
        CARD_SUCCESS: (
            card: ICardState,
            action: PayloadAction<IRequestResponse<ICardState>>
        ) => {
            card.loading = false;
            card.error = false;
            card.success = true;
        },
        GET_USER_CARD_SUCCESSFULLY: (
            card: ICardState,
            action: PayloadAction<IRequestResponse<ICard>>
        ) => {
            card.loading = false;
            card.success = true;
            card.userCard = action.payload.result;
        },
        LIST_COMPANY_REQUESTS_SUCCESSFULLY: (
            card: ICardState,
            action: PayloadAction<IRequestResponse<ICompanyRequests[]>>
        ) => {
            card.loading = false;
            card.success = true;
            card.companyRequestCards = action.payload.result;
        },
        LIST_COMPANY_REQUESTS_STATUS_SUCCESSFULLY: (
            card: ICardState,
            action: PayloadAction<
                IRequestResponse<ICompanyRequestedCardStatus[]>
            >
        ) => {
            card.loading = false;
            card.success = true;
            card.companyRequestCardStatus = action.payload.result;
        }
    }
});

export const {
    CARD_REQUESTED,
    CARD_FAILED,
    CARD_SUCCESS,
    GET_USER_CARD_SUCCESSFULLY,
    LIST_COMPANY_REQUESTS_SUCCESSFULLY,
    LIST_COMPANY_REQUESTS_STATUS_SUCCESSFULLY
} = slice.actions;

export default slice.reducer;

const url: string = GatewayApiUrl + '/Card';

export const GetUserCard = (userID: string, cardId: string) =>
    apiCallBegan({
        axiosConfig: {
            url: url + `/${cardId}?UserID=${userID}`
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: GET_USER_CARD_SUCCESSFULLY.type,
            onError: CARD_FAILED.type
        }
    });

export const IssueCardDuplicate = (cardID: string, userID: string) =>
    apiCallBegan({
        axiosConfig: {
            url: url + `/IssueCardDuplicate/${cardID}?UserID=${userID}`,
            method: 'post'
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: CARD_SUCCESS.type,
            onError: CARD_FAILED.type
        }
    });

export const ChangeCardStatus = (
    cardID: string,
    userID: string,
    newStatus: string,
    companyID: string
) =>
    apiCallBegan({
        axiosConfig: {
            url: url + `/ChangeCardStatus`,

            method: 'put',
            data: { cardID, userID, newStatus, companyID }
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: CARD_SUCCESS.type,
            onError: CARD_FAILED.type
        }
    });

export const ListCompanyRequests = (companyID: string) =>
    apiCallBegan({
        axiosConfig: {
            url: url + `/CompanyRequests?CompanyID=${companyID}`
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: LIST_COMPANY_REQUESTS_SUCCESSFULLY.type,
            onError: CARD_FAILED.type
        }
    });

export const ListCompanyRequestsStatus = (companyCardRequestID: string) =>
    apiCallBegan({
        axiosConfig: {
            url:
                url +
                `/CompanyRequestedCardStatus?CompanyCardRequestID=${companyCardRequestID}`,

            method: 'get'
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: LIST_COMPANY_REQUESTS_STATUS_SUCCESSFULLY.type,
            onError: CARD_FAILED.type
        }
    });

export const IssueNewCard = (
    address: IAddress,
    userID: string,
    firstName: string,
    lastName: string
) =>
    apiCallBegan({
        axiosConfig: {
            url: url + `/IssueCard`,

            method: 'post',
            data: { address, userID, firstName, lastName }
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: CARD_SUCCESS.type,
            onError: CARD_FAILED.type
        }
    });

export const CreateOrResetPin = (
    pinInfo: ICardPin,
    cardID: string,
    userID: string,
    companyID: string
) =>
    apiCallBegan({
        axiosConfig: {
            url: url + `/Pin/${cardID}?UserID=${userID}&CompanyID=${companyID}`,
            method: 'post',
            data: {
                password: pinInfo.password,
                last4PanDigitis: pinInfo.last4PanDigits,
                pin: pinInfo.pin,
                balanceAccountGroupID: pinInfo.balanceAccountGroupID
            }
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: CARD_SUCCESS.type,
            onError: CARD_FAILED.type
        }
    });

export const LinkCard = (
    cardID: string,
    userID: string,
    companyID: string,
    productID: string
) =>
    apiCallBegan({
        axiosConfig: {
            url:
                url +
                `/LinkCard/${cardID}?UserID=${userID}&CompanyID=${companyID}&ProductID=${productID}`,
            method: 'post'
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: CARD_SUCCESS.type,
            onError: CARD_FAILED.type
        }
    });

export const VirtualCard = (cardID: string) =>
    apiCallBegan({
        axiosConfig: {
            url: url + `/VirtualCard/${cardID}`,
            method: 'get'
        },
        onAction: {
            onStart: CARD_REQUESTED.type,
            onSuccess: CARD_SUCCESS.type,
            onError: CARD_FAILED.type
        }
    });