import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { QRCodeSVG } from 'qrcode.react';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadingAction from '../../components/LoadingAction';
import WexpIconOrangeBarCode from '../../assets/images/copying.png';

import { postPixFundsIn, TRANSFER_RESET } from '../../store/_Entities/Transfer';

// MUI
import Grid from '@mui/material/Grid';
import { Container, TextField } from '@material-ui/core';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { GetAccountHoldersByUserId } from '../../store/_Entities/AccountHolder';
import InputWithMask from '../../components/InputWithMask';
import { cepMask } from '../../utils/InputMasks';

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '10px'
}));

const AddBalanceByPix = () => {
    const dispatch = useDispatch();
    const { user, transfer, accountHolder } = useSelector(
        (state) => state.entities
    );

    const [value, setValue] = React.useState(0);
    const [city, setCity] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [hideQrCode, setHideQrCode] = React.useState('none');
    const [qrCode, setQrCode] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        dispatch(TRANSFER_RESET());
    }, []);

    useEffect(() => {
        if (transfer.success) {
            setQrCode(transfer.pix.emv);
            setHideQrCode('flex');
        } else if (!transfer.loading || !transfer.error) {
            setHideQrCode('none');
        }

        if (transfer.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [transfer]);

    const generatePix = async () => {
        if (value != 0 || city != '' || postalCode != '') {
            setError(false);
            const request = {
                description: 'Pix funds in',
                amount: value,
                companyID: +user.data.companyID,
                city: city,
                postalCode: postalCode.replace('-', ''),
                userID: user.data.userID
            };
            await dispatch(postPixFundsIn(request));
        } else {
            setError(true);
        }
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header
                    backButton
                    title="Adicionar Crédito por Pix"
                    subtitle="Os créditos por PIX serão compensados após indentificação do pagamento."
                />

                <Grid item xs={12}>
                    <Item sx={{ border: '1px solid #C0C0C0FF' }}>
                        <Grid
                            xs={12}
                            sx={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-evenly',
                                flexDirection: { xs: 'column', md: 'row' }
                            }}
                        >
                            <Grid minWidth={500}>
                                <div style={{ marginBottom: '40px' }}>
                                    <h3>Valor</h3>
                                    <CurrencyTextField
                                        variant="standard"
                                        value={value}
                                        placeholder="0,00"
                                        currencySymbol="R$"
                                        //minimumValue="0"
                                        decimalCharacter=","
                                        digitGroupSeparator="."
                                        // onChange={onChange}
                                        onChange={(e, value) => setValue(value)}
                                        error={error}
                                    />
                                    <h3>Cidade</h3>
                                    <TextField
                                        variant="standard"
                                        label="Cidade"
                                        value={city}
                                        onChange={(e) =>
                                            setCity(e.target.value)
                                        }
                                        error={error}
                                    />
                                    <h3>CEP</h3>
                                    <InputWithMask
                                        variant="standard"
                                        label="CEP"
                                        value={postalCode}
                                        onChange={(e) =>
                                            setPostalCode(e.target.value)
                                        }
                                        error={error}
                                        mask={cepMask}
                                    />
                                </div>

                                <div>
                                    <Button
                                        onClick={generatePix}
                                        variant="contained"
                                    >
                                        Gerar QR Code
                                    </Button>
                                </div>
                            </Grid>
                            <Grid minWidth={500}>
                                <div
                                    style={{
                                        marginBottom: '5px',
                                        alignItems: 'center',
                                        display: hideQrCode,
                                        justifyContent: 'center'
                                    }}
                                >
                                    <QRCodeSVG
                                        value={qrCode}
                                        style={{ marginTop: '30px' }}
                                    />
                                </div>
                                <div
                                    style={{
                                        alignItems: 'center',
                                        display: hideQrCode,
                                        justifyContent: 'center',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(qrCode);
                                    }}
                                >
                                    <img
                                        src={WexpIconOrangeBarCode}
                                        alt=""
                                        style={{
                                            width: '20px',
                                            marginRight: '5px'
                                        }}
                                    />
                                    <p>Pix Copia e Cola</p>
                                </div>
                            </Grid>
                        </Grid>
                    </Item>
                </Grid>
                <Footer />
            </Container>
            <LoadingAction loading={loading} />
        </>
    );
};

export default AddBalanceByPix;
