import React, { useEffect, useState } from 'react';

// MUI
import Grid from '@mui/material/Grid';
import { alpha, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Switch } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Wexp Components
import ResponsiveAppBar from '../components/NavBar';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getBenefits, changeBenefitStatus } from '../store/_Entities/Benefits';
import { benefitsIcons } from '../utils/benefitsIcons';
import { Tooltip } from '@material-ui/core';
import { formatLenghtTypography } from '../utils/formatLenghtTypography';

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '10px'
}));

export const WexpBlack = {
    700: '#616161',
    800: '#424242',
    900: '#212121'
};

const BlackSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: WexpBlack[900],
        '&:hover': {
            backgroundColor: alpha(
                WexpBlack[900],
                theme.palette.action.hoverOpacity
            )
        }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: WexpBlack[900]
    }
}));

const Benefits = () => {
    const dispatch = useDispatch();
    const { benefits, user } = useSelector((state) => state.entities);

    useEffect(() => {
        dispatch(getBenefits(user.data?.companyID));
    }, []);

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Categoria de Benefícios" />
                <Grid container rowGap={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">CORP</Typography>
                    </Grid>
                    {benefits?.benefits
                        .filter(
                            (x) => x.product === 'Corp' && x.benefitId !== 12
                        )
                        .map((benefit) => (
                            <div style={{ marginBottom: '10px' }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Grid xs={12} key={benefit?.benefitId}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <img
                                                    style={{
                                                        width: '35px',
                                                        height: '35px',
                                                        marginRight: '8px'
                                                    }}
                                                    src={
                                                        benefitsIcons[
                                                            benefit?.description
                                                        ]
                                                    }
                                                    alt=""
                                                />
                                                <Grid>
                                                    <Typography
                                                        variant="subtitle1"
                                                        component="div"
                                                        sx={{
                                                            fontSize: '18px'
                                                        }}
                                                    >
                                                        {benefit?.description}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <BlackSwitch
                                                checked={benefit?.active}
                                                onChange={() =>
                                                    dispatch(
                                                        changeBenefitStatus(
                                                            benefit
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        gap={5}
                                    >
                                        <>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th
                                                            align="left"
                                                            width="200px"
                                                        >
                                                            <p
                                                                style={{
                                                                    borderRight:
                                                                        '2px solid',
                                                                    width: '135px'
                                                                }}
                                                            >
                                                                Codigo MCC
                                                            </p>
                                                        </th>
                                                        <th
                                                            align="left"
                                                            width="200px"
                                                        >
                                                            <p
                                                                style={{
                                                                    borderRight:
                                                                        '2px solid',
                                                                    width: '135px'
                                                                }}
                                                            >
                                                                Descrição
                                                            </p>
                                                        </th>
                                                        <th
                                                            align="left"
                                                            width="200px"
                                                        >
                                                            Categoria
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {benefit.benefitMCC.map(
                                                        (itens) => (
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        itens.merchantCategoryCode.merchantCategoryCodeID
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Tooltip
                                                                        title={
                                                                            itens
                                                                                .merchantCategoryCode
                                                                                .description
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {formatLenghtTypography(
                                                                                itens
                                                                                    .merchantCategoryCode
                                                                                    .description
                                                                            )}
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        itens
                                                                            .merchantCategoryCode
                                                                            .descriptionInTransaction
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        ))}
                    <Grid item xs={12}>
                        <div style={{ marginTop: '2rem' }}>
                            <Typography variant="h6">YOU</Typography>
                        </div>
                    </Grid>

                    {benefits?.benefits
                        .filter((x) => x.product === 'You' && x.benefitId !== 9)
                        .map((benefit) => (
                            <div style={{ marginBottom: '10px' }}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Grid xs={12} key={benefit?.benefitId}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <Grid
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <img
                                                    style={{
                                                        width: '35px',
                                                        height: '35px',
                                                        marginRight: '8px'
                                                    }}
                                                    src={
                                                        benefitsIcons[
                                                            benefit?.description
                                                        ]
                                                    }
                                                    alt=""
                                                />
                                                <Grid>
                                                    <Typography
                                                        variant="subtitle1"
                                                        component="div"
                                                        sx={{
                                                            fontSize: '18px'
                                                        }}
                                                    >
                                                        {benefit?.description}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <BlackSwitch
                                                checked={benefit?.active}
                                                onChange={() =>
                                                    dispatch(
                                                        changeBenefitStatus(
                                                            benefit
                                                        )
                                                    )
                                                }
                                            />
                                        </div>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid
                                        display="flex"
                                        alignItems="center"
                                        gap={5}
                                    >
                                        <>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th
                                                            align="left"
                                                            width="200px"
                                                        >
                                                            <p
                                                                style={{
                                                                    borderRight:
                                                                        '2px solid',
                                                                    width: '135px'
                                                                }}
                                                            >
                                                                Codigo MCC
                                                            </p>
                                                        </th>
                                                        <th
                                                            align="left"
                                                            width="200px"
                                                        >
                                                            <p
                                                                style={{
                                                                    borderRight:
                                                                        '2px solid',
                                                                    width: '135px'
                                                                }}
                                                            >
                                                                Descrição
                                                            </p>
                                                        </th>
                                                        <th
                                                            align="left"
                                                            width="200px"
                                                        >
                                                            Categoria
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {benefit.benefitMCC.map(
                                                        (itens) => (
                                                            <tr>
                                                                <td>
                                                                    {
                                                                        itens.merchantCategoryCode.merchantCategoryCodeID
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Tooltip
                                                                        title={
                                                                            itens
                                                                                .merchantCategoryCode
                                                                                .description
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {formatLenghtTypography(
                                                                                itens
                                                                                    .merchantCategoryCode
                                                                                    .description
                                                                            )}
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>
                                                                    {
                                                                        itens
                                                                            .merchantCategoryCode
                                                                            .descriptionInTransaction
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        ))}
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Benefits;
