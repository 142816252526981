import React from 'react';
import MenuItem from "@mui/material/MenuItem";
import {FormControl, InputLabel, Select} from '@mui/material';
import axios from "axios";

const CountryCodeSelector = ({...MUIProps}) => {
    const [countriesList, setCountriesList] = React.useState([]);

    const COUNTRY_API_URL = "https://restcountries.com/v3.1/alpha";

    const fetchCountries = async () => {
        const countriesToGetInformation = ['br', 'usa'];
        try {
            const response = await axios.get(COUNTRY_API_URL, {
                params: {
                    codes: countriesToGetInformation.join(',')
                }
            });

            setCountriesList(response.data);
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
        fetchCountries();
    }, []);

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="simple-select-standard-label">País</InputLabel>
            <Select
                {...MUIProps}
                labelId="simple-select-standard-label"
                id="simple-select-standard"
                label="País"
            >
                {countriesList.map((country) => {
                    const addSuffixToCountryCodeIfNecessary = country.idd.suffixes.length === 1 ? country.idd.root + country.idd.suffixes[0] : country.idd.root;
                    return (
                        <MenuItem
                            value={addSuffixToCountryCodeIfNecessary}>{country.flag} {addSuffixToCountryCodeIfNecessary}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    );
};

export default CountryCodeSelector;