import { Endpoints } from '../../constants/endpoints';
import { apiCallBegan } from '../api';
import { createSlice } from '@reduxjs/toolkit';
import { IDataTransfer } from './interfaces/Pix';

const slice = createSlice({
    name: 'transfer',
    initialState: {
        loading: false,
        success: false,
        error: false,
        pix: {
            amount: 0,
            emv: '',
            id: '',
            pmaId: '',
            transactionIdentification: ''
        },
        boleto: {
            amount: 0,
            transferId: ''
        },
        companyOrders: [],
        boletoDownloadFile: ''
    },
    reducers: {
        TRANSFER_REQUESTED: (state, action) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        },
        TRANSFER_SUCCESS: (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
        },
        TRANSFER_FAILED: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = true;
        },
        TRANSFER_RESET: (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = false;
            state.pix = {
                amount: 0,
                emv: '',
                id: '',
                pmaId: '',
                transactionIdentification: ''
            };
            state.boleto = {
                amount: 0,
                transferId: ''
            };
            state.boletoDownloadFile = '';
        },
        TRANSFER_PIX_SUCCESS: (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.pix = action.payload.result;
        },
        TRANSFER_BOLETO_SUCCESS: (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.boleto = action.payload.result;
        },
        TRANSFER_ORDERS_SUCCESS: (state, action) => {
            state.companyOrders = action.payload.result;
        },
        TRANSFER_BOLETO_DOWNLOAD_SUCCESS: (state, action) => {
            state.loading = false;
            state.success = true;
            state.error = false;
            state.boletoDownloadFile = action.payload.result.file;
        }
    }
});

export const {
    TRANSFER_REQUESTED,
    TRANSFER_SUCCESS,
    TRANSFER_FAILED,
    TRANSFER_RESET,
    TRANSFER_PIX_SUCCESS,
    TRANSFER_BOLETO_SUCCESS,
    TRANSFER_ORDERS_SUCCESS,
    TRANSFER_BOLETO_DOWNLOAD_SUCCESS
} = slice.actions;

export default slice.reducer;

export const postTransfer = (companyID: string, toAccount: any[]): any => {
    const data = {
        fromTreasuryAccount: {
            companyID: companyID
        },
        toAccount: toAccount
    };
    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Transfer.TreasuryAccount,
            data
        },
        onAction: {
            onStart: TRANSFER_REQUESTED.type,
            onSuccess: TRANSFER_SUCCESS.type,
            onError: TRANSFER_FAILED.type,
            showToast: true
        }
    });
};

export const postReturnBalanceTransfer = (
    companyID: number,
    userID: string,
    balanceAccountGroupID: string,
    accountHolderID: string
) => {
    const data = {
        userID: userID,
        balanceAccountGroupID: balanceAccountGroupID,
        accountHolderID: accountHolderID,
        companyID: companyID
    };
    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Transfer.ReturnBalanceTransfer,
            data
        },
        onAction: {
            onStart: TRANSFER_REQUESTED.type,
            onSuccess: TRANSFER_SUCCESS.type,
            onError: TRANSFER_FAILED.type,
            showToast: true
        }
    });
};

export const postPixFundsIn = (data: {
    description: string;
    amount: number;
    companyID: number;
    city: string;
    postalCode: string;
    userID: string;
}) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Pix.PostPixFundsIn,
            data
        },
        onAction: {
            onStart: TRANSFER_REQUESTED.type,
            onSuccess: TRANSFER_PIX_SUCCESS.type,
            onError: TRANSFER_FAILED.type,
            showToast: true
        }
    });
};

export const postGenerateBoleto = (
    companyID: number,
    amount: number,
    userId: string
) => {
    const data = {
        amount: amount * 100,
        companyID: companyID,
        userId: userId
    };
    return apiCallBegan({
        axiosConfig: {
            method: 'POST',
            url: Endpoints.Transfer.GenerateBoleto,
            data
        },
        onAction: {
            onStart: TRANSFER_REQUESTED.type,
            onSuccess: TRANSFER_BOLETO_SUCCESS.type,
            onError: TRANSFER_FAILED.type,
            showToast: true
        }
    });
};

export const getCompanyOrders = (companyID: number, orderTypeID: number) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Transfer.GetCompanyOrders(companyID, orderTypeID)
        },
        onAction: {
            onSuccess: TRANSFER_ORDERS_SUCCESS.type
        }
    });
};

export const getDownloadBoleto = (billingID: string) => {
    return apiCallBegan({
        axiosConfig: {
            method: 'GET',
            url: Endpoints.Transfer.DownloadBoleto(billingID)
        },
        onAction: {
            onStart: TRANSFER_REQUESTED.type,
            onSuccess: TRANSFER_BOLETO_DOWNLOAD_SUCCESS.type,
            onError: TRANSFER_FAILED.type,
            showToast: true
        }
    });
};
