/*
* Function to Mask the CPF (Ex. 000.000.000-00)
*/
export const MaskCpf = (cpf) => {
    if(!cpf)
        return cpf;

    cpf = cpf.toString();
    cpf = cpf.replace(/\D/g,"")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf = cpf .replace(/(\d{3})(\d{1,2})$/,"$1-$2")

    return cpf;
}

/*
* Function to Mask the CNPJ (Ex. 00.000.000/0001-00)
*/
export const MaskCnpj = (cnpj) => {
    if(!cnpj)
        return cnpj;

    cnpj = cnpj.toString();
    cnpj = cnpj.replace(/\D/g,"")
    cnpj = cnpj.replace(/^(\d{2})(\d)/,"$1.$2")
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    cnpj = cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")
    cnpj = cnpj.replace(/(\d{4})(\d)/,"$1-$2")

    return cnpj;
}

/*
* Function to Mask Telephone (Ex. +(00)0 00000-0000
*/
export const MaskPhone = (phone) => {
    if(!phone)
        return phone;

    let maskedPhone = phone.toString().replace(/\D/g, "");
    maskedPhone = maskedPhone.replace(/^0/, "");

    if (maskedPhone.length >= 13)
        maskedPhone = maskedPhone.replace(/^(\d\d)(\d{2})(\d{5})(\d{4}).*/, "+$1 ($2) $3-$4");
    else if (maskedPhone.length > 10)
        maskedPhone = maskedPhone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    else if (maskedPhone.length > 5)
        maskedPhone = maskedPhone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
    else if (maskedPhone.length > 2)
        maskedPhone = maskedPhone.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    else
        maskedPhone = maskedPhone.replace(/^(\d*)/, "($1");

    return maskedPhone;
}