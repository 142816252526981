import {
    IBalanceAccountGroup,
    IBalanceAccountState
} from './interfaces/BalanceAccountGroup';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApi, IRequestResponse } from '../middleware/interfaces/api';
import { Endpoints, GatewayApiUrl } from '../../constants/endpoints';
import { apiCallBegan } from '../api';

const initialState: IBalanceAccountState = {
    loading: false,
    error: false,
    success: false,
    userBalanceAccounts: []
};

const slice = createSlice({
    name: 'balanceAccountGroup',
    initialState,
    reducers: {
        BALANCE_ACCOUNT_REQUESTED: (
            balanceAccount: IBalanceAccountState,
            action: PayloadAction<IRequestResponse<IBalanceAccountState>>
        ) => {
            balanceAccount.loading = true;
            balanceAccount.error = false;
            balanceAccount.success = false;
        },
        BALANCE_ACCOUNT_FAILED: (
            balanceAccount: IBalanceAccountState,
            action: PayloadAction<IRequestResponse<IBalanceAccountState>>
        ) => {
            balanceAccount.loading = false;
            balanceAccount.error = true;
            balanceAccount.success = false;
        },
        BALANCE_ACCOUNT_SUCCESS: (
            balanceAccount: IBalanceAccountState,
            action: PayloadAction<IRequestResponse<IBalanceAccountState>>
        ) => {
            balanceAccount.loading = false;
            balanceAccount.error = false;
            balanceAccount.success = true;
        },
        GET_USER_BALANCE_ACCOUNT_SUCCESSFULLY: (
            balanceAccount: IBalanceAccountState,
            action: PayloadAction<IRequestResponse<IBalanceAccountGroup[]>>
        ) => {
            balanceAccount.loading = false;
            balanceAccount.error = false;
            balanceAccount.success = true;
            balanceAccount.userBalanceAccounts = [];
            balanceAccount.userBalanceAccounts = action.payload.result;

        }
    }
});

export const {
    BALANCE_ACCOUNT_REQUESTED,
    BALANCE_ACCOUNT_FAILED,
    BALANCE_ACCOUNT_SUCCESS,
    GET_USER_BALANCE_ACCOUNT_SUCCESSFULLY
} = slice.actions;

export default slice.reducer;

const url: string = GatewayApiUrl + 'BalanceAccountGroup';

export const GetUserBalanceAccountGroup = (userID: string) => {
    const request: IApi = {
        axiosConfig: {
            url: Endpoints.BalanceAccountGroup.GetUserBalanceGroup,
            method: 'get',
            params: {
                UserID: userID
            }
        },
        onAction: {
            onStart: BALANCE_ACCOUNT_REQUESTED.type,
            onSuccess: GET_USER_BALANCE_ACCOUNT_SUCCESSFULLY.type,
            onError: BALANCE_ACCOUNT_FAILED.type
        }
    };

    return apiCallBegan(request);
};
