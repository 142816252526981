import React, { useState } from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Wexp Components
import ResponsiveAppBar from '../../../components/NavBar';
import BackButton from '../../../components/BackButton';
import Footer from '../../../components/Footer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExtractGrid from '../../../components/grids/ExtractGrid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { getCompanyTransferStatements } from '../../../store/_Entities/Statement';
import {
    UpdateApproveTranfer,
    getApproveTranfer
} from '../../../store/_Entities/ApproveTransfer';
import { ModalWrapper } from '../../../components/Modal';
import { fromFloatToCurrency } from '../../../utils/format';
import { useNavigate } from 'react-router-dom';
import LoadingAction from "../../../components/LoadingAction";

const ApproveTranfer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, approveTransfer } = useSelector((state) => state.entities);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);

    useEffect(() => {
        dispatch(getApproveTranfer(user.data?.companyID));
    }, []);

    useEffect(() => {
        if(approveTransfer.loading){
            setLoading(true);
        }else{
            setLoading(false);
        }

    }, [approveTransfer]);

    const listToApprove = approveTransfer?.success
        ? approveTransfer?.approveTransfers?.map((items) => ({
              id: items?.approveTransferID,
              firstName: items?.destinationUser,
              destination: items?.destinationBenefit,
              status: items?.approveTransferStatus?.description,
              value: fromFloatToCurrency(items?.amount)
          }))
        : [];

    const columns = [
        { field: 'firstName', headerName: 'Nome', width: 150 },
        { field: 'destination', headerName: 'Destino', width: 300 },
        { field: 'status', headerName: 'Status', width: 90 },
        { field: 'value', headerName: 'Valor', width: 90 }
    ];

    const handleSelectionChange = (selection) => {
        setSelectedRows(selection);
    };

    const handleApproveOrRepprove = async (approveTransferStatusID) => {
        setOpenModal(false)
        const data = {
            userId: user.data.userID,
            user: user.data.firstName,
            companyID: user.data?.companyID,
            approveTransferStatusID: approveTransferStatusID,
            approveTransferID: selectedRows
        };
        await dispatch(UpdateApproveTranfer(data));
        setOpenSuccessModal(true)
    };

    if (!user?.data?.approver) {
        setTimeout(() => {
            navigate('/orders');
        }, 5000);
        return (
            <>
                <ResponsiveAppBar />
                <Container spacing={11} style={{ display: "flex", justifyContent: "center", marginTop: "20%" }}>
                    <Grid>
                        <Typography>Seu perfil não é de aprovador</Typography>
                    </Grid>
                </Container>
            </>
        );
    }

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={12}>
                <BackButton />
                <Grid container>
                    <Grid xs={12}>
                        <h2>Aprovações pendentes</h2>
                    </Grid>
                </Grid>
                <Grid>
                        <LoadingAction loading={loading} />
                    </Grid>

                <Grid container rowGap={8}>
                    <Grid item xs={12} style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={listToApprove || []}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 }
                                }
                            }}
                            pageSizeOptions={[5, 10]}
                            checkboxSelection
                            onSelectionModelChange={handleSelectionChange}
                        />
                        {selectedRows?.length > 0 && (
                            <Button
                                variant="contained"
                                onClick={() => setOpenModal(true)}
                            >
                                Aprovar ou Reprovar
                            </Button>
                        )}
                    </Grid>
                </Grid>

                <ModalWrapper
                    showModal={openModal}
                    closeModalAction={() => setOpenModal(false)}
                    title="Aprovar ou Reprovar Transferência"
                >
                    <Typography>{`${selectedRows?.length} ${
                        selectedRows?.length > 1
                            ? 'itens selecionados'
                            : 'item selecionado'
                    }`}</Typography>
                    <Grid display="flex" gap={5}>
                        <Button
                            variant="contained"
                            onClick={() => handleApproveOrRepprove(2)}
                        >
                            Aprovar
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => handleApproveOrRepprove(6)}
                        >
                            Reprovar
                        </Button>
                    </Grid>
                </ModalWrapper>

                <ModalWrapper
                    showModal={openSuccessModal}
                    closeModalAction={() => navigate('/orders')}
                    title="Operação realizada com sucesso!"
                >
                    <Grid display="flex" gap={5}>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/orders')}
                        >
                            Fechar
                        </Button>
                    </Grid>
                </ModalWrapper>
                
                <Footer />
            </Container>
        </>
    );
};

export default ApproveTranfer;

const Styles = {
    cardStyle: {
        borderRadius: 2,
        padding: '0.5rem',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)'
    },
    greyText: {
        mb: 1.5,
        minHeight: '3rem'
    }
};
